import { message, Upload } from 'antd'
import moment from 'moment'

const allowedTypes = ['image/svg+xml', 'image/png', 'image/jpeg']

export const ruleRequired = (name = 'Input') => {
  return [
    {
      type: 'text',
      message: `Please enter the valid Event ${name}.`,
    },
    {
      required: true,
      message: `Event ${name} is required.`,
    },
  ]
}

export const ruleEndDate = (endEventDate, flag = '') => {
  return [
    {
      required: true,
      message: 'Please input End Date',
    },
    () => ({
      validator(_, value) {
        if (!value) {
          return Promise.reject('End Date is required.')
        }

        let paramsDate = endEventDate.slice(0, -1)
        let endDate = moment(paramsDate).format('YYYY-MM-DD HH:mm')
        let childEndDate = moment(value).format('YYYY-MM-DD HH:mm')
        let check = moment(endDate).isSameOrAfter(childEndDate)

        if (flag === 'parent')
          check = moment(childEndDate).isSameOrAfter(endDate)
        if (!check) {
          return Promise.reject(
            'Event End Date Must not be Before Event Start Date'
          )
        }
        return Promise.resolve()
      },
    }),
  ]
}

export const ruleStartDateRequire = () => {
  return [
    {
      required: true,
      message: 'Please input Start Date',
    },
  ]
}

export const ruleEventTitleRequire = (name = 'Input') => {
  return [
    {
      type: 'text',
      message: `Please enter the valid Event ${name}.`,
    },
    {
      required: true,
      message: `Event ${name} is required.`,
    },
    () => ({
      validator(_, value) {
        if (!value) {
          return Promise.reject()
        }
        if (value.length < 3) {
          return Promise.reject(`Event ${name} can't be less than 3`)
        }
        return Promise.resolve()
      },
    }),
  ]
}

export const ruleDescription = [
  {
    type: 'text',
    message: 'Please enter the valid Event Description.',
  },
]

export const onlyCharactersAllowed = (e) => {
  const str = e.key
  const letters = /^[a-zA-Z ]*$/
  if (!str.match(letters)) return e.preventDefault()
}
export const onlyCharactersandNumbersAllowed = (e) => {
  const str = e.key
  const letters = /^[a-zA-Z0-9_ .-]*$/
  if (!str.match(letters)) return e.preventDefault()
}

export const ruleImage = (file) => {
  let res = false
  if (allowedTypes.includes(file.type)) {
    res = true
  } else message.error(`${file.type} files are allowed`)
  return res || Upload.LIST_IGNORE
}

export const validateEmail = (email = '') => {
  // eslint-disable-next-line no-useless-escape
  const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  return !!(email.match(mailformat));
}

export const ruleCsv = (file) => {
  // image/jpeg
  let res = false
  if (file.type === 'text/csv') {
    res = true
  } else message.error(`Only text/csv file is allowed`)

  return res || Upload.LIST_IGNORE
}
