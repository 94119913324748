import React from 'react'
import { Button, Form, Input, message } from 'antd'
import './ForgotPassword.scss'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { ForgotPasswordAPI } from '../../redux/actions/user/user.actions'

export default function ForgotPassword() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onFinish = (data) => {
    dispatch(ForgotPasswordAPI(data)).then((res) => {
      if (res.status === 200) {
        message.success(res.data.message)
        navigate('/')
      }
    })
  }
  return (
    <div className="form-cover forgot-password">
      <div className="userformmbox">
        <div className="form-feildbox">
          <h1> Forgot Password </h1>
          <Form name="sign-in" onFinish={(data) => onFinish(data)}>
            <div className="form-feildbox-row">
              <Form.Item
                name={['email_id']}
                rules={[
                  {
                    type: 'email',
                    message: 'Please enter a valid Email Address.',
                  },
                  {
                    required: true,
                    message: 'Email Address is required.',
                  },
                ]}
              >
                <Input placeholder="Email Address" />
              </Form.Item>
            </div>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Send Email
              </Button>
            </Form.Item>
            <p className="register-link">
              Go back to <Link to="/login">Login</Link>
            </p>
          </Form>
        </div>
      </div>
    </div>
  )
}
