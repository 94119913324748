import { CameraFilled } from '@ant-design/icons'
import { useRef, useEffect } from 'react'
import Webcam from 'react-webcam'

export const WebcamCapture = ({setImgUrl, facingMode='user'}) => {

  useEffect(() => {
    if(navigator.getUserMedia){
      navigator.getUserMedia(
      {
        video: true
      }, 
      (localMediaStream) =>{}, 
      (err) => {
        alert('The following error occurred when trying to access the camera: ' + err); 
      }
    );
    } else {
      alert('Sorry, browser does not support camera access');
    }
  }, [])

  const camRef = useRef();
  return (
    <Webcam
      audio={false}
      height={720}
      screenshotFormat="image/jpeg"
      width={1280}
      videoConstraints={{facingMode}}
    >
      {({ getScreenshot }) => (
        <button
          ref={camRef}
          onClick={() => {
            const imageSrc = getScreenshot({width: 500, height: 500})
            setImgUrl(imageSrc)
          }}
        >
          <CameraFilled onClick={() => camRef.current.click()} />
        </button>
      )}
    </Webcam>
  )
}
