import React from 'react'
import { Layout } from 'antd'
import './Layout.scss'

const LayoutHOC = (props) => {
  return (
    <div className="App">
      <Layout className="home-page-wrapper">
        <Layout.Content className="content main-content-wrapper">
          {props.children}
        </Layout.Content>
      </Layout>
    </div>
  )
}
export default LayoutHOC
