import { ADD_EVENTS, UPDATE_EVENTS, DELETE_EVENTS, ALL_EVENTS } from '../../types/event'

const initialState = {
  event: {}
}

const eventReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ALL_EVENTS:
      return {
        event: payload,
      }
    case ADD_EVENTS:
      return {
        event: payload,
      }
    case UPDATE_EVENTS:
      return {
        ...state,
        event: payload,
      }
    case DELETE_EVENTS:
      return {
        event: {},
      }
    default:
      return state
  }
}

export default eventReducer
