import React, { useEffect } from 'react'
import NavBar from '../../components/NavBar'
import { Badge, Calendar, Spin } from 'antd'
import './eventCalendar.scss'
import {
  userAuthoritiesRole,
  userInformation,
} from '../../redux/selectors/user/user.selector'
import { useDispatch, useSelector } from 'react-redux'
import { getRolesInArr } from '../../shared/config/globalFunctions'
import { useState } from 'react'
import { AllEventsforattendee } from '../../redux/actions/event/event.actions'
import moment from 'moment'

const EventCalendar = ({ isAuthenticated }) => {
  const dispatch = useDispatch()
  const userAuthorities = useSelector(userAuthoritiesRole)
  const userInfo = useSelector(userInformation)
  const [roles, setRoles] = useState([])
  const [isloading, setIsLoading] = useState(false)
  const [allEvents, setAllEvents] = useState([])
  let { id } = userInfo
  const [resData] = useState({
    title: '',
    location: '',
    start_date: '',
    end_date: '',
    master_event_id: '',
    child_event_id: '',
    userId: '',
    rsvp: false,
    myEvent: true,
  })
  const [eventPagination, setEventPagination] = useState({
    skip: 0,
    limit: 4,
    total: 0,
  })

  useEffect(() => {
    if (userAuthorities) {
      let roles_arr = getRolesInArr(userAuthorities)
      setRoles(roles_arr)
    }
  }, [userAuthorities])

  useEffect(() => {
    fetchAlleventList(resData, id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resData])

  const fetchAlleventList = (resData, id) => {
    setIsLoading(true)
    const params = { page: eventPagination.skip, limit: eventPagination.limit }
    dispatch(AllEventsforattendee(resData, params, id)).then((res) => {
      if (res.status === 200) {
        setIsLoading(false)
        setAllEvents(res?.data?.data?.list)
        const { currentPage, totalItems } = res.data.data
        setEventPagination({
          ...eventPagination,
          total: totalItems,
          skip: currentPage,
        })
      }
    })
  }

  const getEventByDate = (calDate) => {
    const date = moment(calDate).format('YYYY-MM-DD')
    let resp = allEvents?.filter(
      (event) =>
        moment(event?.userEvents_events?.start_date).format('YYYY-MM-DD') ===
        date
    )
    return resp
  }
  const dateCellRender = (value) => {
    const listEvent = getEventByDate(value) ?? []
    return (
      <ul className="events">
        {listEvent.map((event) => {
          return (
            <li key={event?.userEvents_events?.id}>
              <Badge
                status={'success'}
                text={event?.userEvents_events?.title}
              />
            </li>
          )
        })}
      </ul>
    )
  }

  return (
    <>
      {isloading ? (
        <div className="spinner-myevents" style={{ height: '100vh' }}>
          <Spin tip="Loading..."></Spin>
        </div>
      ) : (
        <>
          <NavBar isAuthenticated={isAuthenticated} roles={roles} />
          <div className="main-page-content">
            <div className="calendar-wrapper">
              <Calendar fullscreen dateCellRender={dateCellRender} />
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default EventCalendar
