import { useSelector } from 'react-redux'
import { userToken } from '../redux/selectors/user/user.selector'

function UseAuthentication() {
  const token = useSelector(userToken)

  return token ? true : false
}

export default UseAuthentication
