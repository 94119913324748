import React, { useEffect, useState } from 'react'
import { Button, Form, Input } from 'antd'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import './SignIn.scss'
import { userSignIn, userSignUp, userSignOut } from '../../redux/actions/user/user.actions'
import { useDispatch } from 'react-redux'
import {
  google_client_id,
  google_captcha_site_key,
} from './../../constant/constant'
import jwtDecode from 'jwt-decode'
import $ from 'jquery'
import { generatePassword } from '../../shared/config/globalFunctions'
import GoogleLogo from '../../assets/images/google-logo.png'

export default function SignIn() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { state } = useLocation()
  const [isLoading, setIsLoading] = useState(false)

  const signInCallBack = (payload) => {
    let payloadata = {}

    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(google_captcha_site_key, { action: 'submit' })
        .then((token) => {
          payloadata['g_recaptcha_response'] = token
          payloadata['isLogin'] = true
          payloadata['send_invitation'] = '1'
          payloadata['roles'] = '6'
          payloadata['image'] = payload?.picture
          payloadata['email_id'] = payload?.email
          payloadata['password'] = generatePassword()
          payloadata['company_name'] = payload?.family_name
          payloadata['first_name'] = payload?.family_name
          payloadata['last_name'] = payload?.given_name
          payloadata['isRegisterWithGoogle'] = 1
          dispatch(userSignUp(payloadata)).then(async (res) => {
            if (res.status === 200) {
              handelSignIn(payloadata)
            } else {
              setIsLoading(false)
            }
          })
        })
    })
  }

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id)

      if (!isScriptExist) {
        let script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = url
        script.id = id
        script.onload = function () {
          if (callback) callback()
        }
        document.body.appendChild(script)
      }

      if (isScriptExist && callback) callback()
    }

    // load the script by passing the URL
    loadScriptByURL(
      'recaptcha-key',
      `https://www.google.com/recaptcha/api.js?render=${google_captcha_site_key}`
    )
    return () => unloadScriptByURL()
  }, [])

  const unloadScriptByURL = () => {
    const script = document.getElementById('recaptcha-key')
    if (script) {
      script.remove()
    }

    const recaptchaElems = document.getElementsByClassName('grecaptcha-badge')
    if (recaptchaElems.length) {
      recaptchaElems[0].remove()
    }
  }

  const onFinish = async (data, flag, googleUser) => {
    dispatch(userSignOut())
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(google_captcha_site_key, { action: 'submit' })
        .then((token) => {
          data['g_recaptcha_response'] = token
          data['isLogin'] = true
          setIsLoading(true)
          dispatch(userSignIn(data, flag, googleUser, signInCallBack)).then(
            (res) => {
              if (res.status === 200) {
                setIsLoading(false)
                if (state) {
                  if (state.eventId)
                    navigate('/', {
                      state: { eventId: state.eventId, applyEventFlag: true },
                    })
                } else {
                  navigate('/')
                }
              } else {
                setIsLoading(false)
              }
            }
          )
        })
    })
  }

  const handleCallbackResponse = (response) => {
    const userObject = jwtDecode(response.credential)
    const { email: email_id, password = generatePassword() } = userObject
    let payload = { email_id, password }
    onFinish(payload, 'google', userObject)
  }

  const [credentials] = useState({
    client_id: google_client_id,
    callback: handleCallbackResponse,
  })

  useEffect(() => {
    /* global google */
    if (!google) return null
    google?.accounts?.id?.initialize(credentials)
    google?.accounts?.id?.renderButton(document.getElementById('signInDiv'), {
      theme: '#323248',
      size: 'large',
      width: '100%',
    })
    google?.accounts?.id?.prompt()
    $('.signInDiv')
  }, [credentials])

  const handelSignIn = (payloadata) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(google_captcha_site_key, { action: 'submit' })
        .then((token) => {
          let loginPayload = {
            email_id: payloadata?.email_id,
            password: payloadata?.password,
            g_recaptcha_response: token,
            isLogin: true,
            isRegisterWithGoogle: 1
          }
          // make user login
          dispatch(userSignIn(loginPayload)).then((res) => {
            if (res.status === 200) {
              setIsLoading(false)
              navigate('/')
            } else {
              setIsLoading(false)
            }
          })
        })
    })
  }

  return (
    <div className="form-cover">
      <div className="userformmbox">
        <div className="form-feildbox">
          <h1> Sign In to your account </h1>
          <Form
            className="sign-in-form-wrapper"
            name="sign-in"
            onFinish={(data) => onFinish(data)}
          >
            <div className="form-feildbox-row">
              <Form.Item
                name={['email_id']}
                rules={[
                  {
                    type: 'email',
                    message: 'Please enter a valid Email Address.',
                  },
                  {
                    required: true,
                    message: 'Email Address is required.',
                  },
                ]}
              >
                <Input placeholder="Email Address" />
              </Form.Item>
            </div>
            <div className="form-feildbox-row">
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Password is required.' }]}
              >
                <Input.Password
                  className="form-input"
                  type="password"
                  placeholder="Password"
                  style={{ marginTop: '9px' }}
                />
              </Form.Item>
            </div>
            <Form.Item>
              {isLoading ? (
                <Button type="primary" htmlType="submit" loading>
                  Login
                </Button>
              ) : (
                <Button type="primary" htmlType="submit">
                  Login
                </Button>
              )}
            </Form.Item>
            <p className="register-link">
              <Link to="/signup">Register</Link> if you don't have account
            </p>
            <div className="or-line-box">
              <span className="or-line">OR</span>
            </div>
            <div className="google-signin-wrapper">
              <div id="signInDiv" className="google-signin-btn-box" />
              <button type="button" className="google-login-btn">
                <img src={GoogleLogo} alt="google logo" />
                Sign in with Google
              </button>
            </div>
          </Form>

          <div className="getin-touchtext">
            <p>
              <Link to="/forgot-password">Forgot password?</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
