import { message, Modal, Radio, Select, Tabs, Spin, Form, Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  getQuickAddon,
  UpdateAteendeeVIPlevel,
  BulkInvitations,
} from '../../redux/actions/event/event.actions'
import {
  capitalizeFirstLetter,
  ImageData,
} from '../../shared/config/globalFunctions'
import noData from '../../assets/images/NoData.png'
import defaultUserImg from '../../assets/images/default-user-img.png'
import $ from 'jquery'
import './modal.scss'
import { useSwipeable } from 'react-swipeable'
import {
  ArrowDownOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ArrowUpOutlined,
  LinkOutlined,
} from '@ant-design/icons'
import { useCallback } from 'react'

function ModalQuickaddonView({ openmodal, setPGuestModal, Quickaddonid }) {
  const dispatch = useDispatch()
  const [value, setValue] = useState('isAdded')
  const [list, setList] = useState('a')
  const [index, setIndex] = useState(0)
  const [quickaddonData, setQuickAddOnData] = useState([])
  const [quickaddonDataswiper, setQuickAddOnDataswiper] = useState([])
  const [updatedVIPUser, setupdateVIPUser] = useState()
  const [isloading, setIsLoading] = useState(false)
  const [vipuserData, setVipUserData] = useState({
    isAdded: true,
    isRemoved: false,
    vip_level: 1,
  })

  useEffect(() => {
    getQuickAddOnData(Quickaddonid, list)
    // eslint-disable-next-line
  }, [list])

  const getQuickAddOnData = useCallback(() => {
    setIsLoading(true)
    setPGuestModal(true)
    dispatch(getQuickAddon(Quickaddonid, list)).then((res) => {
      if (res.status === 200) {
        setIsLoading(false)
        setQuickAddOnData(res?.data?.data)
        setQuickAddOnDataswiper(res?.data?.data)
      }
    })
  }, [Quickaddonid, dispatch, list, setPGuestModal])

  const onChangeSelectUser = (flag) => {
    let selectedUsers = []
    quickaddonData.map((user, i) => {
      let {
        userDetails,
        childEventUserDetails,
        vip_level,
        eventId,
        childeventId,
        id,
      } = user
      let userData = userDetails ? userDetails : childEventUserDetails
      let evtid = childeventId ? childeventId : eventId
      userData?.map((userdata) => {
        let check
        if (flag === 'b') {
          check = $(`#event-user-${i}`).prop('checked')
        } else {
          check = $(`#${userdata.id}`).prop('checked')
        }
        if (check) {
          selectedUsers.push({ userdata, vip_level, evtid, id })
        }
        return null
      })
      return null
    })
    setupdateVIPUser(selectedUsers)
  }

  const selectAll = (flag) => {
    let selectedUsers = []
    quickaddonData.map((user, i) => {
      let {
        userDetails,
        childEventUserDetails,
        vip_level,
        eventId,
        childeventId,
        id,
      } = user
      let userData = userDetails ? userDetails : childEventUserDetails
      let evtid = childeventId ? childeventId : eventId
      userData?.map((userdata) => {
        let check
        if (flag === 'b') {
          check = $(`#event-user-${i}`).prop('checked', true)
        } else {
          check = $(`#${userdata.id}`).prop('checked', true)
        }
        if (check) {
          selectedUsers.push({ userdata, vip_level, evtid, id })
        }
        return null
      })
      return null
    })
    setupdateVIPUser(selectedUsers)
  }

  const ClearAll = (flag) => {
    quickaddonData.map((user, i) => {
      let { userDetails, childEventUserDetails } = user
      let userData = userDetails ? userDetails : childEventUserDetails
      userData?.map((userdata) => {
        if (flag === 'b') {
          $(`#event-user-${i}`).prop('checked', false)
        } else {
          $(`#${userdata.id}`).prop('checked', false)
        }
        return null
      })
      return null
    })
    setupdateVIPUser([])
  }

  const updateVIPLevel = () => {
    let quick_add_on = []
    let { vip_level, isAdded, isRemoved } = vipuserData
    updatedVIPUser?.map((data) => {
      let { id, userdata, evtid } = data
      let userId = userdata.id
      quick_add_on.push({ eventId: evtid, userId, vip_level, id })
      return null
    })
    console.log({ isAdded, isRemoved, quick_add_on }, Quickaddonid)
    dispatch(UpdateAteendeeVIPlevel({ isAdded, isRemoved, quick_add_on })).then(
      (res) => {
        if (res.status === 200) {
          setPGuestModal(false)
          message.success(res.data.message)
        }
      }
    )
  }

  const inviteUser = () => {
    let quick_add_on = []
    let { vip_level, isAdded, isRemoved } = vipuserData
    updatedVIPUser?.map((data) => {
      let { id, userdata } = data
      let userId = userdata.id
      quick_add_on.push({ eventId: Quickaddonid, userId, vip_level, id })
      return null
    })
    dispatch(BulkInvitations({ isAdded, isRemoved, quick_add_on })).then(
      (res) => {
        if (res.status === 200) {
          setPGuestModal(false)
          message.success(res.data.message)
        }
      }
    )
  }

  const onChange = (e) => {
    setValue(e.target.value)
    if (e.target.value === 'isAdded') {
      setVipUserData({
        ...vipuserData,
        isAdded: true,
        isRemoved: false,
      })
    } else {
      setVipUserData({
        ...vipuserData,
        isRemoved: true,
        isAdded: false,
      })
    }
  }

  const tabOnchange = (data) => {
    setList(data)
  }

  const swipeHandlers = useSwipeable({
    onSwiped: ({ dir }) => swiperdata(dir),
    trackMouse: true,
  })

  const swiperdata = (dir) => {
    let data = quickaddonData[index]
    let userId = data?.userId
    let eventId = data?.eventId
    let id = data?.id
    let VIPdata = {
      isAdded: true,
      isRemoved: false,
      vip_level: 1,
    }
    if (dir === 'Left') {
      if (index !== quickaddonDataswiper.length - 1) {
        VIPdata.vip_level = 1
      } else {
        setIndex(0)
        VIPdata.vip_level = 1
      }
    } else if (dir === 'Right') {
      if (index !== quickaddonDataswiper.length - 1) {
        VIPdata.vip_level = 1
      } else {
        setIndex(0)
        VIPdata.vip_level = 1
      }
    } else if (dir === 'Up') {
      if (index !== quickaddonDataswiper.length - 1) {
        VIPdata.vip_level = 2
      } else {
        setIndex(0)
        VIPdata.vip_level = 2
      }
    } else {
      if (index !== quickaddonDataswiper.length - 1) {
        VIPdata.vip_level = 3
      } else {
        setIndex(0)
        VIPdata.vip_level = 3
      }
    }
    updateSLiderAttendee(userId, eventId, VIPdata, id)
  }

  const updateSLiderAttendee = (userId, eventId, VIPdata, id) => {
    let quick_add_on = []
    let { vip_level, isAdded, isRemoved } = VIPdata
    quick_add_on.push({ id, userId, eventId, vip_level })
    dispatch(UpdateAteendeeVIPlevel({ isAdded, isRemoved, quick_add_on })).then(
      (res) => {
        if (res.status === 200) {
          message.success(res.data.message)
          getQuickAddOnData(Quickaddonid, list)
        }
      }
    )
  }

  return (
    <>
      <Modal
        title="Manage Quick Add-on"
        centered
        open={openmodal}
        onOk={() => setPGuestModal(false)}
        onCancel={() => setPGuestModal(false)}
        footer={null}
        className="add-guest-modal"
      >
        <div className="QuickView-Tabs">
          <Tabs defaultActiveKey="a" onChange={(data) => tabOnchange(data)}>
            <Tabs.TabPane tab="Applied to the event" key="a">
              {isloading ? (
                <div className="spinner-myevents" style={{ height: '50vh' }}>
                  <Spin tip="Loading..."></Spin>
                </div>
              ) : (
                <div className="right-add-guest" {...swipeHandlers}>
                  <div>
                    <div className="add-action">
                      {quickaddonData[index] ? (
                        <div className="userformmbox-data" {...swipeHandlers}>
                          <div className="form-feildbox">
                            <div
                              className="profile-wrapper"
                              id={quickaddonData[index]?.eventId}
                            >
                              <div
                                className="profile-img-wrapper"
                                id={quickaddonData[index]?.eventId}
                              >
                                <div
                                  className="profile-img"
                                  id={quickaddonData[index]?.eventId}
                                >
                                  {quickaddonData[index]
                                    ?.childEventUserDetails ? (
                                    <>
                                      <img
                                        src={ImageData(
                                          quickaddonData[index]
                                            ?.childEventUserDetails[0]?.image,
                                          defaultUserImg
                                        )}
                                        alt="Profile"
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        src={ImageData(
                                          quickaddonData[index]?.userDetails[0]
                                            ?.image,
                                          defaultUserImg
                                        )}
                                        alt="Profile"
                                      />
                                    </>
                                  )}
                                </div>
                              </div>
                              <p className="name">
                                {quickaddonData[index]
                                  ?.childEventUserDetails ? (
                                  <>
                                    {!quickaddonData[index]
                                      ?.childEventUserDetails[0]?.first_name
                                      ? 'User'
                                      : capitalizeFirstLetter(
                                          quickaddonData[index]
                                            ?.childEventUserDetails[0]
                                            ?.first_name
                                        ) +
                                        ' ' +
                                        capitalizeFirstLetter(
                                          quickaddonData[index]
                                            ?.childEventUserDetails[0]
                                            ?.last_name
                                        )}
                                  </>
                                ) : (
                                  <>
                                    {!quickaddonData[index]?.userDetails[0]
                                      ?.first_name
                                      ? 'User'
                                      : capitalizeFirstLetter(
                                          quickaddonData[index]?.userDetails[0]
                                            ?.first_name
                                        ) +
                                        ' ' +
                                        capitalizeFirstLetter(
                                          quickaddonData[index]?.userDetails[0]
                                            ?.last_name
                                        )}
                                  </>
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="userformmbox-data">
                          <div className="form-feildbox">
                            <div className="profile-wrapper">
                              <div className="profile-img-wrapper">
                                <div className="profile-img-nodata">
                                  <img src={noData} alt="Profile" />
                                </div>
                              </div>
                              <p className="name">No Data Found</p>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="swipe-note">
                        <p>
                          <ArrowLeftOutlined /> Swipe left to User is Approved
                          to event
                        </p>
                        <p>
                          {' '}
                          <ArrowRightOutlined /> Swipe right to User is Guest
                          List VIP Level 1
                        </p>
                        <p>
                          {' '}
                          <ArrowUpOutlined /> Swipe up to User is Guest List VIP
                          Level 2
                        </p>
                        <p>
                          {' '}
                          <ArrowDownOutlined /> Swipe down to User is Guest List
                          VIP Level 3
                        </p>
                      </div>
                      <ul>
                        {quickaddonDataswiper?.length > 0 ? (
                          quickaddonDataswiper?.map((user, i) => {
                            let userDetails = user?.childEventUserDetails
                              ? user?.childEventUserDetails
                              : user?.userDetails
                            return (
                              user &&
                              userDetails?.map((users, i) => {
                                return (
                                  <li key={i}>
                                    <div className="custom-checkbox">
                                      <input
                                        type="checkbox"
                                        id={users.id}
                                        onChange={() => onChangeSelectUser('a')}
                                      />
                                      <span></span>
                                    </div>
                                    <div className="quickadd-box">
                                      <div className="quick-add-img-box">
                                        <img
                                          className="quickaddon-img"
                                          alt="userimg"
                                          src={ImageData(
                                            users?.image,
                                            defaultUserImg
                                          )}
                                        ></img>
                                      </div>
                                      <div className="addon-info">
                                        <p className="name">
                                          {capitalizeFirstLetter(
                                            users?.first_name
                                          )}{' '}
                                          {capitalizeFirstLetter(
                                            users?.last_name
                                          )}
                                          {users?.bio_link ? (
                                            <span className="link-icon">
                                              <Button
                                                type="link"
                                                href={users?.bio_link}
                                                target="_blank"
                                                icon={<LinkOutlined />}
                                              />
                                            </span>
                                          ) : (
                                            ''
                                          )}
                                        </p>
                                        <p className="email">
                                          {users?.email_id}
                                        </p>
                                        {users?.company_name && (
                                          <p className="email">
                                            {users?.company_name}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </li>
                                )
                              })
                            )
                          })
                        ) : (
                          <div className="modal-data">
                            <span className="nodata-bg">No Data Found</span>
                          </div>
                        )}
                      </ul>
                      <button
                        className="btn primary-btn"
                        onClick={() => selectAll('a')}
                      >
                        Add All
                      </button>
                      <button
                        className="btn danger-btn "
                        onClick={() => ClearAll('a')}
                      >
                        Clear All
                      </button>
                    </div>
                    <Form
                      className="add-action no-data-action"
                      name="sign-in"
                      onFinish={() => updateVIPLevel()}
                    >
                      <Form.Item
                        name={['"Priority_level']}
                        rules={[
                          {
                            required: true,
                            message: 'Priority Level is required.',
                          },
                        ]}
                      >
                        <Select
                          placeholder="Priority Level"
                          onChange={(data) =>
                            setVipUserData({ ...vipuserData, vip_level: data })
                          }
                        >
                          <Select.Option value="1">
                            Priority Level 1
                          </Select.Option>
                          <Select.Option value="2">
                            Priority Level 2
                          </Select.Option>
                          <Select.Option value="3">
                            Priority Level 3
                          </Select.Option>
                        </Select>
                      </Form.Item>

                      <Radio.Group
                        onChange={onChange}
                        value={value}
                        className="radio-btn-vip"
                      >
                        <Radio value="isAdded">Approved</Radio>
                        <Radio value="isRemoved">Disapproved</Radio>
                      </Radio.Group>
                      <Form.Item>
                        <Button
                          className="btn primary-btn"
                          type="primary"
                          htmlType="submit"
                          disabled={!quickaddonData?.length > 0 ? true : false}
                        >
                          Submit
                        </Button>
                      </Form.Item>
                      {/* <button
                        className="btn primary-btn"
                        onClick={() => updateVIPLevel()}
                        disabled={!quickaddonData?.length > 0 ? true : false}
                      >
                        Submit
                      </button> */}
                    </Form>
                  </div>
                </div>
              )}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Suggested guest to invite" key="b">
              {isloading ? (
                <div className="spinner-myevents" style={{ height: '50vh' }}>
                  <Spin tip="Loading..."></Spin>
                </div>
              ) : (
                <div className="right-add-guest">
                  <div>
                    <div className="add-action">
                      {quickaddonData[index] ? (
                        <div className="userformmbox-data" {...swipeHandlers}>
                          <div className="form-feildbox">
                            <div
                              className="profile-wrapper"
                              id={quickaddonData[index]?.eventId}
                            >
                              <div
                                className="profile-img-wrapper"
                                id={quickaddonData[index]?.eventId}
                              >
                                <div
                                  className="profile-img"
                                  id={quickaddonData[index]?.eventId}
                                >
                                  {quickaddonData[index]
                                    ?.childEventUserDetails ? (
                                    <>
                                      <img
                                        src={ImageData(
                                          quickaddonData[index]
                                            ?.childEventUserDetails[0]?.image,
                                          defaultUserImg
                                        )}
                                        alt="Profile"
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        src={ImageData(
                                          quickaddonData[index]?.userDetails[0]
                                            ?.image,
                                          defaultUserImg
                                        )}
                                        alt="Profile"
                                      />
                                    </>
                                  )}
                                </div>
                              </div>
                              <p className="name">
                                {quickaddonData[index]
                                  ?.childEventUserDetails ? (
                                  <>
                                    {!quickaddonData[index]
                                      ?.childEventUserDetails[0]?.first_name
                                      ? 'User'
                                      : capitalizeFirstLetter(
                                          quickaddonData[index]
                                            ?.childEventUserDetails[0]
                                            ?.first_name
                                        ) +
                                        ' ' +
                                        capitalizeFirstLetter(
                                          quickaddonData[index]
                                            ?.childEventUserDetails[0]
                                            ?.last_name
                                        )}
                                  </>
                                ) : (
                                  <>
                                    {!quickaddonData[index]?.userDetails[0]
                                      ?.first_name
                                      ? 'User'
                                      : capitalizeFirstLetter(
                                          quickaddonData[index]?.userDetails[0]
                                            ?.first_name
                                        ) +
                                        ' ' +
                                        capitalizeFirstLetter(
                                          quickaddonData[index]?.userDetails[0]
                                            ?.last_name
                                        )}
                                  </>
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="userformmbox-data">
                          <div className="form-feildbox">
                            <div className="profile-wrapper">
                              <div className="profile-img-wrapper">
                                <div className="profile-img-nodata">
                                  <img src={noData} alt="Profile" />
                                </div>
                              </div>
                              <p className="name">No Data Found</p>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="swipe-note">
                        <p>
                          <ArrowLeftOutlined /> Swipe left to User is Approved
                          to event
                        </p>
                        <p>
                          {' '}
                          <ArrowRightOutlined /> Swipe right to User is Guest
                          List VIP Level 1
                        </p>
                        <p>
                          {' '}
                          <ArrowUpOutlined /> Swipe up to User is Guest List VIP
                          Level 2
                        </p>
                        <p>
                          {' '}
                          <ArrowDownOutlined /> Swipe down to User is Guest List
                          VIP Level 3
                        </p>
                      </div>
                      <ul>
                        {quickaddonDataswiper?.length > 0 ? (
                          quickaddonDataswiper?.map((user, index) => {
                            let userDetails = user?.childEventUserDetails
                              ? user?.childEventUserDetails
                              : user?.userDetails
                            return (
                              user &&
                              userDetails?.map((users, i) => {
                                return (
                                  <>
                                    <li key={i}>
                                      <div className="custom-checkbox">
                                        <input
                                          type="checkbox"
                                          id={`event-user-${index}`}
                                          onChange={() =>
                                            onChangeSelectUser('b')
                                          }
                                        />
                                        <span></span>
                                      </div>
                                      <div className="quickadd-box">
                                        <div className="quick-add-img-box">
                                          <img
                                            className="quickaddon-img"
                                            alt="userimg"
                                            src={ImageData(
                                              users?.image,
                                              defaultUserImg
                                            )}
                                          ></img>
                                        </div>
                                        <div className="addon-info">
                                          <p className="name">
                                            {users?.first_name}{' '}
                                            {users?.last_name}
                                            {users?.bio_link ? (
                                              <span className="link-icon">
                                                <Button
                                                  type="link"
                                                  href={users?.bio_link}
                                                  target="_blank"
                                                  icon={<LinkOutlined />}
                                                />
                                              </span>
                                            ) : (
                                              ''
                                            )}
                                          </p>
                                          <p className="email">
                                            {users?.email_id}
                                          </p>
                                          {users?.company_name && (
                                            <p className="email">
                                              {users?.company_name}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </li>
                                  </>
                                )
                              })
                            )
                          })
                        ) : (
                          <span className="nodata-bg">No Data Found</span>
                        )}
                      </ul>

                      <button
                        className="btn primary-btn"
                        onClick={() => selectAll('b')}
                      >
                        Add All
                      </button>
                      <button
                        className="btn danger-btn "
                        onClick={(data) => ClearAll('b')}
                      >
                        Clear All
                      </button>
                    </div>
                    <Form
                      className="add-action no-data-action"
                      name="sign-in"
                      onFinish={() => inviteUser()}
                    >
                      <Form.Item
                        name={['"Priority_level']}
                        rules={[
                          {
                            required: true,
                            message: 'Priority Level is required.',
                          },
                        ]}
                      >
                        <Select
                          placeholder="Priority Level"
                          onChange={(data) =>
                            setVipUserData({ ...vipuserData, vip_level: data })
                          }
                        >
                          <Select.Option value="1">
                            Priority Level 1
                          </Select.Option>
                          <Select.Option value="2">
                            Priority Level 2
                          </Select.Option>
                          <Select.Option value="3">
                            Priority Level 3
                          </Select.Option>
                        </Select>
                      </Form.Item>
                      {/* <Radio.Group
                        onChange={onChange}
                        value={value}
                        className="radio-btn-vip"
                      >
                        <Radio value="isAdded">Approved</Radio>
                        <Radio value="isRemoved">Disapproved</Radio>
                      </Radio.Group> */}
                      <Form.Item>
                        <Button
                          size="large"
                          className="btn primary-btn"
                          type="primary"
                          htmlType="submit"
                          disabled={!quickaddonData?.length > 0 ? true : false}
                        >
                          Invite
                        </Button>
                      </Form.Item>
                      {/* <button
                        className="btn primary-btn"
                        onClick={() => updateVIPLevel()}
                        disabled={!quickaddonData?.length > 0 ? true : false}
                      >
                        Submit
                      </button> */}
                    </Form>
                  </div>
                </div>
              )}
            </Tabs.TabPane>
          </Tabs>
        </div>
      </Modal>
    </>
  )
}

export default ModalQuickaddonView
