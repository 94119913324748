import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Modal, Row, Col, Spin, Tooltip, Button } from 'antd'
import moment from 'moment'
import img1 from '../../assets/images/event-1.png'
import { GetEvent } from '../../redux/actions/event/event.actions'
import {
  getImagePath
} from '../../shared/config/globalFunctions'
import CopyToClipboard from 'react-copy-to-clipboard'
import { CopyOutlined } from '@ant-design/icons'

const ModalViewEvents = ({ setIsModalOpen, ismodalopen, modalData }) => {
  const dispatch = useDispatch()
  const [evntDetails, setEventDetails] = useState({})
  const [isloading, setIsLoading] = useState(false)

  const UseEffectOnce = useRef(true)

  useEffect(() => {
    if (UseEffectOnce.current) {
      UseEffectOnce.current = false
      fetchEventData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // fetch event details
  const fetchEventData = async () => {
    setIsLoading(true)
    await dispatch(GetEvent(modalData)).then(async (res) => {
      setIsLoading(false)
      let manager = []
      if (res?.data?.childEventUsers) {
        manager = res?.data?.childEventUsers
      } else {
        manager = res?.data?.eventUsers
      }
      const tempData = manager.length > 0 && groupBy('roleId', manager)

      setEventDetails({
        title: res?.data?.title,
        location: res?.data?.location,
        start_date: res?.data?.start_date,
        end_date: res?.data?.end_date,
        description: res?.data?.description,
        event_banner: res?.data?.event_banner
          ? getImagePath(res?.data?.event_banner)
          : img1,
        master_manager: tempData['2'],
        child_manager: tempData['3'],
        onsite_manager: tempData['4'],
        checkIn_manager: tempData['5'],
        shortenedLink: `${process.env.REACT_APP_URL}/event-details/${res?.data.id}`,
        eventId: res?.data.id,
      })
    })
  }

  // group by role id
  const groupBy = (property, objectArray = []) => {
    return objectArray.reduce((acc, obj) => {
      const key = obj[property]
      if (!acc[key]) {
        acc[key] = []
      }
      // Add object to list for given key's value
      acc[key].push(obj)
      return acc
    }, {})
  }

  return (
    <>
      <Modal
        centered
        destroyOnClose
        className="add-guest-modal"
        title="View Event"
        open={ismodalopen}
        onCancel={() => {
          setIsModalOpen(false)
        }}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        footer={null}
      >
        {isloading ? (
          <div className="spinner-myevents" style={{ height: '50vh' }}>
            <Spin tip="Loading..."></Spin>
          </div>
        ) : (
          <div>
            <Row>
              <Col span={12}>
                <p className="title-view">
                  <div>Title: </div>
                  <span className="details-view">
                    {evntDetails?.title}
                  </span>{' '}
                </p>
              </Col>
              <Col span={2}></Col>
              <Col span={10}>
                <p className="title-view">
                  <div>Location: </div>
                  <span className="details-view">{evntDetails?.location}</span>
                </p>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <p className="title-view">
                  <div>Start Date: </div>
                  <span className="details-view">
                    {moment(evntDetails?.start_date)
                      .utc()
                      .format('DD/MM/YYYY, hh:mm A')}
                  </span>
                </p>
              </Col>
              <Col span={2}></Col>
              <Col span={10}>
                <p className="title-view">
                  <div>End Date: </div>
                  <span className="details-view">
                    {moment(evntDetails?.end_date)
                      .utc()
                      .format('DD/MM/YYYY, hh:mm A')}
                  </span>
                </p>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <p className="title-view">
                  <div>Description: </div>
                  <span className="details-view">
                    {evntDetails?.description}
                  </span>
                </p>
              </Col>
              <Col span={2}></Col>
            </Row>
            <Row>
              <Col span={12}>
                <div className="banne-view">
                  <div className="banner-immage title-view">
                    <div>Banner: </div>
                    <img
                      alt="event-banner"
                      src={evntDetails?.event_banner}
                    ></img>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <CopyToClipboard text={evntDetails.shortenedLink}>
                  <Tooltip title="Copy link">
                    <Button icon={<CopyOutlined />} className="copy-button">
                      Copy Event URL to Clipboard
                    </Button>
                  </Tooltip>
                </CopyToClipboard>
              </Col>
            </Row>
          </div>
        )}
      </Modal>
    </>
  )
}

export default ModalViewEvents
