import { message } from 'antd'
import { userSignOut } from '../../redux/actions/user/user.actions'
import { cancelSource } from './axiosConfig'
import moment from 'moment'
import Compressor from 'compressorjs'

let authError = false

export const handleError = (error, dispatch) => {
  let errorMessage = error.response.data.message || 'Something went wrong'

  if (error && error.response && error.response.status === 400) {
    if (error.response && error.response.data && error.response.data.message) {
      errorMessage = error.response.data.message
      if (errorMessage === 'Authentication Failed!' && !!!authError) {
        authError = true
        message.error(errorMessage, () => {
          dispatch(userSignOut())
          cancelSource.cancel('You have been logged out')
          window.location.href = '/'
        })
      }
      setTimeout(() => {
        authError = false
      })
      message.error(errorMessage)
      return errorMessage
    }
  }

  if (error && error.response && error.response.status === 401) {
    if (error.response && error.response.data && error.response.data.message) {
      errorMessage = error.response.data.message
      if (errorMessage === 'Authentication Failed!' && !!!authError) {
        authError = true
        message.error(errorMessage, () => {
          dispatch(userSignOut())
          cancelSource.cancel('You have been logged out')
          window.location.href = '/'
        })
      }
      setTimeout(() => {
        authError = false
      })
      message.error(errorMessage)
      return errorMessage
    }
  }

  if (error.response && error.response.message) {
    errorMessage = error.response.message
  }

  if (error && error.response && error.response.status === 404) {
    if (error.response && error.response.data && error.response.data.message) {
      errorMessage = error.response.data.message
      message.error(errorMessage)
      return errorMessage
    }
  }

  if (error && error.response && error.response.status === 500) {
    if (error.response && error.response.data && error.response.data.message) {
      errorMessage = error.response.data.message
      message.error(errorMessage)
      return errorMessage
    }
  }

  message.error(errorMessage)
  return errorMessage
}

export const getImagePath = (imgName) =>
  `${process.env.REACT_APP_LIVE_ASSESTS_URL}/${imgName}`

export const capitalizeFirstLetter = (string) =>
  string && string?.replace(/^./, string[0]?.toUpperCase())

export const buildFormData = (formData, data, parentKey) => {
  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File) &&
    !(data instanceof Blob) &&
    !(data instanceof Array)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      )
    })
  } else {
    const value = data == null ? '' : data
    if (data instanceof Array) {
      formData.append(parentKey, JSON.stringify(value))
    } else {
      formData.append(parentKey, value)
    }
  }
}

export const getRoleName = (role) => {
  switch (role) {
    case '1':
      return 'Super Admin'
    case '2':
      return 'Master'
    case '3':
      return 'Child'
    case '6':
      return 'Main'
    case '4':
      return 'Onsite'
    case '5':
      return 'Check In'
    default:
      return 'null'
  }
}

export const getRolesInArr = (userAuthorities) => {
  let roleArr = []
  if (Array.isArray(userAuthorities))
    userAuthorities?.map(({ id, roleId }) => roleArr.push(roleId))
  return roleArr ?? []
}

export const buildFormFileData = (formData, data, parentKey) => {
  const value = data == null ? '' : data
  if (data instanceof File) {
    formData.appendFile(parentKey, JSON.stringify(value))
  } else {
    formData.append(parentKey, value)
  }
}

export const jsonToFormData = async (data) => {
  const formData = new FormData()

  buildFormData(formData, data)

  return formData
}

export const momentDateFormate = (date) => {
  let data = moment(date)
  return moment(data).format('DD-MM-YYYY')
}

export const disablePastDate = (current) => {
  // return current < moment().endOf('day')
  return current < moment()
}

export const checkDate = (start_date, end_date) => {
  if (start_date === end_date) return false
  if (start_date > end_date) return true
}

export const formatDate = (date) =>
  moment(date).format(`YYYY-MM-DDTHH:mm:ss.SSS[Z]`)

export const dateFormatStr = `YYYY-MM-DDTHH:mm:ss.SSS[Z]`

export const getRandomuserParams = (params) => ({
  page: params.pagess?.x,
  limit: params.pagess?.y,
})

export const getquickaddonlist = (params) => ({
  list: params,
})

export const getRandomuserParamsForAttendee = (params, id) => ({
  page: params.pagess?.x,
  limit: params.pagess?.y,
  userId: id,
  rsvp: false,
})

export const getRandomuserMasterParams = (params, roles) => ({
  page: params.pagess?.x,
  limit: params.pagess?.y,
  roleId: roles,
})

export const getRandomuserChildParams = (params) => ({
  page: params.pagess?.x,
  limit: params.pagess?.y,
  roleId: '3',
})

export function checkBetween(date1, date2, date3) {
  let date11 = moment(date1).format('YYYY-MM-DD')
  let start_date = moment(date2).add(-1, 'day')
  return moment(date11).isBetween(start_date, date3)
}

export const handleRole = (roles = []) => {
  let flag = false
  let my_role = ['2', '3', '4', '5']
  my_role.forEach((rl) => {
    if (Array.isArray(roles) && roles.includes(rl)) flag = true
  })
  return flag
}

export const checkManagerRole = (roles = []) => {
  let flag = false
  let my_role = ['1', '2', '3', '4', '5']
  my_role.forEach((rl) => {
    if (Array.isArray(roles) && roles.includes(rl)) flag = true
  })
  return flag
}

export const handleRoleForAdmin = (roles = []) => {
  let flag = false
  let my_role = ['2', '3']

  my_role.forEach((rl) => {
    rl = parseInt(rl)
    if (Array.isArray(roles) || roles.includes(rl)) flag = true
  })
  return flag
}

export const handleRoleForChildAdmin = (roles = []) => {
  let flag = false
  let my_role = ['1', '3']
  my_role.forEach((rl) => {
    rl = parseInt(rl)
    if (Array.isArray(roles) && roles.includes(rl)) flag = true
  })
  return flag
}

export const handleRoleForCheckin = (roles = []) => {
  let flag = false
  let my_role = ['5']
  my_role.forEach((rl) => {
    rl = parseInt(rl)
    if (Array.isArray(roles) && roles.includes(rl)) flag = true
  })
  return flag
}

export const handleRoleForonsite = (roles = []) => {
  let flag = false
  let my_role = ['4']
  my_role.forEach((rl) => {
    rl = parseInt(rl)
    if (Array.isArray(roles) && roles.includes(rl)) flag = true
  })
  return flag
}

export const publicURL = process.env.ASSESTS_URL

export const allowedImageTypes = ['image/png', 'image/jpeg']

export const allowedCSVType = ['text/csv']

export const roleName = (roles) => {
  const rolesDetails = {
    1: 'Super admin',
    2: 'Master manager',
    3: 'Child manager',
    4: 'Onsite manager',
    5: 'CheckIn manager',
    6: 'Attendee',
  }
  return rolesDetails[roles]
}

export const onlyCharactersAllowed = (e) => {
  const str = e.key
  const letters = /^[a-zA-Z ]*$/
  if (!str.match(letters)) return e.preventDefault()
}

export const disableDateRange = (current, { start_date, end_date }) => {
  const startDate = moment(start_date, 'YYYY-MM-DD HH:mm')
  const endDate = moment(end_date, 'YYYY-MM-DD HH:mm').add(1, 'day')
  const currentDate = moment(current.format('YYYY-MM-DD HH:mm'))
  return !currentDate.isBetween(startDate, endDate)
}

export const debounce = (func, timeout = 1000) => {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

export const dataURLtoFile = (dataurl, filename) => {
  filename = `${filename}.jpeg`
  const arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)

  // eslint-disable-next-line no-const-assign
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

export const filterByName = (e, events) => {
  let name = e.target.value.toLowerCase()
  const results = events.filter((user) => {
    return user?.title
      ?.toLowerCase()
      ?.replace(/ +/g, '')
      .startsWith(name.toLowerCase())
  })
  return results
}

export const ExpiredDate = (start_date) => {
  let dateFormat = 'YYYY-MM-DD HH:MM'
  start_date = moment.utc(start_date).format(dateFormat)
  let currDate = moment().format(dateFormat)
  return !!(
    moment(currDate).isAfter(start_date) || moment(currDate).isSame(start_date)
  )
}

export const FilterEventByTodayDate = (event) => {
  let data = []
  const today = moment().format('DD/MM/YYYY')
  event?.forEach((e) => {
    let date = moment(e?.userEvents_events?.start_date)
    let startdate = moment(date).format('DD/MM/YYYY')
    if (startdate === today) {
      data.push(e)
    }
  })
  return data
}

export const FilterEventByTomorrowDate = (event) => {
  let data = []
  const today = moment().add(1, 'days').format('DD/MM/YYYY')
  event?.forEach((e) => {
    let date = moment(e?.userEvents_events?.start_date)
    let startdate = moment(date).format('DD/MM/YYYY')
    if (startdate === today) {
      data.push(e)
    }
  })
  return data
}

export function validatePassword(p) {
  const errors = []
  if (p.length < 8) {
    errors.push('Your password must be at least 8 characters')
  }
  if (p.length > 24) {
    errors.push('Your password must be at max 24 characters')
  }
  if (p.search(/[a-z]/) < 0) {
    errors.push('Your password must contain at least one lower case letter.')
  }
  if (p.search(/[A-Z]/) < 0) {
    errors.push('Your password must contain at least one upper case letter.')
  }

  if (p.search(/\d/) < 0) {
    errors.push('Your password must contain at least one digit.')
  }
  if (p.search(/[!@#$%^&*_]/) < 0) {
    errors.push(
      'Your password must contain at least special char from -[ ! @ # $ % ^ & * _ ]'
    )
  }
  return errors
}

export function validatephonenumber(p) {
  const errors = []
  if (p.length < 5) {
    errors.push('Your Phone Number must be at least 5 characters')
  }
  if (p.length > 17) {
    errors.push('Your Phone Number must be at max 17 characters')
  }
  if (isNaN(p)) {
    errors.push('Your Phone Number must be digit')
  }
  return errors
}

export const lable = (isPending, isRequested, isApproved) => {
  let data = {}
  if (isPending === 0 && isRequested === 0 && isApproved === 0) {
    data['btnname'] = 'Apply'
  }
  if (isPending === 1 && isRequested === 1 && isApproved === 0) {
    data['tagname'] = 'Pending'
  }
  if (isPending === 0 && isRequested === 1 && isApproved === 1) {
    data['btnname'] = 'Cancel'
    data['tagname'] = 'Approved'
  }
  if (isPending === 0 && isRequested === 1 && isApproved === 0) {
    data['tagname'] = 'Currently not available'
  }
  return data
}

// export const handlerestriction_level = (restricdata = 1,userRestriclevel) => {
//   let myFunc = num => Number(num);
//   var intArr = Array.from(String(userRestriclevel), myFunc);
//   let flag = false
//   if(restricdata === parseInt(1)){
//     let restrict = [1,2,3]
//     console.log(typeof(restrict))
//     console.log(typeof(intArr))
//     if(restrict.includes(intArr)){
//       return flag = true
//     }
//   }
//   console.log(flag)
//   return flag
// }

export const handlerestriction_level = (restricdata, userRestriclevel) => {
  let int = parseInt(restricdata)
  let flag
  if (int === 1) {
    let Array = [1, 2, 3]
    if (Array.includes(userRestriclevel)) {
      flag = true
    } else {
      flag = false
    }
  } else if (int === 2) {
    let Array = [2, 3]
    if (Array.includes(userRestriclevel)) {
      flag = true
    } else {
      flag = false
    }
  } else if (int === 3) {
    let Array = [3]
    if (Array.includes(userRestriclevel)) {
      flag = true
    } else {
      flag = false
    }
  } else {
    return (flag = false)
  }
  return flag
}

export const ImageData = (image, defaultimg) => {
  return !image
    ? defaultimg
    : image && image.includes('googleusercontent')
    ? image
    : getImagePath(image)
}

export const handleCompressedUpload = (e, callback, key) => {
  const image = e
  new Compressor(image, {
    quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
    success: (compressedResult) => {
      return callback(compressedResult, key)
    },
  })
}

export const generatePassword = () => {
  let length = 12,
    charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
    retVal = ''
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n))
  }
  return retVal + '@#13'
}

export const generateCharacters = () => {
  let length = 2,
    charset = '$#@%&_-!',
    retVal = ''
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n))
  }
  return retVal
}

export const getAttendeesCSVPath = (fileName) =>
  `${process.env.REACT_APP_API_URL_DISCOURSE}/attendeesCSV/${fileName}`

export const compare = (a, b) => {
  if (a.title.toLowerCase() < b.title.toLowerCase()) {
    return -1
  }
  if (a.title.toLowerCase() > b.title.toLowerCase()) {
    return 1
  }
  return 0
}

export const sortEvents = (data) => {
  const filterExpiredEventsFromApproved = data.sort((a, b) => {
    const aDate = new Date(a.start_date)
    const bDate = new Date(b.start_date)
    const aTime = a.start_time
    const bTime = b.start_time
    if (aDate < bDate) return -1
    if (aDate > bDate) return 1
    if (aTime < bTime) return -1
    if (aTime > bTime) return 1
    return 0
  })

  const events = data.concat(filterExpiredEventsFromApproved)
  const finalEventList = events.filter(function (item, index, inputArray) {
    return inputArray.indexOf(item) === index
  })

  return finalEventList
}
