import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import storeConfig from './redux/store'
import App from './App'
import Loader from './common/Loader'
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

const store = storeConfig()

// disable console log in production
if (process.env.NODE_ENV === 'production') console.log = () => { }
const container = document.getElementById('root')
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Router basename={process.env.REACT_APP_BASE_PATH}>
        <Provider store={store}>
          <PersistGate persistor={store.__PERSISTOR} loading={null}>
            <Suspense
              fallback={
                <div
                  style={{
                    display: 'flex',
                    height: '100vh',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Loader color="#EB5757" size={30} />
                </div>
              }
            >
              <App />
            </Suspense>
          </PersistGate>
        </Provider>
      </Router>
    </HelmetProvider>
  </React.StrictMode>
)
