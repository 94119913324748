import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Select, Form, List, Popover, Button, Spin, Pagination } from 'antd'
import VirtualList from 'rc-virtual-list'
import { FilterTwoTone } from '@ant-design/icons'
import NavBar from '../../components/NavBar'
import {
  userAuthoritiesRole,
  userInformation,
} from '../../redux/selectors/user/user.selector'
import { AllEventsforattendee } from '../../redux/actions/event/event.actions'
import moment from 'moment'
import './style.scss'

import {
  getRolesInArr,
  FilterEventByTodayDate,
  FilterEventByTomorrowDate,
} from '../../shared/config/globalFunctions'

export default function MyEvents({ isAuthenticated }) {
  const dispatch = useDispatch()
  const userInfo = useSelector(userInformation)
  const userAuthorities = useSelector(userAuthoritiesRole)
  const [form] = Form.useForm()
  const [event, setEvent] = useState([])
  const [filterData, setFilterData] = useState([])
  const [roles, setRoles] = useState([])
  const [open, setOpen] = useState(false)
  const [isloading, setIsLoading] = useState(false)
  const [isshow, setIsShow] = useState(false)

  const resData = {
    title: '',
    location: '',
    start_date: '',
    end_date: '',
    master_event_id: '',
    child_event_id: '',
    userId: '',
    rsvp: false,
    myEvent: true,
  }

  const [eventPagination, setEventPagination] = useState({
    skip: 0,
    limit: 10,
    total: 0,
  })

  let { id } = userInfo

  useEffect(() => {
    if (userAuthorities) {
      let roles_arr = getRolesInArr(userAuthorities)
      setRoles(roles_arr)
    }
  }, [userAuthorities])

  useEffect(() => {
    fetchAlleventList(resData, id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventPagination.skip, eventPagination.limit])

  const fetchAlleventList = (resData, id) => {
    const params = { page: eventPagination.skip, limit: eventPagination.limit }
    setIsLoading(true)
    dispatch(AllEventsforattendee(resData, params, id)).then((res) => {
      if (res.status === 200) {
        setIsLoading(false)
        setEvent(res?.data?.data?.list)

        const { currentPage, totalItems } = res.data.data
        setEventPagination({
          ...eventPagination,
          total: totalItems,
          skip: currentPage,
        })
      }
    })
  }

  const onFinish = async (data) => {
    if (data.filter === 'rsvp' && !data.events) {
      resData['rsvp'] = true
      fetchAlleventList(resData, id)
    }
    if (data.filter === 'all') {
      resData['rsvp'] = false
      fetchAlleventList(resData, id)
      fetchAlleventList(event)
    }
    if (data.filter === 'invited') {
      resData['rsvp'] = false
      resData['myEvent'] = true
      fetchAlleventList(resData, id)
      setFilterData(event)
    }
    if (data.events === 'onlineevents') {
      let dataevt = FilterEventByTodayDate(event)
      resData['rsvp'] = false
      setFilterData(dataevt)
      fetchAlleventList(resData, id)
    }
    if (data.events === 'today') {
      let dataevt = FilterEventByTodayDate(event)
      resData['rsvp'] = false
      setFilterData(dataevt)
      fetchAlleventList(resData, id)
    }
    if (data.events === 'tomorrow') {
      let startdate = moment().format('DD/MM/YYYY')
      const endDate = moment().add(1, 'days').format('DD/MM/YYYY')
      let dataevt = FilterEventByTomorrowDate(event)
      resData['rsvp'] = false
      resData['start_date'] = startdate
      resData['end_date'] = endDate
      setFilterData(dataevt)
      fetchAlleventList(resData, id)
    }
    setIsShow(true)
    setOpen(false)
  }

  const ResetFilter = () => {
    form.resetFields()
    if (form.__INTERNAL__.name) {
      form.setFieldsValue({
        filter: '',
        events: '',
      })
    }
    let data = {
      title: '',
      location: '',
      start_date: '',
      end_date: '',
      master_event_id: '',
      child_event_id: '',
      userId: '',
      rsvp: false,
      myEvent: true,
    }
    setFilterData([])
    fetchAlleventList(data, id)
    setIsShow(false)
    setOpen(false)
  }

  const popovercontent = (
    <div className="filter-popover">
      <Form form={form} onFinish={(data) => onFinish(data)}>
        <Form.Item className="sort-select" label="Filter By:" name="filter">
          <Select key="filter" defaultValue={'all'}>
            <Select.Option value="all">All</Select.Option>
            <Select.Option value="invited">Invited</Select.Option>
            <Select.Option value="rsvp">RSVP</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item className="sort-select" label="Events:" name="events">
          <Select key="sortevents" placeholder="Select option">
            <Select.Option value="onlineevents">Online Events</Select.Option>
            <Select.Option value="today">Today</Select.Option>
            <Select.Option value="tomorrow">Tomorrow</Select.Option>
          </Select>
        </Form.Item>
        <div className="disply-flex-btn">
          <Form.Item>
            <Button
              id="resetbtn"
              type="primary"
              htmlType="submit"
              className="btn third-btn"
            >
              Search
            </Button>
          </Form.Item>
          {isshow && (
            <Form.Item>
              <Button
                id="resetbtn"
                type="primary"
                htmlType="submit"
                className="btn third-btn"
                onClick={() => ResetFilter()}
              >
                Clear Filters
              </Button>
            </Form.Item>
          )}
        </div>
      </Form>
    </div>
  )

  //pagination onchange
  const paginationTotal = () => {
    let num = parseInt(eventPagination.total / eventPagination.limit)
    if (eventPagination.total % eventPagination.limit !== 0) num = num + 1
    return num * 10
  }

  const increasePageSize = (page, pageSize) => {
    setEventPagination({
      ...eventPagination,
      limit: pageSize,
      skip: page - 1,
    })
  }

  return (
    <>
      {isloading ? (
        <div className="spinner-myevents" style={{ height: '100vh' }}>
          <Spin tip="Loading..."></Spin>
        </div>
      ) : (
        <>
          <NavBar isAuthenticated={isAuthenticated} roles={roles} />
          <div className="contact-page">
            <div className="filter-top-bar">
              <p className="page-title">My Events</p>
              <div className="left-filter">
                <Popover
                  content={popovercontent}
                  placement={'bottomRight'}
                  trigger="click"
                  open={open}
                  onOpenChange={() => setOpen(true)}
                >
                  <button
                    className="btn third-btn"
                    onClick={(e) => e.preventDefault()}
                  >
                    <FilterTwoTone twoToneColor="#fff" /> Filter
                  </button>
                </Popover>
              </div>
            </div>
            {event?.length > 0 || filterData.length > 0 ? (
              <List>
                <VirtualList
                  data={filterData?.length > 0 ? filterData : event}
                  itemHeight={50}
                  itemKey="id"
                >
                  {(item) => {
                    return (
                      <List.Item key={item.id}>
                        <List.Item.Meta
                          avatar={
                            <p className="event-date">
                              <span className="date">
                                {moment(item?.userEvents_events?.start_date)
                                  .utc()
                                  .format('DD')}
                              </span>
                              <span className="month">
                                {moment(item?.userEvents_events?.start_date)
                                  .utc()
                                  .format('MM')}
                              </span>
                              <span>
                                {moment(item?.userEvents_events?.start_date)
                                  .utc()
                                  .format('YYYY')}
                              </span>
                            </p>
                          }
                          title={<span>{item?.userEvents_events?.title}</span>}
                          description={
                            <>
                              <span>
                                {item?.userEvents_events?.description}
                              </span>{' '}
                              <div>
                                Date:{' '}
                                <span>
                                  {moment(item?.userEvents_events?.start_date)
                                    .utc()
                                    .format('DD/MM/YYYY, hh:mm A') +
                                    '  -  ' +
                                    moment(item?.userEvents_events?.end_date)
                                      .utc()
                                      .format('DD/MM/YYYY, hh:mm A')}
                                </span>
                              </div>
                            </>
                          }
                        />
                      </List.Item>
                    )
                  }}
                </VirtualList>
                <div className="pagination-table">
                  <Pagination
                    onChange={increasePageSize}
                    // onChange={(pagination) =>
                    //   setEventPagination({
                    //     ...eventPagination,
                    //     skip: pagination - 1,
                    //   })
                    // }
                    total={paginationTotal()}
                    defaultCurrent={eventPagination.skip + 1}
                    showSizeChanger
                  />
                </div>
              </List>
            ) : (
              <p className="No-Data-List">No Data Found</p>
            )}
          </div>
        </>
      )}
    </>
  )
}
