import Axios from '../../../shared/config/axiosConfig'
import {
  getquickaddonlist,
  getRandomuserChildParams,
  getRandomuserMasterParams,
  getRandomuserParams,
  getRandomuserParamsForAttendee,
  handleError,
  jsonToFormData,
} from '../../../shared/config/globalFunctions'
import { ADD_EVENTS } from '../../types/event'
import qs from 'qs'
import moment from 'moment/moment'

export const AddEvent = (data) => async (dispatch) => {
  const formData = await jsonToFormData(data)
  try {
    const response = await Axios.post('createEvent', formData)
    if (response.status === 200 && !response.data.hasError) {
      if (response.data) {
        dispatch({
          type: ADD_EVENTS,
          payload: response.data.message,
        })
      }
    }
    let returnData = {
      data: response.data.data,
      status: response.status,
      message: response.data.message,
      code: response.data.code,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const UpdateEvent = (id, data) => async (dispatch) => {
  const formData = await jsonToFormData(data)
  try {
    const response = await Axios.post(`updateEvent/${id}`, formData)
    if (response.status === 200 && !response.data.hasError) {
      AllEvent()
    }
    let returnData = {
      responseData: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const AllEvent = (data, tableParams) => async (dispatch) => {
  try {
    const response = await Axios.post(
      `allEvents?${qs.stringify(getRandomuserParams(tableParams))}`,
      data
    )
    if (response.status === 200 && !response.data.hasError) {
      if (response.data) {
        dispatch({
          type: ADD_EVENTS,
          payload: response.data.data,
        })
      }
    }
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const bulkUploadAttendee = (data) => async (dispatch) => {
  const formData = await jsonToFormData(data)
  try {
    const response = await Axios.post(`bulkUploadAttendee`, formData)
    if (response.status === 200 && !response.data.hasError) {
    }
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const BulkUploadAttendeeChildeEvent = (data) => async (dispatch) => {
  const formData = await jsonToFormData(data)
  try {
    const response = await Axios.post(`bulkUploadAttendee`, formData)
    if (response.status === 200 && !response.data.hasError) {
    }
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const DeleteEvent = (id) => async (dispatch) => {
  try {
    const response = await Axios.delete(`deleteEvent/${id}`)
    if (response.status === 200 && !response.data.hasError) {
      AllEvent()
    }
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const AddChildEvent = (data) => async (dispatch) => {
  const formData = await jsonToFormData(data)
  try {
    const response = await Axios.post('createChildEvent', formData)
    if (response.status === 200 && !response.data.hasError) {
    }
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const GetEvent = (id) => async (dispatch) => {
  try {
    const response = await Axios.get(`getEventDetails/${id}`)
    if (response.status === 200 && response.data) {
      return response.data
    }
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const GetEventDetailWithoutAuth = (id) => async (dispatch) => {
  try {
    const response = await Axios.get(`eventDetails/${id}`)
    if (response.status === 200 && response.data) {
      return response.data
    }
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const fetchLoadSEO = (id) => async (dispatch) => {
  try {
    const response = await Axios.get(`loadSEO/eventDetails/${id}`)
    if (response.status === 200 && response.data) {
      return response.data
    }
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const ReMoveManager = (data) => async (dispatch) => {
  try {
    const response = await Axios.post(`removeManager`, data)
    if (response.status === 200 && !response.data.hasError) {
    }
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const ReMoveChildEvent = (id) => async (dispatch) => {
  try {
    const response = await Axios.delete(`deleteEvent/childEvent/${id}`)
    if (response.status === 200 && !response.data.hasError) {
    }
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const UpdateChildEvent = (id, data) => async (dispatch) => {
  const formData = await jsonToFormData(data)
  try {
    const response = await Axios.post(`updateChildEvent/${id}`, formData)
    if (response.status === 200 && !response.data.hasError) {
    }
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const AllEventMaster = (tableParams, data, roles) => async (dispatch) => {
  try {
    // delete data["type"];
    const response = await Axios.post(
      `manager/events?${qs.stringify(
        getRandomuserMasterParams(tableParams, roles[0])
      )}`,
      data
    )
    if (response.status === 200 && !response.data.hasError) {
      if (response.data) {
        dispatch({
          type: ADD_EVENTS,
          payload: response.data.data,
        })
      }
    }
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const AllEventChild = (tableParams, flag, data) => async (dispatch) => {
  try {
    // const response = await Axios.get(
    //   `manager/events?${qs.stringify(
    //     getRandomuserChildParams(tableParams, flag)
    //   )}`
    // )
    delete data['type']
    const response = await Axios.post(
      `manager/events?${qs.stringify(
        getRandomuserChildParams(tableParams, flag)
      )}`,
      data
    )
    if (response.status === 200 && !response.data.hasError) {
      if (response.data) {
        dispatch({
          type: ADD_EVENTS,
          payload: response.data.data,
        })
      }
    }
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const AllEventsOfCheckInManager = (id) => async (dispatch) => {
  try {
    const response = await Axios.post(`events/checkInManager`, { userId: id })
    if (response.status === 200 && !response.data.hasError) {
    }
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const CheckInManagerActions = (data) => async (dispatch) => {
  try {
    const response = await Axios.post(`events/checkInManager/actions`, data)
    if (response.status === 200 && !response.data.hasError) {
    }
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const AddGuestMasterEvent = (data) => async (dispatch) => {
  const formData = await jsonToFormData(data)
  try {
    const response = await Axios.post(`createGuest`, formData)
    if (response.status === 200 && !response.data.hasError) {
    }
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const getUserDetails = (id) => async (dispatch) => {
  try {
    const response = await Axios.get(`events/user/${id}`)
    if (response.status === 200 && !response.data.hasError) {
    }
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const AllEventForattendee = (tableParams, id) => async (dispatch) => {
  try {
    const response = await Axios.get(
      `attendee/myevents?${qs.stringify(
        getRandomuserParamsForAttendee(tableParams, id)
      )}`
    )
    if (response.status === 200 && !response.data.hasError) {
      if (response.data) {
        dispatch({
          type: ADD_EVENTS,
          payload: response.data.data,
        })
      }
    }
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return error
  }
}

export const getQuickAddon = (id, list) => async (dispatch) => {
  try {
    const response = await Axios.get(
      `getQuickAddon/${id}/?${qs.stringify(getquickaddonlist(list))}`
    )
    if (response.status === 200 && !response.data.hasError) {
    }
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const UpdateAteendeeVIPlevel = (data) => async (dispatch) => {
  try {
    const response = await Axios.post(`update/quickAddOn`, data)
    if (response.status === 200 && !response.data.hasError) {
    }
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const BulkInvitations = (data) => async (dispatch) => {
  try {
    const response = await Axios.post(`inviteBulkGuest`, data)
    if (response.status === 200 && !response.data.hasError) {
      let returnData = {
        data: response.data,
        status: response.status,
      }
      return returnData
    }
  } catch (error) {
    return handleError(error, dispatch)
  }
}


export const getAllEvents = () => async (dispatch) => {
  try {
    const response = await Axios.get('allEventsList')
    if (response.status === 200 && !response.data.hasError) {
    }
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const AllEventsforattendee = (data, params, id) => async (dispatch) => {
  if (id) data['userId'] = id
  try {
    const response = await Axios.post(
      `attendee/allEvents?${qs.stringify(params)}`,
      data
    )
    if (response.status === 200 && !response.data.hasError) {
      if (response.data) {
        dispatch({
          type: ADD_EVENTS,
          payload: response.data.data,
        })
      }
    }
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const OnsitemanagerEvents = (id) => async (dispatch) => {
  try {
    const response = await Axios.post(`events/onsiteManager`, { userId: id })
    if (response.status === 200 && !response.data.hasError) {
    }
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const getAttendeeofOnsitemanager = (id) => async (dispatch) => {
  try {
    const response = await Axios.get(`attendees/${id}`)
    if (response.status === 200 && !response.data.hasError) {
    }
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const UpdateAteendeeVIPlevelonsite = (data) => async (dispatch) => {
  try {
    const response = await Axios.post(`update/attendee`, data)
    if (response.status === 200 && !response.data.hasError) {
    }
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const Applyevents = (data) => async (dispatch) => {
  try {
    const response = await Axios.post(`applycancel/event`, data)
    if (response.status === 200 && !response.data.hasError) {
      // AllEventsforattendee()
    }
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const getAttendeewaitingList = (id) => async (dispatch) => {
  try {
    const response = await Axios.get(`attendees/waiting/approvals/${id}`)
    if (response.status === 200 && !response.data.hasError) {
    }
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const fetchCalendarEvents = async (userId, tableParams) => {
  try {
    let payload = {
      title: '',
      location: '',
      start_date: '',
      end_date: '',
      master_event_id: '',
      child_event_id: '',
      userId,
      rsvp: false,
      myEvent: true,
    }
    const { data } = await Axios.post(`/attendee/allEvents`, payload)
    let eventList = data?.data?.list

    return (eventList = eventList?.map(({ userEvents_events }) => {
      let { id, title, start_date } = userEvents_events
      start_date = moment(start_date).format('YYYY-MM-DD')
      return { id, title, date: start_date }
    }))
  } catch (error) {
    // console.log(error.message)
  }
}

export const getidForAttendeeInCheckinManager = (userId, eventId) => async (dispatch) => {
  try {
    const response = await Axios.get(`events/user/${userId}/${eventId}`)
    if (response.status === 200 && !response.data.hasError) {
    }
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const updateAcessRestriction = (data) => async (dispatch) => {
  try {
    const response = await Axios.post(`updateAccessRestriction`, data)
    if (response.status === 200 && !response.data.hasError) {
    }
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const ExportAttendeesCSV = (data) => async (dispatch) => {
  try {
    const response = await Axios.post(`general/exportAttendees`, data)
    if (response.status === 200 && !response.data.hasError) {
    }
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}