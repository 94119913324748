import 'antd/dist/antd.min.css'

import React, { useCallback, useEffect, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { userSignOut } from './redux/actions/user/user.actions'
import jwtDecode from 'jwt-decode'

// Pages 
import ProfileScreen from './pages/Profile'
import MyEventsScreen from './pages/MyEvents'
import ForgotPasswordScreen from './pages/ForgotPassword'
import ChangePasswordScreen from './pages/ChangePassword'
import AddEventScreen from './pages/AddEvent'
import CheckInManagerScreen from './pages/CheckInManager'
import QuickAddOnScreen from './pages/QuickAddOn'
import ManagerEventScreen from './pages/ManagerEvent'
import LoginScreen from './pages/SignIn'
import RegisterScreen from './pages/SignUp'
import PageNotFound from './pages/NotFound'
import ResetPasswordScreen from './pages/ResetPassword'
import Screens from './pages/Screens'
import MyQRCode from './pages/MyQRCode'
import EventCalendar from './pages/EventCalendar'
import Network from './pages/Network'
import EventDetails from './pages/EventDetails'

// role context
import RoleContext from './context/roleContext'

// protected routes
import ProtectedRoute from './Routing/ProtectedRoute'

import {
  userInformation,
  userToken,
} from './redux/selectors/user/user.selector'

import './App.scss'
import BackCamera from './components/WebCapture/backCamera'

function App() {
  const [forceRefresh, setForceRefresh] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const token = useSelector(userToken)
  const userInfo = useSelector(userInformation)
  const isAuthenticated = !!Object.keys(userInfo).length
  const [activeRole, setActiveRole] = useState('')

  const roleContextValues = {
    activeRole,
    setActiveRole,
  }
  const checkIsTokenExpired = useCallback(
    (token, navigate) => {
      const decodedToken = jwtDecode(token)
      if (decodedToken.exp < new Date().getTime() / 1000) {
        message.error('Your session is expired, please log in again', () => {
          setForceRefresh(true)
          dispatch(userSignOut())
          if (navigate) {
            navigate(`/`)
            return false
          }
        })
      } else {
        if (forceRefresh) {
          setForceRefresh(false)
        }
      }
    },
    [dispatch, forceRefresh]
  )

  // For check Token Expired
  useEffect(() => {
    if (isAuthenticated && token) {
      checkIsTokenExpired(token, navigate)
    }
  }, [checkIsTokenExpired, navigate, isAuthenticated, token])

  return (
    <div>
      <RoleContext.Provider value={roleContextValues}>
        <Routes>
          <Route
            exact
            path={`/`}
            element={<Screens isAuthenticated={isAuthenticated} />}
          />
          <Route
            path={`/profile`}
            element={
              <ProtectedRoute
                component={<ProfileScreen isAuthenticated={isAuthenticated} />}
              />
            }
          />
          <Route
            path={`/my-events`}
            element={
              <ProtectedRoute
                component={<MyEventsScreen isAuthenticated={isAuthenticated} />}
              />
            }
          />
          <Route
            path={`/network`}
            element={
              <ProtectedRoute
                component={<Network isAuthenticated={isAuthenticated} />}
              />
            }
          />
          <Route
            path={`/add-event`}
            element={
              <ProtectedRoute
                component={<AddEventScreen isAuthenticated={isAuthenticated} />}
              />
            }
          />
          <Route
            path={`/door-scan`}
            element={
              <ProtectedRoute
                component={
                  <CheckInManagerScreen isAuthenticated={isAuthenticated} />
                }
              />
            }
          />
          <Route
            path={`/quick-addon`}
            element={
              <ProtectedRoute
                component={
                  <QuickAddOnScreen isAuthenticated={isAuthenticated} />
                }
              />
            }
          />
          <Route
            path={`/manager-event`}
            element={
              <ProtectedRoute
                component={
                  <ManagerEventScreen isAuthenticated={isAuthenticated} />
                }
              />
            }
          />
          <Route
            path={`/myQR`}
            element={
              <ProtectedRoute
                component={<MyQRCode isAuthenticated={isAuthenticated} />}
              />
            }
          />
          <Route
            path={`/event-calendar`}
            element={
              <ProtectedRoute
                component={<EventCalendar isAuthenticated={isAuthenticated} />}
              />
            }
          />
          <Route path={`/event-details/:id`} element={<EventDetails />} />
          <Route
            path={`/change-password`}
            element={<ProtectedRoute component={<ChangePasswordScreen />} />}
          />
          <Route path={`/forgot-password`} element={<ForgotPasswordScreen />} />
          <Route
            path={`/reset-password/:id`}
            element={<ResetPasswordScreen />}
          />
          <Route path={`/login`} element={<LoginScreen />} />
          <Route path={`/signup`} element={<RegisterScreen />} />
          <Route path={`/backcamera`} element={<BackCamera />} />
          <Route path={`/*`} exact={true} element={<PageNotFound />} />
        </Routes>
      </RoleContext.Provider>
    </div>
  )
}

export default App
