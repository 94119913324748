import React, { useEffect, useState } from 'react'
import { Form, Input, message } from 'antd'
import {
  ArrowLeftOutlined,
  SyncOutlined,
} from '@ant-design/icons'
import './QuickAddOn.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  QuickAddonUser,
} from '../../redux/actions/user/user.actions'
import { useDispatch } from 'react-redux'
import {
  dataURLtoFile,
} from '../../shared/config/globalFunctions'
import { WebcamCapture } from '../../components/WebCapture'
import { getAttendeewaitingList } from '../../redux/actions/event/event.actions'
import { onlyCharactersandNumbersAllowed } from '../AddEvent/eventFormRules'

export default function Profile() {
  const location = useLocation()
  const flag = localStorage.getItem('flag')
  const [formdata, setFormdata] = useState({})
  const [imgUrl, setImgUrl] = useState('')
  const [facingMode, setFacingMode] = useState('user')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { state } = location
  const id = state?.eventId

  const onFinish = async () => {
    if (flag === 'childevent_id') {
      formdata['childevent_id'] = id
    } else {
      formdata['event_id'] = id
    }
    dispatch(QuickAddonUser(formdata)).then(
      async ({ data: { message: apiMessage }, status }) => {
        if (status === 200) {
          // await discourseUser(formdata)
          message.success(apiMessage)
          getCounterofAttendee(id)
          navigate('/door-scan')
        }
      }
    )
  }

  const getCounterofAttendee = (id) => {
    dispatch(getAttendeewaitingList(id)).then((res) => { })
  }

  const formDataHandler = (e) => {
    setFormdata({ ...formdata, [e.target.id]: e.target.value })
  }

  useEffect(() => {
    if (imgUrl)
      setFormdata({ ...formdata, image: dataURLtoFile(imgUrl, 'user_profile') })
  }, [formdata, imgUrl])

  return (
    <>
      <div className="form-cover quick-addon-page">
        <div className="userformmbox">
          <div className="form-feildbox">
            <button
              className="back-icon"
              onClick={() => navigate('/door-scan')}
            >
              <ArrowLeftOutlined />
            </button>
            <div className="profile-wrapper">
              <div className="profile-img-wrapper">
                <div className="profile-img">
                  {imgUrl ? (
                    <img src={imgUrl} alt="Profile" />
                  ) : (
                    <WebcamCapture
                      setImgUrl={setImgUrl}
                      facingMode={facingMode}
                    />
                  )}
                </div>

                <div
                  className="switch-camera-box"
                  onClick={() =>
                    setFacingMode((facingMode) =>
                      facingMode === 'user' ? 'environment' : 'user'
                    )
                  }
                >
                  <SyncOutlined className="switch-camera-btn" />
                  <span className="sw-cm-txt">Switch Camera</span>
                </div>
              </div>
              <Form name="signin" onFinish={() => onFinish()}>
                <div className="form-feildbox-row">
                  <Form.Item
                    name={'email'}
                    rules={[
                      {
                        type: 'email',
                        message: 'Please enter the valid Email.',
                      },
                      {
                        required: true,
                        message: 'Email is required.',
                      },
                    ]}
                  >
                    <Input
                      placeholder="Email *"
                      id="email_id"
                      onChange={formDataHandler}
                    />
                  </Form.Item>
                </div>
                <div className="form-feildbox-row">
                  <Form.Item
                    name={'first_name'}
                    rules={[
                      {
                        type: 'text',
                        message: 'Please enter the valid First Name.',
                      },
                      {
                        required: true,
                        message: 'First Name is required.',
                      },
                    ]}
                  >
                    <Input
                      placeholder="First Name *"
                      id="first_name"
                      onChange={formDataHandler}
                      onKeyDown={onlyCharactersandNumbersAllowed}
                      maxLength="18"
                    />
                  </Form.Item>
                </div>
                <div className="form-feildbox-row">
                  <Form.Item
                    name={'last_name'}
                    rules={[
                      {
                        type: 'text',
                        message: 'Please enter the valid Last Name.',
                      },
                      {
                        required: true,
                        message: 'Last Name is required.',
                      },
                    ]}
                  >
                    <Input
                      id="last_name"
                      onChange={formDataHandler}
                      placeholder="Last Name *"
                      onKeyDown={onlyCharactersandNumbersAllowed}
                      maxLength="18"
                    />
                  </Form.Item>
                </div>
                <div className="form-feildbox-row">
                  <Form.Item
                    name={'company_name'}
                    rules={[
                      {
                        type: 'text',
                        message: 'Please enter the valid Company Name.',
                      },
                      {
                        required: true,
                        message: 'Company Name is required.',
                      },
                    ]}
                  >
                    <Input
                      id="company_name"
                      onChange={formDataHandler}
                      placeholder="Company Name"
                      maxLength="32"
                    />
                  </Form.Item>
                </div>
                <div className="profile-btn">
                  <div className="profile-action">
                    <button className="btn primary-btn">Submit</button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
