import { SyncOutlined } from '@ant-design/icons'
import React, { useEffect, useRef, useState } from 'react'
import { QrReader } from 'react-qr-reader'
import '../../pages/CheckInManager/style.scss'

function BackCamera() {
  const [facingMode, setFacingMode] = useState('user')
  const ref = useRef(null)

  const switchCameraHandler = () => {
    let currentMode = facingMode
    setFacingMode()

    setTimeout(() => {
      setFacingMode(currentMode === 'user' ? 'environment' : 'user')
    }, [1])
  }

  let handleError = (err) => {
    alert(err)
  }

  const closeCam = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: true,
    })
    stream.getTracks().forEach(function (track) {
      track.stop()
      track.enabled = false
    })
    ref?.current?.stopCamera()
  }

  useEffect(() => {
    closeCam()
  }, [])
  return (
    <div className="">
      <div className="">
        <div
          className="switch-camera-box"
          onClick={() => switchCameraHandler()}
        >
          <SyncOutlined className="switch-camera-btn" />
          <span className="sw-cm-txt">Switch Camera</span>
        </div>
        <div className="">
          {facingMode && (
            <QrReader
              delay={500}
              onError={handleError}
              style={{ width: '100%' }}
              constraints={{
                facingMode: facingMode,
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default BackCamera
