import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Button, Form, Input, message, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { userSignIn, userSignUp } from '../../redux/actions/user/user.actions'
import { google_client_id, google_captcha_site_key } from './../../constant/constant'
import {
  generateCharacters,
  generatePassword,
  handleCompressedUpload,
  validatePassword,
  validatephonenumber,
} from '../../shared/config/globalFunctions'
import logo from '../../assets/images/digitaldavos.svg'
import GoogleLogo from '../../assets/images/google-logo.png'
import {
  onlyCharactersandNumbersAllowed,
  ruleImage,
} from '../AddEvent/eventFormRules'
import jwtDecode from 'jwt-decode'
import './style.scss'
import $ from 'jquery'

export default function SignUp() {
  const [image, setImage] = useState()
  const [password, setPassword] = useState()
  const [formData, setFormData] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const { state } = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const onChange = (e) => {
    const target = e.target
    setFormData((prevState) => ({
      ...prevState,
      [target.name]: target.checked === true ? 1 : 0,
    }))
  }

  const handleCallbackResponse = (response) => {
    const userObject = jwtDecode(response.credential)
    const {
      email: email_id,
      password = generatePassword(),
      family_name,
      given_name,
      picture,
      name,
    } = userObject
    let payload = { email_id, password, family_name, given_name, picture, name }
    onFinish(payload, 'google')
  }

  const [credentials] = useState({
    client_id: google_client_id,
    callback: handleCallbackResponse,
  })

  useEffect(() => {
    /* global google */
    if (!google) return null
    google?.accounts?.id?.initialize(credentials)
    google?.accounts?.id?.renderButton(document.getElementById('signInDiv'), {
      theme: '#323248',
      size: 'large',
      width: '100%',
    })
    google?.accounts?.id?.prompt()
    $('.signInDiv').css('opacity', '.1')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id)

      if (!isScriptExist) {
        let script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = url
        script.id = id
        script.onload = function () {
          if (callback) callback()
        }
        document.body.appendChild(script)
      }

      if (isScriptExist && callback) callback()
    }

    // load the script by passing the URL
    loadScriptByURL(
      'recaptcha-key',
      `https://www.google.com/recaptcha/api.js?render=${google_captcha_site_key}`
    )
    return () => unloadScriptByURL()
  }, [])

  const unloadScriptByURL = () => {
    const script = document.getElementById('recaptcha-key')
    if (script) {
      script.remove()
    }

    const recaptchaElems = document.getElementsByClassName('grecaptcha-badge')
    if (recaptchaElems.length) {
      recaptchaElems[0].remove()
    }
  }

  const callbackFun = (data) => {
    setImage(data)
  }

  const signInCallBack = (payload) => {
    let payloadata = {}
    payloadata['email_id'] = payload?.email_id
    payloadata['password'] = payload?.password
    payloadata['isSignInWithGoogle'] = true
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(google_captcha_site_key, { action: 'submit' })
        .then((token) => {
          payloadata['g_recaptcha_response'] = token
          payloadata['isLogin'] = true
          dispatch(userSignIn(payloadata)).then((res) => {
            if (res.status === 200) {
              setIsLoading(false)
              if (state) {
                if (state.eventId)
                  navigate('/', {
                    state: {
                      eventId: state.eventId,
                      applyEventFlag: true,
                      btnflag: true,
                    },
                  })
              } else {
                navigate('/')
              }
            } else {
              setIsLoading(false)
            }
          })
        })
    })
  }

  const onFinish = async (data, flag) => {
    console.log(data, flag)
    let payloadata = {}
    const {
      password = generatePassword(),
      picture,
      family_name,
      given_name,
    } = data

    if (!flag) {
      data['roles'] = '6'
      if (formData.send_invitation) {
        data['send_invitation'] = formData.send_invitation
      }
      if (formData.hidden_from_others) {
        data['hidden_from_others'] = formData.hidden_from_others
      }
      data['image'] = image
    } else {
      payloadata['send_invitation'] = '1'
      payloadata['roles'] = '6'
      payloadata['image'] = picture
      payloadata['email_id'] = data?.email_id
      payloadata['password'] = password
      payloadata['company_name'] = given_name + generateCharacters()
      payloadata['first_name'] = family_name
      payloadata['last_name'] = given_name
      payloadata['isRegisterWithGoogle'] = 1
    }
    setIsLoading(true)
    signupUser(flag, payloadata, data)
  }

  const signupUser = (flag, payloadata, data) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(google_captcha_site_key, { action: 'submit' })
        .then((token) => {
          if (flag) {
            payloadata['g_recaptcha_response'] = token
          } else {
            data['g_recaptcha_response'] = token
          }

          dispatch(userSignUp(flag ? payloadata : data)).then(async (res) => {
            // If User is alredy Signup
            if (res === 'dosigning') {
              if (flag === 'google') {
                signInCallBack(flag ? payloadata : data)
              } else {
                setIsLoading(false)
                message.error('Email is already in use!')
              }
            } else {
              // make user Login
              loginUser({
                email_id: flag ? payloadata?.email_id : data?.email_id,
                password: flag ? payloadata?.password : data?.password
              }, res)
            }
          })
        })
    })
  }

  const loginUser = (loginData, lres) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(google_captcha_site_key, { action: 'submit' })
        .then((token) => {
          let loginPayload = {
            email_id: loginData?.email_id,
            password: loginData?.password,
            g_recaptcha_response: token,
            isLogin: true,
            isRegisterWithGoogle: 1
          }
          // make user login
          dispatch(userSignIn(loginPayload)).then((res) => {
            if (res.status === 200) {
              setIsLoading(false)
              if (state) {
                if (state.eventId)
                  navigate('/', {
                    state: {
                      eventId: state.eventId,
                      applyEventFlag: true,
                      btnflag: true,
                    },
                  })
              } else {
                navigate('/')
              }
            } else {
              setIsLoading(false)
            }
          })
        })
    })
    message.success(lres.data.message)
  }


  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
  }

  return (
    <div className="form-cover">
      <div className="userformmbox signup-box">
        <div className="form-feildbox">
          <div className="logo-signin">
            <img src={logo} alt="profile" />
          </div>
          <h1> Sign Up to your account </h1>
          <Form
            name="sign-in"
            className="sign-in-form-wrapper"
            onFinish={(e, data) => onFinish(e, data)}
          >
            <div className="form-feildbox-row">
              <Form.Item
                name={['first_name']}
                rules={[
                  {
                    type: 'text',
                    message: 'Please enter the valid First Name.',
                  },
                  {
                    required: true,
                    message: 'First Name is required.',
                  },
                ]}
              >
                <Input
                  placeholder="First Name *"
                  onKeyDown={onlyCharactersandNumbersAllowed}
                  maxLength="18"
                />
              </Form.Item>
            </div>
            <div className="form-feildbox-row required-field">
              <Form.Item
                name={['last_name']}
                rules={[
                  {
                    type: 'text',
                    message: 'Please enter the valid Last Name.',
                  },
                  {
                    required: true,
                    message: 'Last Name is required.',
                  },
                ]}
              >
                <Input
                  placeholder="Last Name *"
                  onKeyDown={onlyCharactersandNumbersAllowed}
                  maxLength="18"
                />
              </Form.Item>
            </div>
            <div className="form-feildbox-row">
              <Form.Item
                name={['email_id']}
                rules={[
                  {
                    type: 'email',
                    message: 'Please enter a valid Email Address.',
                  },
                  {
                    required: true,
                    message: 'Email Address is required.',
                  },
                ]}
              >
                <Input placeholder="Email Address *" />
              </Form.Item>
            </div>
            <div className="form-feildbox-row">
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Password is required.',
                  },
                  () => ({
                    validator(_, value) {
                      setPassword(value)
                      if (value) {
                        return Promise.reject(validatePassword(value))
                      }
                      return Promise.resolve()
                    },
                  }),
                ]}
              >
                <Input.Password
                  className="form-input"
                  type="password"
                  placeholder="Password *"
                  style={{ marginTop: '9px' }}
                />
              </Form.Item>
            </div>
            <div className="form-feildbox-row">
              <Form.Item
                name={['confirmPassword']}
                rules={[
                  {
                    type: 'password',
                    message: 'The input is not valid confirm password!',
                  },
                  {
                    required: true,
                    message: 'Please Enter confirm password',
                  },
                  () => ({
                    validator(_, value) {
                      if (value !== password) {
                        return Promise.reject(
                          'Password and Confirm Password did not match.'
                        )
                      }
                      if (value) {
                        return Promise.reject(validatePassword(value))
                      }
                      return Promise.resolve()
                    },
                  }),
                ]}
              >
                <Input.Password
                  type="password"
                  className="form-input"
                  style={{ marginTop: '9px' }}
                  placeholder="Confirm password *"
                />
              </Form.Item>
            </div>
            <div className="form-feildbox-row">
              <Form.Item
                name={['phone_number']}
                rules={[
                  {
                    required: true,
                    message: 'Phone Number is required.',
                  },
                  () => ({
                    validator(_, value) {
                      if (value) {
                        return Promise.reject(validatephonenumber(value))
                      }
                      return Promise.resolve()
                    },
                  }),
                ]}
              >
                <Input
                  placeholder="Phone Number *"
                  maxLength="17"
                  minLength="5"
                />
              </Form.Item>
            </div>
            <div className="form-feildbox-row">
              <Form.Item
                name={['company_name']}
                rules={[
                  {
                    type: 'text',
                    message: 'Please enter the valid Company Name.',
                  },
                  {
                    required: true,
                    message: 'Company Name is required.',
                  },
                ]}
              >
                <Input placeholder="Company Name *" maxLength="32" />
              </Form.Item>
            </div>
            <div className="form-feildbox-row">
              <Form.Item name={['bio_link']}>
                <Input placeholder="Bio Link" />
              </Form.Item>
            </div>
            <div className="form-feildbox-row">
              <Form.Item name="image">
                <Upload
                  name="logo"
                  listType="picture"
                  maxCount={1}
                  customRequest={dummyRequest}
                  onChange={(e) =>
                    handleCompressedUpload(e?.file?.originFileObj, callbackFun)
                  }
                  beforeUpload={ruleImage}
                >
                  <Button
                    icon={<UploadOutlined />}
                    style={{ background: '#323248' }}
                    htmlType="button"
                  >
                    Click to upload
                  </Button>
                </Upload>
              </Form.Item>
            </div>
            <div className="checkbox-row">
              <div className="custom-checkbox">
                <input
                  type="checkbox"
                  id="invite"
                  name="send_invitation"
                  onChange={(e) => onChange(e)}
                />
                <span></span>
                <label htmlFor="invite">Send Invitation</label>
              </div>
            </div>
            <div className="checkbox-row">
              <div className="custom-checkbox">
                <input
                  type="checkbox"
                  id="users-select"
                  name="hidden_from_others"
                  onChange={(e) => onChange(e)}
                />
                <span></span>
                <label htmlFor="users-select">Hidden from others</label>
              </div>
            </div>
            <Form.Item>
              {isLoading ? (
                <Button type="primary" htmlType="submit" loading>
                  Register
                </Button>
              ) : (
                <Button type="primary" htmlType="submit">
                  Register
                </Button>
              )}
            </Form.Item>
            <p className="register-link">
              Go back to <Link to="/login">Login</Link>
            </p>
            <div className="or-line-box">
              <span className="or-line">OR</span>
            </div>
            <div className="google-signin-wrapper">
              <div id="signInDiv" className="google-signin-btn-box"></div>
              <button type="button" className="google-login-btn">
                <img src={GoogleLogo} alt="google logo" />
                Sign in with Google
              </button>
            </div>
          </Form>
          <div className="getin-touchtext">
            <p>
              <Link to="/forgot-password">Forgot password?</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
