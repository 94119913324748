import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { persistStore } from 'redux-persist'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from './reducers/index.reducer'

// eslint-disable-next-line import/no-anonymous-default-export
export default (initialState) => {
  let store

  const { persistReducer } = require('redux-persist')
  const storage = require('redux-persist/lib/storage').default

  const persistConfig = {
    key: 'root',
    storage,
  }
  store = createStore(
    persistReducer(persistConfig, rootReducer),
    composeWithDevTools(applyMiddleware(thunkMiddleware)),
    initialState
  )
  store.__PERSISTOR = persistStore(store)

  if (module.hot) {
    module.hot.accept('./reducers/index.reducer', () => {
      const createNextReducer = require('./reducers/index.reducer').default
      // passed initialState to adapt changes in reducers
      store.replaceReducer(createNextReducer(initialState))
    })
  }

  return store
}
