import React, { useEffect, useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import {
  userAuthoritiesRole,
  userToken,
} from '../../redux/selectors/user/user.selector'
import RoleContext from '../../context/roleContext'
import { getRolesInArr } from '../../shared/config/globalFunctions'
import AttendeeScreen from '../Home'

const Screens = ({ isAuthenticated }) => {
  const token = useSelector(userToken)
  const userAuthorities = useSelector(userAuthoritiesRole)
  const { activeRole, setActiveRole } = useContext(RoleContext)
  const [roles, setRoles] = useState([])
  const screenAccess = ['1', '2', '3', '4', '5', '6']
  const screenAccess_temp = [1, 2, 3, 4, 5, 6]


  useEffect(() => {
    if (userAuthorities) handleRole()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAuthorities, activeRole])

  const handleRole = () => {
    let roleArr = getRolesInArr(userAuthorities)
    setRoles(roleArr)
    setActiveRole(roleArr[0])
  }

  if (!token)
    return <AttendeeScreen isAuthenticated={isAuthenticated} roles={roles} />

  return (
    <>
      {(screenAccess.includes(activeRole) || screenAccess_temp.includes(activeRole)) && (
        <AttendeeScreen isAuthenticated={isAuthenticated} roles={roles} />
      )}
    </>
  )
}

export default Screens
