import React, { useEffect, useState } from 'react'
import QRCode from 'qrcode'
import NavBar from '../../components/NavBar'
import './MyQRCode.scss'
import { userAuthoritiesRole, userToken } from '../../redux/selectors/user/user.selector'
import { useSelector } from 'react-redux'
import jwtDecode from 'jwt-decode'
import { getRolesInArr } from '../../shared/config/globalFunctions';

export default function MyQRCode({ isAuthenticated }) {
  const token = useSelector(userToken)
  const decodedToken = jwtDecode(token)
  const userAuthorities = useSelector(userAuthoritiesRole)
  const [roles, setRoles] = useState([])
  let { id } = decodedToken

  useEffect(() => {
    GenerateQRCode(id)
  }, [id])


  useEffect(() => {
    if (userAuthorities) {
      let roles_arr = getRolesInArr(userAuthorities)
      setRoles(roles_arr)
    }
  }, [userAuthorities])

  const [qr, setQr] = useState('')
  const GenerateQRCode = (url) => {
    QRCode.toDataURL(url, {
      width: 300,
      margin: 2,
      color: {
        dark: '#0000',
        light: '#EEEEEEFF'
      }
    }, (err, url) => {
      if (err) return console.error(err)
      setQr(url)
    })
  }

  return (
    <div>
      <NavBar isAuthenticated={isAuthenticated} roles={roles} />
      <div className='main-page-content'>
        <h1 className="headerqr color">QR Code</h1>
        <div className="headerqr">
          {qr && (
            <>
              <a href={qr} download="qrcode.png">
                <img src={qr} alt="QR code" />
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
