import { Spin } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NavBar from '../../components/NavBar'
import { LoginSSo } from '../../redux/actions/discourse/discourse.actions'
import {
  userAuthoritiesRole,
  userInformation,
} from '../../redux/selectors/user/user.selector'
import { getRolesInArr } from '../../shared/config/globalFunctions'
import { useCallback } from 'react'

const Network = ({ isAuthenticated }) => {
  const dispatch = useDispatch()
  const userAuthorities = useSelector(userAuthoritiesRole)
  const userInfo = useSelector(userInformation)
  const [roles, setRoles] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const queryParams = new URLSearchParams(window.location.search)
  const sso = queryParams.get('sso')
  const sig = queryParams.get('sig')

  const loginSSoHandler = useCallback(async () => {
    let payload = {
      email_id: userInfo?.email_id,
      user_id: userInfo?.discourse_id,
    }
    if (payload) {
      await dispatch(LoginSSo(payload, sso, sig)).then(async (res) => {
        let Url = res?.data
        if (Url) {
          window.location.href = Url
          setIsLoading(false)
        }
      })
    }
  }, [dispatch, sso, sig, userInfo?.discourse_id, userInfo?.email_id])

  useEffect(() => {
    if (userAuthorities) {
      let roles_arr = getRolesInArr(userAuthorities)
      setRoles(roles_arr)
    }
  }, [userAuthorities])

  useEffect(() => {
    setIsLoading(true)
    loginSSoHandler(sso, sig)
  }, [loginSSoHandler, sig, sso])

  return (
    <>
      {isLoading ? (
        <div className="spinner-myevents" style={{ height: '100vh' }}>
          <Spin tip="Please wait we are authenticating you over discourse."></Spin>
        </div>
      ) : (
        <NavBar isAuthenticated={isAuthenticated} roles={roles} />
      )}
    </>
  )
}

export default Network
