import Axios from '../../../shared/config/axiosConfig'
import {
  handleError,
  jsonToFormData,
} from '../../../shared/config/globalFunctions'
import { LOGGED_IN, LOG_OUT, UPDATE_USER } from '../../types/user'

export const userSignIn =
  (data, flag = '', googleUser = {}, signInCallBack = () => {}) =>
  async (dispatch) => {
    try {
      // Login with google flag
      if (Object.keys(googleUser).length > 0) data.isSignInWithGoogle = true
      const response = await Axios.post('auth/signin', data)
      if (response.status === 200 && !response.data.hasError) {
        Axios.defaults.headers['x-access-token'] =
          response.data.data.accessToken
        dispatch({
          type: LOGGED_IN,
          payload: response.data.data,
        })
      }
      let returnData = {
        data: response.data,
        status: response.status,
      }
      return returnData
    } catch (error) {
      if (flag === 'google') {
        signInCallBack(googleUser)
      } else return handleError(error, dispatch)
    }
  }

export const userSignUp =
  (data, flag = '', signInCallBack = () => {}) =>
  async (dispatch) => {
    const formData = await jsonToFormData(data)
    try {
      const response = await Axios.post('auth/signup', formData)
      let returnData = {
        data: response.data,
        status: response.status,
      }
      return returnData
    } catch (error) {
      if (!flag) {
        if (error?.response?.data?.message === 'Email is already in use!') {
          return 'dosigning'
        } else {
          return handleError(error, dispatch)
        }
      } else {
        signInCallBack(data)
      }
    }
  }

export const DescourseDetais = (data) => async (dispatch) => {
  try {
    const response = await Axios.post('/update/user/discourse_details', data)
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const updateProfile = (data) => async (dispatch) => {
  const formData = await jsonToFormData(data)
  try {
    const response = await Axios.post(`/update/user`, formData)
    if (response.status === 200 && !response.data.hasError) {
      if (response.data.data) {
        dispatch({
          type: UPDATE_USER,
          payload: response.data.data,
        })
      }
    }
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const userSignOut = (data) => async (dispatch) => {
  try {
    Axios.defaults.headers['x-access-token'] = null
    dispatch({
      type: LOG_OUT,
    })
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const ChangePasswordAPI = (data) => async (dispatch) => {
  try {
    const response = await Axios.post('update/password', data)
    if (response.status === 200 && !response.data.hasError) {
    }
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const ForgotPasswordAPI = (data) => async (dispatch) => {
  try {
    const response = await Axios.post('forgot/password', data)
    if (response.status === 200 && !response.data.hasError) {
    }
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const ResetPasswordAPI = (data) => async (dispatch) => {
  try {
    const response = await Axios.post('reset/password', data)
    if (response.status === 200 && !response.data.hasError) {
    }
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}

export const QuickAddonUser = (data) => async (dispatch) => {
  const formData = await jsonToFormData(data)
  try {
    const response = await Axios.post('quick-addon/user', formData)
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error, dispatch)
  }
}
