import React from 'react'
import { useLocation } from 'react-router-dom'

const NotFound = () => {
  const location = useLocation()

  return (
    <>
      <div className="form-cover error-middle">
        <h1>404</h1>
        <p>
          We couldn't find <u>{location.pathname}</u> page
        </p>
      </div>
    </>
  )
}

export default NotFound
