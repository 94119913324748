import React, { useState } from 'react'
import { Button, Form, Input, message } from 'antd'
import './ChangePassword.scss'
import { useDispatch } from 'react-redux'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { ChangePasswordAPI } from '../../redux/actions/user/user.actions'
import { useNavigate } from 'react-router-dom'
import { validatePassword } from '../../shared/config/globalFunctions'

export default function ChangePassword() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [password, setPassword] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const onFinish = (data) => {
    setIsLoading(true)
    dispatch(ChangePasswordAPI(data)).then((res) => {
      if (res.status === 200) {
        message.success(res.data.message)
        setIsLoading(false)
        navigate('/')
      } else {
        setIsLoading(false)
      }
    })
  }
  return (
    <div className="form-cover forgot-password change-pass">
      <div className="userformmbox">
        <div className="form-feildbox">
          <button className="back-icon" onClick={() => navigate('/')}>
            <ArrowLeftOutlined />
          </button>
          <h1> Change Password </h1>
          <Form name="sign-in" onFinish={(data) => onFinish(data)}>
            <div className="form-feildbox-row">
              <Form.Item
                name={['oldPassword']}
                rules={[
                  {
                    type: 'text',
                    message: 'The input is not valid old password!',
                  },
                  {
                    required: true,
                    message: 'Please Enter old password',
                  },
                  () => ({
                    validator(_, value) {
                      setPassword({ ...password, oldPassword: value })
                      return Promise.resolve()
                    },
                  }),
                ]}
              >
                <Input.Password type="password" placeholder="Old password *" />
              </Form.Item>
            </div>
            <div className="form-feildbox-row">
              <Form.Item
                name={['password']}
                rules={[
                  {
                    type: 'text',
                    message: 'The input is not valid new password!',
                  },
                  {
                    required: true,
                    message: 'Please Enter new password',
                  },
                  () => ({
                    validator(_, value) {
                      setPassword({ ...password, newpassword: value })
                      if (value === password?.oldPassword) {
                        return Promise.reject(
                          'Old password and new password must be diffrent!.'
                        )
                      }
                      if (value) {
                        return Promise.reject(validatePassword(value))
                      }
                      return Promise.resolve()
                    },
                  }),
                ]}
              >
                <Input.Password type="password" placeholder="New password *" />
              </Form.Item>
            </div>
            <div className="form-feildbox-row">
              <Form.Item
                name={['confirmPassword']}
                rules={[
                  {
                    type: 'password',
                    message: 'The input is not valid confirm password!',
                  },
                  {
                    required: true,
                    message: 'Please Enter confirm password',
                  },
                  () => ({
                    validator(_, value) {
                      if (value !== password?.newpassword) {
                        return Promise.reject(
                          'New password and Confirm password did not match.'
                        )
                      }
                      if (value) {
                        return Promise.reject(validatePassword(value))
                      }
                      return Promise.resolve()
                    },
                  }),
                ]}
              >
                <Input.Password
                  type="password"
                  placeholder="Confirm password *"
                />
              </Form.Item>
            </div>
            <div className="form-feildbox-row">
              <Form.Item>
                {isLoading ? (
                  <Button type="primary" htmlType="submit" loading>
                    Update Password
                  </Button>
                ) : (
                  <Button type="primary" htmlType="submit">
                    Update Password
                  </Button>
                )}
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}
