import axios from 'axios'
import store from '../../redux/store'

const storeInstance = store()
const CancelToken = axios.CancelToken
export const cancelSource = CancelToken.source()
const requestInterceptor = (config) => {
  config.cancelToken = cancelSource.token
  return config
}

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

axiosInstance.interceptors.request.use(requestInterceptor)

const listner = () => {
  const { authToken } = storeInstance.getState().auth
  axiosInstance.defaults.headers['x-access-token'] = authToken
    ? authToken
    : null
}

storeInstance.subscribe(listner)

export default axiosInstance
