import { DownloadOutlined, UploadOutlined } from '@ant-design/icons'
import { Select, Form, Modal, Button, Input, Upload, message } from 'antd'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ruleCsv, ruleImage } from '../../pages/AddEvent/eventFormRules'
import {
  AddGuestMasterEvent,
  bulkUploadAttendee,
  BulkUploadAttendeeChildeEvent,
} from '../../redux/actions/event/event.actions'
import { handleCompressedUpload } from '../../shared/config/globalFunctions'

function ModalEvents({ setOpenModal, openmodal, addGuestmodalData, flag }) {
  const dispatch = useDispatch()
  const [formData, setFormData] = useState({})
  const [image, setImage] = useState()
  const [file, setFile] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const cvsBlockUpload = async (event_id, flag) => {
    if (!event_id) return null
    let payload = {}
    if (flag === 'parent') {
      payload = { event_id, file }
    } else {
      payload = { childevent_id: event_id, file }
    }
    if (Object.keys(payload.file ?? {}).length === 0) return
    if (flag === 'parent') {
      await dispatch(bulkUploadAttendee(payload))
    } else {
      await dispatch(BulkUploadAttendeeChildeEvent(payload))
    }
  }

  const callbackFun = (data) => {
    setImage(data)
  }

  const onFinish = async (data) => {
    setIsLoading(true)
    if (flag === 'parent') {
      data['event_id'] = addGuestmodalData
    } else {
      data['childevent_id'] = addGuestmodalData
    }
    data['send_invitation'] = formData.send_invitation
    data['hidden_from_others'] = formData.hidden_from_others
    data['image'] = image
    dispatch(AddGuestMasterEvent(data)).then(async (res) => {
      if (res.status === 200) {
        setIsLoading(false)
        setOpenModal(false)

        // await discourseUser(data)

        // Bluk Upload for Event.
        await cvsBlockUpload(addGuestmodalData, flag)
        message.success(res.data.message)
      } else {
        setOpenModal(false)
        setIsLoading(false)
      }
    })
  }

  const onChange = (e) => {
    const target = e.target
    setFormData((prevState) => ({
      ...prevState,
      [target.name]: target.checked === true ? 1 : 0,
    }))
  }

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
  }

  return (
    <Modal
      title="Add Guest"
      centered
      destroyOnClose
      open={openmodal}
      onCancel={() => {
        setOpenModal(false)
      }}
      footer={null}
      className="add-guest-modal"
    >
      <Form name="sign-in" onFinish={(data) => onFinish(data)}>
        <div className="display">
          <div className="left-add-guest">
            <div className="form-row">
              <Form.Item
                layout="vertical"
                className="sort-select"
                label="VIP Levels"
                name={['vip_level']}
                rules={[
                  {
                    required: true,
                    message: 'VIP Levels is required.',
                  },
                ]}
              >
                <Select placeholder="VIP Level">
                  <Select.Option value="1">VIP Level 1</Select.Option>
                  <Select.Option value="2">VIP Level 2</Select.Option>
                  <Select.Option value="3">VIP Level 3</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                layout="vertical"
                className="sort-select"
                label="First Name"
                name={['first_name']}
                rules={[
                  {
                    type: 'text',
                    message: 'Please enter a valid First Name.',
                  },
                  {
                    required: true,
                    message: 'First Name is required.',
                  },
                ]}
              >
                <Input placeholder="First Name" maxLength="18" />
              </Form.Item>
              <Form.Item
                layout="vertical"
                className="sort-select"
                label="Last Name"
                name={['last_name']}
                rules={[
                  {
                    type: 'text',
                    message: 'Please enter a valid Last Name.',
                  },
                  {
                    required: true,
                    message: 'Last Name is required.',
                  },
                ]}
              >
                <Input placeholder="Last Name" maxLength="18" />
              </Form.Item>
              <Form.Item
                layout="vertical"
                className="sort-select"
                label="Company Name"
                name={['company_name']}
                rules={[
                  {
                    type: 'text',
                    message: 'Please enter a valid Company Name.',
                  },
                  {
                    required: true,
                    message: 'Company Name is required.',
                  },
                ]}
              >
                <Input placeholder="Company Name" maxLength="32" />
              </Form.Item>
              <Form.Item
                layout="vertical"
                className="sort-select"
                label="Email Address"
                name={['email_id']}
                rules={[
                  {
                    type: 'email',
                    message: 'Please enter a valid Email Address.',
                  },
                  {
                    required: true,
                    message: 'Email Address is required.',
                  },
                ]}
              >
                <Input placeholder="Email Address" />
              </Form.Item>
              <Form.Item
                layout="vertical"
                className="sort-select"
                label="Phone Number"
                name={['phone_number']}
                rules={[
                  {
                    required: false,
                    message: 'Phone Number is required.',
                  },
                ]}
              >
                <Input
                  name="phone_number"
                  placeholder="Phone Number"
                  maxLength="17"
                  minLength="5"
                />
              </Form.Item>
              <Form.Item
                layout="vertical"
                className="sort-select add-guest-upload"
                name={['image']}
                label="Profile Image"
              >
                <Upload
                  name="logo"
                  listType="picture"
                  customRequest={dummyRequest}
                  maxCount={1}
                  onChange={(e) =>
                    handleCompressedUpload(e?.file?.originFileObj, callbackFun)
                  }
                  beforeUpload={ruleImage}
                >
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </Form.Item>
              <Form.Item
                layout="vertical"
                className="sort-select csv-input add-guest-upload"
                label="Upload Attendes CSV"
              >
                <Upload
                  name="logo"
                  listType="picture"
                  customRequest={dummyRequest}
                  maxCount={1}
                  beforeUpload={ruleCsv}
                  onChange={({ fileList }) =>
                    fileList[0] && setFile(fileList[0].originFileObj)
                  }
                >
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
                <div className="add-guest-csv">
                  <Button
                    className="btn sample-csv-btn"
                    type="primary"
                    htmlType="button"
                    onClick={() =>
                      window.open(
                        process.env.REACT_APP_SAMPLE_CSV_LINK,
                        '_self'
                      )
                    }
                  >
                    <DownloadOutlined />
                    Sample CSV File
                  </Button>
                </div>
              </Form.Item>

              <div className="check-box">
                <div className="checkbox-row">
                  <div className="custom-checkbox">
                    <input
                      type="checkbox"
                      id="users-select"
                      name="send_invitation"
                      onChange={(e) => onChange(e)}
                    />
                    <span></span>
                    <label htmlFor="users-select">Send Invitation</label>
                  </div>
                </div>
                <div className="checkbox-row">
                  <div className="custom-checkbox">
                    <input
                      type="checkbox"
                      id="hidden"
                      name="hidden_from_others"
                      onChange={(e) => onChange(e)}
                    />
                    <span></span>
                    <label htmlFor="hidden">Hidden from others</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-custom-footer">
          <div className="btns-footer-box">
            <Button
              className="btn danger-btn"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </Button>
            {isLoading ? (
              <Button type="primary" htmlType="submit" loading>
                Add Guest
              </Button>
            ) : (
              <Button type="primary" htmlType="submit">
                Add Guest
              </Button>
            )}
            {/* <Button className='btn' htmlFor='submit'>
            Add Guest
          </Button> */}
          </div>
        </div>
      </Form>
    </Modal>
  )
}

export default ModalEvents
