import React, { useEffect, useRef, useState } from 'react'
import { Select, Form, Input, message, Modal } from 'antd'
import img from '../../assets/images/default-user-img.png'
import noData from '../../assets/images/NoData.png'
import './ManagerEvent.scss'
import { useSwipeable } from 'react-swipeable'
import { useDispatch } from 'react-redux'
import { userSignOut } from '../../redux/actions/user/user.actions'
import {
  getAttendeeofOnsitemanager,
  GetEvent,
  updateAcessRestriction,
  UpdateAteendeeVIPlevelonsite,
} from '../../redux/actions/event/event.actions'
import {
  capitalizeFirstLetter,
  ImageData,
} from '../../shared/config/globalFunctions'
import moment from 'moment'
import {
  ExclamationCircleOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'

const ManagerEvent = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const UseEffectOnce = useRef(true)
  const [details, setDetails] = useState({})
  const [eventDetails, setEventDetails] = useState([])
  const [index, setIndex] = useState(0)
  let eventid = localStorage.getItem('event_id')

  const [RestrictValue] = useState([
    {
      name: 'Restrict Level 1',
      value: 1,
    },
    {
      name: 'Restrict Level 2',
      value: 2,
    },
    {
      name: 'Restrict Level 3',
      value: 3,
    },
    {
      name: 'Stop',
      value: 0,
    },
  ])



  const onChangeEventHandler = useCallback(async (eventid) => {
    const res = await dispatch(GetEvent(eventid))
    let details = res?.data
    setDetails(details)
    handleacces(details?.restrict_access)
    dispatch(getAttendeeofOnsitemanager(eventid)).then((res) => {
      if (res.status === 200) {
        setEventDetails(res?.data?.data)
      }
    })
  }, [dispatch])

  const swipeHandlers = useSwipeable({
    onSwiped: ({ dir }) => swiperdata(dir),
    trackMouse: true,
    // ...config,
  })

  const swiperdata = (dir) => {
    let userData = eventDetails[index]?.userId
    let id = eventDetails[index]?.childeventId
      ? eventDetails[index]?.childeventId
      : eventDetails[index]?.eventId
    let payload = {
      eventId: id,
      userId: userData,
      vip_level: 1,
      isBlocked: false,
    }
    if (dir === 'Left') {
      if (index !== eventDetails.length - 1) {
        setIndex(index + 1)
        payload.isBlocked = true
      } else {
        setIndex(0)
        payload.isBlocked = true
      }
    } else if (dir === 'Right') {
      if (index !== eventDetails.length - 1) {
        setIndex(index + 1)
        payload.vip_level = 2
      } else {
        setIndex(0)
        payload.vip_level = 2
      }
    } else if (dir === 'Up') {
      if (index !== eventDetails.length - 1) {
        setIndex(index + 1)
        payload.vip_level = 3
      } else {
        setIndex(0)
        payload.vip_level = 3
      }
    } else {
      if (index !== eventDetails.length - 1) {
        setIndex(index + 1)
        payload.vip_level = 1
      } else {
        setIndex(0)
        payload.vip_level = 1
      }
    }
    dispatch(UpdateAteendeeVIPlevelonsite(payload)).then((res) => {
      if (res.status === 200) {
        message.success(res?.data?.message)
      }
    })
  }

  const onChange = (data) => {
    let payload = {
      eventId: eventid,
      restriction_level: data,
    }
    dispatch(updateAcessRestriction(payload)).then((res) => {
      if (res.status === 200) {
        message.success(res?.data?.message)
      }
    })
  }

  // logout handler ask confirmation to logout
  const logoutHandler = () => {
    Modal.confirm({
      title: 'Are you sure you want to logout?',
      icon: <ExclamationCircleOutlined />,
      content: 'You can not revert back!!',
      okText: 'Logout',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        dispatch(userSignOut())
      },
      onCancel() { },
    })
  }

  const handleacces = (data) => {
    let flag
    if (data === 1) {
      flag = 1
    } else if (data === 2) {
      flag = 2
    } else if (data === 3) {
      flag = 3
    } else {
      flag = 0
    }
    return flag
  }

  useEffect(() => {
    if (UseEffectOnce.current) {
      UseEffectOnce.current = false
    }
    if (eventid) {
      onChangeEventHandler(eventid)
    }
  }, [eventid, onChangeEventHandler])

  return (
    <>
      <div className="manager-event-page onsite-manage-box">
        <div className="form-row centered-row">
          <Form.Item layout="vertical" className="sort-select">
            <Input disabled value={details ? details?.title : '-'} />
          </Form.Item>
        </div>
        <div className="form-row">
          <Form.Item
            layout="vertical"
            className="sort-select"
            label="Master Location"
          >
            <Input disabled value={details ? details?.location : '-'} />
          </Form.Item>
          {details && (
            <Form.Item
              layout="vertical"
              className="sort-select"
              label="ChildEvent Location"
            >
              <Input
                disabled
                value={
                  details?.childEvents
                    ? details?.childEvents[0].location
                    : details?.location
                }
              />
            </Form.Item>
          )}
          <Form.Item
            layout="vertical"
            className="sort-select"
            label="Start Date"
          >
            <Input
              disabled
              value={
                details
                  ? moment(details?.start_date).format('DD / MM / YYYY')
                  : moment().format('DD/MM/YYYY')
              }
            />
          </Form.Item>
          <Form.Item layout="vertical" className="sort-select" label="End Date">
            <Input
              disabled
              value={
                details
                  ? moment(details?.end_date).format('DD / MM / YYYY')
                  : moment().format('DD/MM/YYYY')
              }
            />
          </Form.Item>
        </div>
        <div>
          {eventDetails[index] ? (
            <div className="userformmbox" {...swipeHandlers}>
              <div className="form-feildbox">
                <div className="profile-wrapper" id={eventDetails[index]?.id}>
                  <div
                    className="profile-img-wrapper"
                    id={eventDetails[index]?.id}
                  >
                    <div className="profile-img" id={eventDetails[index]?.id}>
                      {eventDetails[index]?.childEventUserDetails ? (
                        <img
                          src={ImageData(
                            eventDetails[index]?.childEventUserDetails[0]
                              ?.image,
                            img
                          )}
                          alt="Profile"
                        />
                      ) : (
                        <img
                          src={ImageData(
                            eventDetails[index]?.userDetails[0]?.image,
                            img
                          )}
                          alt="Profile"
                        />
                      )}
                    </div>
                  </div>
                  <p className="name">
                    {eventDetails[index]?.childEventUserDetails
                      ? eventDetails[index]?.childEventUserDetails[0]
                        ?.first_name
                        ? capitalizeFirstLetter(
                          eventDetails[index]?.childEventUserDetails[0]
                            ?.first_name +
                          ' ' +
                          eventDetails[index]?.childEventUserDetails[0]
                            ?.last_name
                        )
                        : ''
                      : eventDetails[index]?.userDetails[0]?.first_name
                        ? capitalizeFirstLetter(
                          eventDetails[index]?.userDetails[0]?.first_name +
                          ' ' +
                          eventDetails[index]?.userDetails[0]?.last_name
                        )
                        : ''}
                  </p>
                  <p className="companyname">
                    {eventDetails[index]?.childEventUserDetails
                      ? eventDetails[index]?.childEventUserDetails[0]
                        ?.company_name
                        ? capitalizeFirstLetter(
                          eventDetails[index]?.childEventUserDetails[0]
                            ?.company_name
                        )
                        : ''
                      : eventDetails[index]?.userDetails[0]?.company_name
                        ? capitalizeFirstLetter(
                          eventDetails[index]?.userDetails[0]?.company_name
                        )
                        : ''}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="userformmbox">
              <div className="form-feildbox">
                <div className="profile-wrapper">
                  <div className="profile-img-wrapper">
                    <div className="profile-img-nodata">
                      <img src={noData} alt="Profile" />
                    </div>
                  </div>
                  <p className="name">No Data Found</p>
                </div>
              </div>
            </div>
          )}
          <div className="swipe-note">
            <p>
              <ArrowLeftOutlined /> Swipe left to User is not allowed to event
            </p>
            <p>
              {' '}
              <ArrowRightOutlined /> Swipe right to User is Guest List VIP Level
              2
            </p>
            <p>
              {' '}
              <ArrowUpOutlined /> Swipe up to User is Guest List VIP Level 3
            </p>
            <p>
              {' '}
              <ArrowDownOutlined /> Swipe down to User is Guest List VIP Level 1
            </p>
          </div>
          {details?.restrict_access && (
            <div className="switch-btns">
              <div className="form-row centered-row">
                <Form.Item layout="vertical" className="sort-select">
                  <Select
                    onChange={(data) => onChange(data)}
                    placeholder="Select Restrict Level"
                    defaultValue={handleacces(details?.restrict_access)}
                  >
                    {RestrictValue?.map(({ value, name }) => {
                      return (
                        <Select.Option key={value} value={value}>
                          {name}
                        </Select.Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </div>
            </div>
          )}
        </div>
        <div className="form-row centered-row">
          <button
            className="btn third-btn margin-top logout-btn"
            onClick={() => {
              navigate('/')
              localStorage.removeItem('event_id')
              localStorage.removeItem('id')
              localStorage.removeItem('flag')
            }}
          >
            Back
          </button>
        </div>
        <div className="form-row centered-row">
          <button
            className="btn third-btn margin-top logout-btn"
            onClick={() => {
              logoutHandler()
              localStorage.removeItem('event_id')
              localStorage.removeItem('id')
              localStorage.removeItem('flag')
            }}
          >
            Logout
          </button>
        </div>
      </div>
    </>
  )
}

export default ManagerEvent;