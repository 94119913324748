import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Pagination,
  Popover,
  Select,
  Spin,
  Tooltip,
} from 'antd'
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ClearOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  FilterTwoTone,
} from '@ant-design/icons'
import {
  AllEventsforattendee,
  Applyevents,
  DeleteEvent,
  GetEvent,
  ReMoveChildEvent,
} from '../../redux/actions/event/event.actions'
import { userInformation } from '../../redux/selectors/user/user.selector'
import NavBar from '../../components/NavBar'
import ModalViewEvents from '../../components/Modal/modalView'
import ModalQuickaddonView from '../../components/Modal/modalQuickaddonView'
import ModalEvents from '../../components/Modal'
import HomeCarousel from '../../components/HomeCarousel'
import moment from 'moment'
import {
  capitalizeFirstLetter,
  ExpiredDate,
  handleRoleForAdmin,
  handleRoleForCheckin,
  handleRoleForonsite,
  lable,
  sortEvents,
} from '../../shared/config/globalFunctions'
import './style.scss'

const AttendeeScreen = ({ isAuthenticated, roles }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { state } = useLocation()
  const [form] = Form.useForm()
  const { Option } = Select
  const userInfo = useSelector(userInformation)

  const [Quickaddonid, setQuickAddonId] = useState()
  const [masterId, setMasterId] = useState('')
  const [addGuestmodalData, setAddGuestModalData] = useState('')
  const [flag, setFlag] = useState('parent')
  const [ismodalopen, setIsModalOpen] = useState(false)
  const [openmodal, setOpenModal] = useState(false)
  const [pguestmodal, setPGuestModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [open, setOpen] = useState(false)
  const [toggle, setToggle] = useState(false)
  const [childeventfilter, setChildeventfilter] = useState(false)
  const [modalData, setModalData] = useState({})

  const initialEventResponse = {
    title: '',
    location: '',
    start_date: '',
    end_date: '',
    master_event_id: '',
    child_event_id: '',
    userId: '',
    rsvp: false,
    myEvent: false,
  }
  const [resData, setResData] = useState(initialEventResponse)
  let { id } = userInfo

  const [allEventsArr, setAllEventsArr] = useState({
    eventsList: [],
    filteredEvents: [],
    eventFilterList: [],
  })

  const [sortedData, setSortedData] = useState([])

  const [eventPagination, setEventPagination] = useState({
    skip: 0,
    limit: 50,
    total: 0,
  })

  const handleFilterForm = (e) => {
    const { id, value } = e.target
    setResData({ ...resData, [id]: value })
  }

  const isEventFilterUsed = useCallback(() => {
    let isUsed = false
    Object.keys(resData)?.forEach((property) => {
      if (property !== 'userId' && resData[property]) isUsed = true
    })
    return isUsed
  }, [resData])

  const fetchMasterEventData = useCallback(
    (resData, flag) => {
      let userId = id
      const params = {
        page: eventPagination.skip,
        limit: eventPagination.limit,
      }
      dispatch(AllEventsforattendee(resData, params, userId)).then((res) => {
        if (res.status === 200) {
          if (flag === 'filter')
            setAllEventsArr({
              ...allEventsArr,
              filteredEvents: sortEvents(res?.data?.data?.list).reverse(),
              eventFilterList: res?.data?.data?.events,
            })
          else
            setAllEventsArr({
              eventsList: res?.data?.data?.list,
              filteredEvents: sortEvents(res?.data?.data?.list).reverse(),
              eventFilterList: res?.data?.data?.events,
            })

          const { currentPage, totalItems } = res.data.data

          setEventPagination({
            ...eventPagination,
            total: totalItems,
            skip: currentPage,
          })
          setLoading(false)
        }
      })
    },
    [allEventsArr, dispatch, eventPagination, id]
  )

  const handlechangedata = (data) => {
    setMasterId(data)
    setResData({ ...resData, master_event_id: data })
    allEventsArr?.filteredEvents?.forEach((evt) => {
      if (data === evt?.eventId) {
        setChildeventfilter(true)
      }
      if (!data) setChildeventfilter(false)
    })
  }

  const ResetFilter = () => {
    form.resetFields()
    if (form.__INTERNAL__.name) {
      form.setFieldsValue({
        location: '',
        start_date: '',
        end_date: '',
        master_event_id: '',
        child_event_id: '',
        title: '',
      })
    }

    setResData(initialEventResponse)
    fetchMasterEventData(initialEventResponse, 'default')
    setChildeventfilter(false)
    setOpen(false)
  }

  const groupBy = (property, objectArray = []) => {
    return objectArray.reduce((acc, obj) => {
      const key = obj[property]
      if (!acc[key]) {
        acc[key] = []
      }
      // Add object to list for given key's value
      acc[key].push(obj)
      return acc
    }, {})
  }

  const getManagerEmailsChild = (childEventUser = []) => {
    let emailStr = ''
    childEventUser?.forEach(({ childEventUserDetails: childEventUser }) => {
      childEventUser.forEach(({ email_id }) => {
        emailStr += `${email_id},`
      })
    })
    emailStr = emailStr.slice(0, -1)
    return emailStr
  }

  const getAllManagers = (event) => {
    let res = []
    // Parent events managers.
    event?.eventUsers?.forEach((eventUser) => {
      let roleId = eventUser.roleId
      eventUser?.userDetails?.forEach((user) => {
        let payload = {
          eventId: event.id,
          managerId: user.id,
          isChildEvent: false,
          roleId: parseInt(roleId),
          email_id: user.email_id,
        }
        res.push(payload)
      })
    })

    // Child event manager
    event?.childEvents?.forEach((childEvent) => {
      childEvent?.childEventUsers?.forEach((childEventUser) => {
        let roleId = childEventUser.roleId
        childEventUser?.childEventUserDetails?.forEach((user) => {
          let payload = {
            eventId: childEvent.id,
            managerId: user.id,
            isChildEvent: true,
            roleId: parseInt(roleId),
            email_id: user.email_id,
          }
          res.push(payload)
        })
      })
    })

    return res
  }

  const getManagerEmails = (data = []) => {
    let emailStr = ''
    data.forEach((user) => {
      emailStr += `${user?.userDetails[0].email_id},`
    })
    emailStr = emailStr.slice(0, -1)
    return emailStr
  }

  const editEventHandler = async (record) => {
    setLoading(true)
    let eventId = record.eventId ? record?.eventId : record.id
    const res = await dispatch(GetEvent(eventId))
    const tempData = groupBy('roleId', res?.data?.eventUsers)

    let data = {
      event_id: eventId,
      title: res?.data?.title,
      location: res?.data?.location,
      start_date: res?.data?.start_date,
      end_date: res?.data?.end_date,
      event_banner: res?.data?.event_banner,
      description: res?.data?.description,
      master_manager: getManagerEmails(tempData['2']),
      child_manager: getManagerEmails(tempData['3']),
      onsite_manager: getManagerEmails(tempData['4']),
      checkin_manager: getManagerEmails(tempData['5']),
      childEvents: res?.data?.childEvents,
      managers: getAllManagers(res?.data),
      roles: roles,
    }

    data.childEvents = data?.childEvents?.map((childEvent) => {
      const childEventData = groupBy(childEvent?.childEventUsers, 'roleId')

      childEvent.master_manager = getManagerEmailsChild(childEventData['2'])
      childEvent.child_manager = getManagerEmailsChild(childEventData['3'])
      childEvent.onsite_manager = getManagerEmailsChild(childEventData['4'])
      childEvent.checkin_manager = getManagerEmailsChild(childEventData['5'])

      return childEvent
    })
    setLoading(false)
    navigate('/add-event', {
      state: { ...data, roles, eventroles: record?.roles },
    })
  }

  const handleDelete = (evt) => {
    Modal.confirm({
      title: 'Are you sure delete this event?',
      icon: <ExclamationCircleOutlined />,
      content: 'You can not revert back!!',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        if (evt?.eventId) {
          dispatch(ReMoveChildEvent(evt?.id)).then((res) => {
            if (res.status === 200) {
              fetchMasterEventData(resData)
              message.success(res.data.message)
            }
          })
        } else {
          dispatch(DeleteEvent(evt?.id)).then((res) => {
            if (res.status === 200) {
              fetchMasterEventData(resData)
              message.success(res.data.message)
            }
          })
        }
      },
      onCancel() { },
    })
  }

  const Eventhandler = useCallback(
    (e, evt, flag, btnflag, applyEventFlag = false) => {
      let value = ''
      let data
      if (flag === 'banner') {
        value = e
        data = {
          userId: id,
          eventId: evt,
          isApply: true,
          isCancel: false,
        }
      } else {
        value = e.target.value
        if (value === 'isAppied') {
          data = {
            userId: id,
            eventId: evt,
            isApply: true,
            isCancel: false,
          }
        } else {
          data = {
            userId: id,
            eventId: evt,
            isApply: false,
            isCancel: true,
          }
        }
      }

      if (applyEventFlag)
        dispatch(Applyevents(data)).then((res) => {
          if (res.status === 200) {
            message.success(res?.data?.message)
            if (btnflag || state?.btnflag) {
              fetchMasterEventData(resData)
            }
            navigate('.', { state: null })
          }
        })
    },
    [dispatch, fetchMasterEventData, id, navigate, resData, state?.btnflag]
  )

  const EventAppler = useCallback(
    (id, flag) => {
      let Event = {}
      Event = allEventsArr.filteredEvents.find((evt, i) => evt?.id === id)
      let data =
        lable(Event?.isPending, Event?.isRequested, Event?.isApproved)
          .btnname === 'Apply'
          ? 'isAppied'
          : 'pending'
      if (state?.eventId) Eventhandler(data, id, 'banner', flag)
    },
    [Eventhandler, allEventsArr.filteredEvents, state?.eventId]
  )

  const paginationTotal = () => {
    let num = parseInt(eventPagination.total / eventPagination.limit)
    if (eventPagination.total % eventPagination.limit !== 0) num = num + 1
    return num * 10
  }

  const popovercontent = (
    <div className="filter-popover">
      <Form form={form}>
        <Form.Item
          layout="vertical"
          className="sort-select"
          label="Location"
          id="location"
          name={'location'}
        >
          <Input
            id="location"
            onChange={handleFilterForm}
            placeholder="Search by Location"
          />
        </Form.Item>
        <Form.Item
          className="sort-select"
          label="Start Date:"
          key="start_date"
          name="start_date"
        >
          <DatePicker
            format="YYYY-MM-DD HH:mm"
            showTime={{
              format: 'HH:mm',
              hideDisabledOptions: true,
              use12Hours: true,
            }}
            onChange={(date) => {
              setResData({
                ...resData,
                start_date: moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
              })
            }}
          />
        </Form.Item>
        <Form.Item
          className="sort-select"
          label="End Date:"
          key="end_date"
          name="end_date"
        >
          <DatePicker
            format="YYYY-MM-DD HH:mm"
            showTime={{
              format: 'HH:mm',
              hideDisabledOptions: true,
              use12Hours: true,
            }}
            onChange={(date) => {
              setResData({
                ...resData,
                end_date: moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
              })
            }}
          />
        </Form.Item>
        <Form.Item
          className="sort-select"
          label="Master Event:"
          key="master_event_id"
          name="end_date"
        >
          <div className="search-select">
            <div className="search-view-child">
              <Select
                placeholder="Search by the house"
                onChange={handlechangedata}
                id="master_event_id"
                value={resData?.master_event_id || undefined}
                allowClear
              >
                {allEventsArr?.eventFilterList?.map((evt, i) => {
                  return (
                    <Option value={evt.id} key={`${evt.id}-${i}`}>
                      {capitalizeFirstLetter(evt.title)}
                    </Option>
                  )
                })}
              </Select>
              {childeventfilter === true && (
                <div className="search-view-child">
                  <Select
                    placeholder="Search by Child Under Master"
                    onChange={(data) =>
                      setResData({
                        ...resData,
                        child_event_id: data,
                      })
                    }
                  >
                    {allEventsArr.eventsList?.map((evt, i) => {
                      let evtid = evt?.eventId
                      if (evtid === masterId) {
                        return (
                          <Option value={evt.id} key={`${evt.id}-${i}`}>
                            {capitalizeFirstLetter(evt.title)}
                          </Option>
                        )
                      }
                    })}
                  </Select>
                </div>
              )}
            </div>
          </div>
        </Form.Item>
        <div className="disply-flex-btn">
          <Form.Item>
            {isEventFilterUsed() && (
              <Button
                id="resetbtn"
                type="primary"
                htmlType="submit"
                className="btn forth-btn"
                onClick={() => ResetFilter()}
              >
                <ClearOutlined />
              </Button>
            )}
          </Form.Item>
        </div>
      </Form>
    </div>
  )

  const switchRoleHandler = (e, event, role) => {
    e.preventDefault()
    let eventid = event.id
    let data = event?.eventId ? 'childevent_id' : 'masterevent_id'
    localStorage.setItem('flag', data)
    if (role === 'Onsite') {
      let eventdata = event?.userEventDetails.filter((evt) => evt?.roleId === 6)
      let id = eventdata[0]?.id
      localStorage.setItem('id', id)
      localStorage.setItem('event_id', eventid)
      navigate('/manager-event')
    } else {
      let eventdata = event?.userEventDetails.filter((evt) => evt?.roleId === 6)
      let id = eventdata[0]?.id
      localStorage.setItem('id', id)
      localStorage.setItem('event_id', eventid)
      localStorage.setItem('restrict_access', event?.restrict_access)
      navigate('/door-scan')
    }
  }

  useEffect(() => {
    if (allEventsArr) {
      let filteredEvents = [...allEventsArr.filteredEvents]
      const sortedEvents = sortEvents(filteredEvents).reverse()
      setSortedData(sortedEvents)
    }
  }, [allEventsArr])

  useEffect(() => {
    if (state) {
      if (state?.eventId && isAuthenticated)
        EventAppler(state?.eventId, state?.applyEventFlag)
    }
  }, [EventAppler, isAuthenticated, state])

  useEffect(() => {
    fetchMasterEventData(resData, 'default')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventPagination.skip])

  useEffect(() => {
    if (isEventFilterUsed()) {
      fetchMasterEventData(resData, 'filter')
    } else {
      fetchMasterEventData(resData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resData])

  return (
    <>
      {loading ? (
        <div className="spinner-myevents" style={{ height: '100vh' }}>
          <Spin tip="Loading..."></Spin>
        </div>
      ) : (
        <>
          <NavBar isAuthenticated={isAuthenticated} roles={roles} />
          <div className="main-page-content">
            <div className="banner-main">
              <div className="swipe-banner-box">
                <div className="event-banner-swipe">
                  <HomeCarousel
                    resData={resData}
                    fetchMasterEventData={fetchMasterEventData}
                    allEventsArr={allEventsArr}
                    isAuthenticated={isAuthenticated}
                    setAllEventsArr={setAllEventsArr}
                  />
                  <div className="swipe-note">
                    <p>
                      <ArrowLeftOutlined /> Swipe left to remove event
                    </p>
                    <p>
                      {' '}
                      Swipe right to apply event <ArrowRightOutlined />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="filter-top-bar main-page">
              <div className="left-filter">
                <p className="top-heading">My trail</p>
              </div>
              <div className="search-bar">
                <div className="search-wrapper">
                  <div className="search-view">
                    <Input
                      value={resData.title}
                      id="title"
                      placeholder="Search by trail"
                      onChange={handleFilterForm}
                    />
                  </div>
                  <Popover
                    content={popovercontent}
                    placement={'bottomRight'}
                    trigger="click"
                    open={open}
                    onOpenChange={(newOpen) => setOpen(newOpen)}
                  >
                    <button
                      className="btn third-btn desk-filter-btn"
                      onClick={(e) => e.preventDefault()}
                    >
                      <FilterTwoTone twoToneColor="#fff" /> Filter
                    </button>
                  </Popover>
                  <Form className="mob-filter" name="Filterform" form={form}>
                    <Form.Item
                      layout="vertical"
                      id="location"
                      className="sort-select"
                      name="location"
                    >
                      <Input
                        id="location"
                        onChange={(e) => {
                          const { location, ...rest } = resData
                          e != null
                            ? setResData({
                              ...resData,
                              location: e.target.value,
                            })
                            : setResData({ ...rest })
                        }}
                        placeholder="Search by Location"
                      />
                    </Form.Item>
                    <Form.Item
                      className="sort-select"
                      key="start_date"
                      name="start_date"
                    >
                      <DatePicker
                        format="YYYY-MM-DD HH:mm"
                        placeholder="Search by Start Date"
                        showTime={{
                          format: 'HH:mm',
                          hideDisabledOptions: true,
                          use12Hours: true,
                        }}
                        onChange={(date) => {
                          const e = {
                            target: {
                              id: 'start_date',
                              value: date
                                ? moment(date).format(
                                  'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
                                )
                                : '',
                            },
                          }
                          handleFilterForm(e)
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      className="sort-select"
                      key="end_date"
                      name="end_date"
                    >
                      <DatePicker
                        format="YYYY-MM-DD HH:mm"
                        placeholder="Search by End Date"
                        showTime={{
                          use12Hours: true,
                          format: 'HH:mm',
                          hideDisabledOptions: true,
                        }}
                        onChange={(date) => {
                          const e = {
                            target: {
                              id: 'end_date',
                              value: date
                                ? moment(date).format(
                                  'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
                                )
                                : '',
                            },
                          }
                          handleFilterForm(e)
                        }}
                      />
                    </Form.Item>
                    <div className="search-select">
                      <div className="search-view-child">
                        <Select
                          placeholder="Search by the house"
                          onChange={handlechangedata}
                          id="master_event_id"
                          value={resData?.master_event_id || undefined}
                          allowClear
                        >
                          {allEventsArr?.eventFilterList?.map((evt, i) => {
                            return (
                              <Option value={evt.id} key={`${evt.id}-${i}`}>
                                {capitalizeFirstLetter(evt?.title)}
                              </Option>
                            )
                          })}
                        </Select>
                        {childeventfilter === true && (
                          <div className="search-view-child">
                            <Select
                              placeholder="Search by Child Under Master"
                              onChange={(data) =>
                                setResData({
                                  ...resData,
                                  child_event_id: data,
                                })
                              }
                            >
                              {allEventsArr.eventsList?.map((evt, i) => {
                                let evtid = evt?.eventId
                                if (evtid === masterId) {
                                  return (
                                    <Option
                                      value={evt.id}
                                      key={`${evt.id}-${i}`}
                                    >
                                      {capitalizeFirstLetter(evt?.title)}
                                    </Option>
                                  )
                                }
                              })}
                            </Select>
                          </div>
                        )}
                      </div>
                    </div>
                    <Form.Item className="filter-btns">
                      <div className="filter-serach-btn">
                        {isEventFilterUsed() && (
                          <Button
                            id="resetbtn"
                            type="primary"
                            htmlType="submit"
                            className="btn forth-btn"
                            onClick={() => ResetFilter()}
                          >
                            <ClearOutlined />
                          </Button>
                        )}
                      </div>
                    </Form.Item>
                  </Form>
                  {roles.length > 0 && roles.includes('1') && (
                    <div className="add-evt-btn">
                      <button
                        className="btn primary-btn add-event-btn"
                        onClick={() => navigate('/add-event')}
                      >
                        Add New Event
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="event-list">
            {sortedData?.length > 0 ? (
              sortedData?.map((evt, i) => {
                return (
                  <div
                    key={`${evt.id}-${i}`}
                    className={`event-item ${(isAuthenticated &&
                        !ExpiredDate(evt?.end_date) &&
                        handleRoleForAdmin(evt?.roles)) ||
                        handleRoleForCheckin(evt?.roles) ||
                        handleRoleForonsite(evt?.roles)
                        ? 'event-last'
                        : ''
                      }`}
                  >
                    <div className="event-left">
                      <div className="event-title three-line-ellipsis">
                        {capitalizeFirstLetter(evt?.title)}
                        {ExpiredDate(evt?.end_date) ? (
                          <span className="expire-badge">Expired</span>
                        ) : (
                          ''
                        )}
                        {lable(
                          evt?.isPending,
                          evt?.isRequested,
                          evt?.isApproved
                        )?.tagname &&
                          !ExpiredDate(evt?.end_date) &&
                          isAuthenticated && (
                            <span
                              className={`expire-badge ${lable(
                                evt?.isPending,
                                evt?.isRequested,
                                evt?.isApproved
                              )?.tagname === 'Pending'
                                  ? 'orange'
                                  : lable(
                                    evt?.isPending,
                                    evt?.isRequested,
                                    evt?.isApproved
                                  )?.tagname === 'Approved'
                                    ? 'green'
                                    : 'expire-badge'
                                }`}
                            >
                              {
                                lable(
                                  evt?.isPending,
                                  evt?.isRequested,
                                  evt?.isApproved
                                )?.tagname
                              }
                            </span>
                          )}
                        <p className="event-desc">
                          Description: {capitalizeFirstLetter(evt?.description)}
                        </p>
                        <p className="event-desc">
                          Location: {capitalizeFirstLetter(evt?.location)}
                        </p>
                      </div>
                      <div className="event-date">
                        Date:{' '}
                        {moment(evt?.start_date)
                          .utc()
                          .format('DD/MM/YYYY, hh:mm A') +
                          '  -  ' +
                          moment(evt?.end_date)
                            .utc()
                            .format('DD/MM/YYYY, hh:mm A')}
                      </div>
                    </div>
                    <div className="event-action">
                      {isAuthenticated &&
                        !ExpiredDate(evt?.end_date) &&
                        lable(evt?.isPending, evt?.isRequested, evt?.isApproved)
                          .btnname ? (
                        <Tooltip
                          placement="top"
                          title={capitalizeFirstLetter(
                            lable(
                              evt?.isPending,
                              evt?.isRequested,
                              evt?.isApproved
                            ).btnname
                          )}
                        >
                          <button
                            className={
                              lable(
                                evt?.isPending,
                                evt?.isRequested,
                                evt?.isApproved
                              ).btnname === 'Apply'
                                ? 'ant-btn ant-btn-primary btn primary-btn'
                                : 'ant-btn ant-btn-primary btn danger-btn'
                            }
                            type="button"
                            value={
                              lable(
                                evt?.isPending,
                                evt?.isRequested,
                                evt?.isApproved
                              ).btnname === 'Apply'
                                ? 'isAppied'
                                : 'pending'
                            }
                            onClick={(e) =>
                              Eventhandler(e, evt.id, '', true, 'evtbtn')
                            }
                          >
                            {
                              lable(
                                evt?.isPending,
                                evt?.isRequested,
                                evt?.isApproved,
                                isAuthenticated,
                                !ExpiredDate(evt?.end_date)
                              ).btnname
                            }
                          </button>
                        </Tooltip>
                      ) : !isAuthenticated && !ExpiredDate(evt?.end_date) ? (
                        <Tooltip placement="top" title="Register">
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              navigate('/signup', {
                                state: { eventId: evt?.id },
                              })
                            }}
                          >
                            Register
                          </button>
                        </Tooltip>
                      ) : (
                        ''
                      )}
                      {isAuthenticated && (
                        <Tooltip placement="top" title="View Event">
                          <Button
                            className="btn primary-btn"
                            type="primary"
                            onClick={(e) => {
                              e.preventDefault()
                              setIsModalOpen(true)
                              setModalData(evt?.id)
                            }}
                          >
                            <EyeOutlined />
                          </Button>
                        </Tooltip>
                      )}
                      <div className="action-more-buttons">
                        {isAuthenticated &&
                          !ExpiredDate(evt?.end_date) &&
                          handleRoleForAdmin(evt?.roles) && (
                            <>
                              <Tooltip placement="top" title="Add Guest">
                                <Button
                                  className="btn primary-btn"
                                  type="primary"
                                  onClick={() => {
                                    setOpenModal(true)
                                    setAddGuestModalData(
                                      evt?.eventId ? evt?.eventId : evt?.id
                                    )
                                    setFlag(evt?.eventId ? 'child' : 'parent')
                                  }}
                                >
                                  Add Guest
                                </Button>
                              </Tooltip>
                              <Tooltip
                                placement="top"
                                title="Add QuickAddOn List"
                              >
                                <Button
                                  className="btn primary-btn"
                                  type="primary"
                                  onClick={() => {
                                    setQuickAddonId(evt?.id)
                                    setPGuestModal(true)
                                  }}
                                >
                                  Add QuickAddOn List
                                </Button>
                              </Tooltip>
                            </>
                          )}
                      </div>
                      {(isAuthenticated &&
                        !ExpiredDate(evt?.end_date) &&
                        handleRoleForAdmin(evt?.roles)) ||
                        roles.includes('1') ? (
                        <>
                          <Tooltip placement="top" title="Edit Event">
                            <Button
                              className="btn primary-btn"
                              type="primary"
                              onClick={() => editEventHandler(evt)}
                            >
                              <EditOutlined />
                            </Button>
                          </Tooltip>
                        </>
                      ) : (
                        ''
                      )}
                      {isAuthenticated && roles.includes('1') && (
                        <>
                          <Tooltip placement="top" title="Delete Event">
                            <Button
                              className="btn danger-btn"
                              type="primary"
                              onClick={() => handleDelete(evt)}
                            >
                              <DeleteOutlined />
                            </Button>
                          </Tooltip>
                        </>
                      )}
                      <div className="action-more-buttons">
                        {isAuthenticated &&
                          handleRoleForCheckin(evt?.roles) && (
                            <Tooltip placement="top" title="Switch to CheckIn">
                              <Button
                                className="btn primary-btn"
                                type="primary"
                                onClick={(e) => {
                                  switchRoleHandler(e, evt, 'checkIn')
                                }}
                              >
                                Switch to CheckIn
                              </Button>
                            </Tooltip>
                          )}
                        {isAuthenticated && handleRoleForonsite(evt?.roles) && (
                          <Tooltip placement="top" title="Switch to Onsite">
                            <Button
                              className="btn primary-btn"
                              type="primary"
                              onClick={(e) => {
                                switchRoleHandler(e, evt, 'Onsite')
                              }}
                            >
                              Switch to Onsite
                            </Button>
                          </Tooltip>
                        )}
                      </div>

                      {(isAuthenticated &&
                        !ExpiredDate(evt?.end_date) &&
                        handleRoleForAdmin(evt?.roles)) ||
                        handleRoleForCheckin(evt?.roles) ||
                        handleRoleForonsite(evt?.roles) ? (
                        <>
                          <button
                            className="more-actions ant-btn ant-btn-primary btn primary-btn"
                            type="primary"
                            onClick={() => setToggle(!toggle)}
                          >
                            More
                          </button>
                          {toggle && (
                            <>
                              <br />
                              <div className="more-action-btn">
                                {isAuthenticated &&
                                  !ExpiredDate(evt?.end_date) &&
                                  handleRoleForAdmin(evt?.roles) && (
                                    <>
                                      <Tooltip
                                        placement="top"
                                        title="Add Guest"
                                      >
                                        <Button
                                          className="btn primary-btn"
                                          type="primary"
                                          onClick={() => {
                                            setOpenModal(true)
                                            setAddGuestModalData(
                                              evt?.eventId
                                                ? evt?.eventId
                                                : evt?.id
                                            )
                                            setFlag(
                                              evt?.eventId ? 'child' : 'parent'
                                            )
                                          }}
                                        >
                                          Add Guest
                                        </Button>
                                      </Tooltip>
                                      <Tooltip
                                        placement="top"
                                        title="Add QuickAddOn List"
                                      >
                                        <Button
                                          className="btn primary-btn"
                                          type="primary"
                                          onClick={() => {
                                            setQuickAddonId(evt?.id)
                                            setPGuestModal(true)
                                          }}
                                        >
                                          Add QuickAddOn List
                                        </Button>
                                      </Tooltip>
                                    </>
                                  )}
                                {isAuthenticated &&
                                  handleRoleForCheckin(evt?.roles) && (
                                    <Tooltip
                                      placement="top"
                                      title="Switch to CheckIn"
                                    >
                                      <Button
                                        className="btn primary-btn"
                                        type="primary"
                                        onClick={(e) => {
                                          switchRoleHandler(e, evt, 'checkIn')
                                        }}
                                      >
                                        Switch to CheckIn
                                      </Button>
                                    </Tooltip>
                                  )}
                                {isAuthenticated &&
                                  handleRoleForonsite(evt?.roles) && (
                                    <Tooltip
                                      placement="top"
                                      title="Switch to Onsite"
                                    >
                                      <Button
                                        className="btn primary-btn"
                                        type="primary"
                                        onClick={(e) => {
                                          switchRoleHandler(e, evt, 'Onsite')
                                        }}
                                      >
                                        Switch to Onsite
                                      </Button>
                                    </Tooltip>
                                  )}
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                )
              })
            ) : (
              <div className="filter-top-bar main-page">
                <div className="NoData">
                  <p className="top-heading">No Data Found</p>
                </div>
              </div>
            )}
          </div>
          <div className="pagination-table">
            <Pagination
              onChange={(pagination) =>
                setEventPagination({ ...eventPagination, skip: pagination - 1 })
              }
              total={paginationTotal()}
              defaultCurrent={eventPagination.skip + 1}
            />
          </div>
          {pguestmodal && (
            <ModalQuickaddonView
              openmodal={pguestmodal}
              setPGuestModal={setPGuestModal}
              Quickaddonid={Quickaddonid}
            ></ModalQuickaddonView>
          )}
          {openmodal && (
            <ModalEvents
              openmodal={openmodal}
              setOpenModal={setOpenModal}
              addGuestmodalData={addGuestmodalData}
              flag={flag}
            ></ModalEvents>
          )}
          {ismodalopen && (
            <ModalViewEvents
              ismodalopen={ismodalopen}
              setIsModalOpen={setIsModalOpen}
              modalData={modalData}
            ></ModalViewEvents>
          )}
        </>
      )}
    </>
  )
}

export default AttendeeScreen
