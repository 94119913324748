import React, { useState } from 'react'
import { Button, Form, Input, message } from 'antd'
import './RestPassword.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { ResetPasswordAPI } from '../../redux/actions/user/user.actions'
import { useDispatch } from 'react-redux'
import { validatePassword } from '../../shared/config/globalFunctions'

export default function RestPassword() {
  let { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const onFinish = (data) => {
    setIsLoading(true)
    data['userId'] = id
    dispatch(ResetPasswordAPI(data)).then((res) => {
      if (res.status === 200) {
        message.success(res.data.message)
        setIsLoading(false)
        navigate('/')
      } else {
        setIsLoading(false)
      }
    })
  }
  return (
    <div className="form-cover ">
      <div className="userformmbox">
        <div className="form-feildbox">
          <h1> Reset Password </h1>
          <Form
            name="sign-in"
            className="sign-in-form-wrapper"
            onFinish={(data) => onFinish(data)}
          >
            <div className="form-feildbox-row">
              <Form.Item
                name={['password']}
                rules={[
                  {
                    required: true,
                    message: 'Please new password',
                  },
                  () => ({
                    validator(_, value) {
                      if (value) {
                        return Promise.reject(validatePassword(value))
                      }
                      return Promise.resolve()
                    },
                  }),
                ]}
              >
                <Input.Password
                  className="form-input"
                  type="password"
                  style={{ marginTop: '9px' }}
                  placeholder="New password"
                />
              </Form.Item>
            </div>

            <div className="form-feildbox-row">
              <Form.Item
                name={['confirmPassword']}
                rules={[
                  {
                    required: true,
                    message: 'Please confirm password',
                  },
                  () => ({
                    validator(_, value) {
                      if (value) {
                        return Promise.reject(validatePassword(value))
                      }
                      return Promise.resolve()
                    },
                  }),
                ]}
              >
                <Input.Password
                  className="form-input"
                  type="password"
                  style={{ marginTop: '9px' }}
                  placeholder="Confirm password"
                />
              </Form.Item>
            </div>
            <div className="form-feildbox-row">
              <Form.Item>
                {isLoading ? (
                  <Button type="primary" htmlType="submit" loading>
                    Reset Password
                  </Button>
                ) : (
                  <Button type="primary" htmlType="submit">
                    Reset Password
                  </Button>
                )}
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}
