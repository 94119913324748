import { combineReducers } from 'redux'
import { LOG_OUT } from '../types/user'
import auth from './auth/auth.reducer'
import user from './user/user.reducer'
import event from './event/event.reducers'

const appReducer = combineReducers({
  auth,
  user,
  event,
})

const rootReducer = (state, action) => {
  if (action.type === LOG_OUT) {
    state.auth = {}
    state.user = {}
  }

  return appReducer(state, action)
}

export default rootReducer
