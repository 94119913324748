import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import { userAuthoritiesRole } from '../redux/selectors/user/user.selector'
import useAuthentication from './UseAuthentication'

function ProtectedRoute({ component }) {
  const userAuthorities = useSelector(userAuthoritiesRole)
  let userRoles = Array.isArray(userAuthorities)
    ? userAuthorities?.map(({ roleId }) => roleId)
    : []
  let isValid = useAuthentication()
  const { pathname } = useLocation()

  const checkRoleAccess = () => {
    let isAccess = false
    const validRoles = ['1', '2', '3']
    userRoles?.forEach((role) => {
      if (isAccess === false) isAccess = validRoles.includes(role)
    })
    return isAccess
  }

  if (pathname === '/add-event') {
    isValid = checkRoleAccess()
  }

  return isValid ? <>{component}</> : <Navigate to="/login" />
}

export default ProtectedRoute
