import React, { useState, useRef, useEffect } from 'react'
import { Button, Dropdown, Menu, Space, Modal } from 'antd'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { userSignOut } from '../../redux/actions/user/user.actions'
import { LogoutDiscourse } from '../../redux/actions/discourse/discourse.actions'
import { userInformation } from '../../redux/selectors/user/user.selector'
import {
  capitalizeFirstLetter,
  ImageData,
} from '../../shared/config/globalFunctions'
import {
  MenuOutlined,
  DownOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  LogoutOutlined,
  UserOutlined,
} from '@ant-design/icons'
import userimg from '../../assets/images/default-user-img.png'
import logo from '../../assets/images/digitaldavos.svg'
import './NavBar.scss'

const NavBar = ({ isAuthenticated, roles = [] }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const [visible, setVisible] = useState(false)
  const userInfo = useSelector(userInformation)
  const navRef = useRef(null)

  const profileImageHandler = () => {
    return ImageData(userInfo?.image, userimg)
  }

  const isActiveLink = (link) => (link === location.pathname ? 'active' : '')
  const logoutHandler = () => {
    Modal.confirm({
      title: 'Are you sure you want to logout?',
      icon: <ExclamationCircleOutlined />,
      content: 'You can not revert back!!',
      okText: 'Logout',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        dispatch(userSignOut())
        dispatch(LogoutDiscourse(userInfo?.discourse_id))
      },
      onCancel() { },
    })
  }

  const onMenuClick = (event) => {
    const { key } = event
    switch (key) {
      case 'Profile':
        navigate('/profile')
        break
      case 'Logout':
        logoutHandler()
        break
      default:
        break
    }
  }

  const menuItems = [
    {
      key: 'Profile',
      icon: <UserOutlined />,
      label: 'Profile',
    },
    {
      key: 'Logout',
      icon: <LogoutOutlined />,
      label: 'Logout',
    },
  ]

  const dropprofile = (
    <Menu selectedKeys={[]} onClick={onMenuClick} items={menuItems} />
  )

  const networkTab = (url) => {
    window.location.href = url
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY) {
        setVisible(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  })

  return (
    <nav className="navbar">
      <div className="header-wrapper">
        {isAuthenticated ? (
          <Button
            className="menu"
            type="primary"
            icon={<MenuOutlined />}
            onClick={() => setVisible(true)}
          />
        ) : (
          ''
        )}
        <div className="logo">
          <h1>
            <a href="/">
              <img className="logo" src={logo} alt="logo" />
            </a>
          </h1>
        </div>
        <div className="right-header">
          {isAuthenticated ? (
            <ul className={visible ? 'nav-header open' : 'nav-header'}>
              <button className="close-btn" onClick={() => setVisible(false)}>
                <CloseOutlined />
              </button>
              <li>
                <Link className={`${isActiveLink('/')} navbarlink`} to="/">
                  Home
                </Link>
              </li>
              <li>
                <Link
                  className={`${isActiveLink('/myQR')} navbarlink`}
                  to="/myQR"
                >
                  My QR
                </Link>
              </li>
              <li>
                <Link
                  className={`${isActiveLink('/my-events')} navbarlink`}
                  to="/my-events"
                >
                  My Events
                </Link>
              </li>
              <li>
                <Link
                  className={`${isActiveLink('/event-calendar')} navbarlink`}
                  to="/event-calendar"
                >
                  Calendar
                </Link>
              </li>
              <li>
                <Link
                  className={`navbarlink`}
                  onClick={() =>
                    networkTab('https://discourse.digitaldavos.app')
                  }
                >
                  Network
                </Link>
              </li>

              {Array.isArray(roles) && roles?.includes('1') ? (
                <>
                  <ul className="master-navbar">
                    <li>
                      <Link
                        className={`${isActiveLink('/add-event')} navbarlink`}
                        to="/add-event"
                      >
                        Add Event
                      </Link>
                    </li>
                  </ul>
                </>
              ) : (
                ''
              )}
            </ul>
          ) : (
            ''
          )}

          {isAuthenticated ? (
            <Dropdown
              overlay={dropprofile}
              overlayClassName={'profile-dropdown'}
              placement="bottom"
              trigger={['click']}
            >
              <Link href="">
                <div className="profile-img">
                  <img
                    src={profileImageHandler()}
                    className="preview-image"
                    alt="avatar"
                    referrerPolicy="no-referrer"
                  />
                </div>
                <Space>
                  <span className="profile-name">
                    {userInfo?.first_name
                      ? capitalizeFirstLetter(userInfo?.first_name)
                      : 'user'}
                  </span>
                  <DownOutlined />
                </Space>
              </Link>
            </Dropdown>
          ) : (
            <div>
              <button
                className="btn primary-btn"
                onClick={() => navigate('/login')}
              >
                Login
              </button>
            </div>
          )}
        </div>
      </div>
    </nav>
  )
}
export default NavBar
