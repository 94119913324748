import {
  USER_DETAILS
} from '../../types/user'

const initialState = {
  userDetails: {}
}

const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case USER_DETAILS:
      return {
        ...state,
        userDetails: payload,
      }

    default:
      return state
  }
}

export default userReducer
