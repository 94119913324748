import { Spin } from 'antd'
import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import defaultEventImage from '../../assets/images/event-1.png'
import {
  capitalizeFirstLetter,
  getImagePath,
} from '../../shared/config/globalFunctions'
import { GetEventDetailWithoutAuth } from '../../redux/actions/event/event.actions'
import './style.scss'
import moment from 'moment'
import { Helmet } from 'react-helmet';

const EventDetails = ({ isAuthenticated }) => {
  const params = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [eventDetails, setEventDetails] = useState()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const fetchEventDetails = useCallback((id) => {
    setIsLoading(true)
    dispatch(GetEventDetailWithoutAuth(id)).then((res) => {
      if (res.code === 0) {
        setEventDetails(res.data)
        setIsLoading(false)
      }
    })
  }, [dispatch])

  useEffect(() => {
    fetchEventDetails(params.id)
  }, [])

  const twitterCardValue = 'summary_large_image';
  const dynamicTitle = eventDetails?.title;
  const dynamicMetaDescription = eventDetails?.description.slice(0, 155);
  const ogTypeValue = 'website';
  const ogImageValue = getImagePath(eventDetails?.event_banner);
  const ogUrlValue = `https://digitaldavos.app/event-details/${params.id}`;
  const dynamicTwitterDomain = 'digitaldavos.app';
  return (
    <>
      <Helmet>
        <title>{dynamicTitle}</title>
        <meta name="title" content={dynamicTitle} key="metaTitle" />
        <meta name="description" content={dynamicMetaDescription} key="metaDescription" />
        <meta property="og:type" content={ogTypeValue} key={`ogType-${ogTypeValue}`} />
        <meta property="og:title" content={dynamicTitle} key={`ogTitle-${dynamicTitle}`} />
        <meta property="og:description" content={dynamicMetaDescription} key={`ogDescription-${dynamicMetaDescription}`} />
        <meta property="og:image" content={ogImageValue} key={`ogImage-${ogImageValue}`} />
        <meta property="og:url" content={ogUrlValue} key={`ogUrl-${ogUrlValue}`} />
        <meta name="twitter:card" content={twitterCardValue} key={`twitterCard-${twitterCardValue}`} />
        <meta property="twitter:domain" content={dynamicTwitterDomain} key={`twitterDomain-${dynamicTwitterDomain}`} />
        <meta property="twitter:url" content={ogUrlValue} key={`twitterURL-${ogUrlValue}`} />
        <meta name="twitter:title" content={dynamicTitle} key={`twitterTitle-${dynamicTitle}`} />
        <meta name="twitter:description" content={dynamicMetaDescription} key={`twitterDescription-${dynamicMetaDescription}`} />
        <meta name="twitter:image" content={ogImageValue} key={`twitterImage-${ogImageValue}`} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="628" />
      </Helmet>
      {isLoading ? (
        <div className="spinner-myevents" style={{ height: '100vh' }}>
          <Spin tip="Loading..."></Spin>
        </div>
      ) : (
        <>
          <div className="event-details">
            <img
              className="event-banner-img"
              src={
                eventDetails?.event_banner
                  ? getImagePath(eventDetails?.event_banner)
                  : defaultEventImage
              }
              alt="Event"
            />
            <div className="event-info">
              <h4>{capitalizeFirstLetter(eventDetails?.title)}</h4>
              <h2>{capitalizeFirstLetter(eventDetails?.location)}</h2>
              <p>
                {moment(eventDetails?.start_date)
                  .utc()
                  .format('DD/MM/YYYY, hh:mm A')}
                {' '}-{' '}
                {moment(eventDetails?.end_date)
                  .utc()
                  .format('DD/MM/YYYY, hh:mm A')}
              </p>
            </div>
            <div className='event-get-started'>
              <button
                className="btn btn-primary"
                onClick={() => {
                  navigate('/login', {
                    state: { eventId: params?.id },
                  })
                }}
              >
                Let's get started
              </button>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default EventDetails
