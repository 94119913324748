import { handleError } from '../../../shared/config/globalFunctions'
import axios from 'axios'

export const CreateDiscourseUser = (data) => async () => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL_DISCOURSE}/create-user`,
      data
    )
    let returnData = {
      response,
    }
    return returnData
  } catch (error) {
    return handleError(error)
  }
}

export const LoginSSo = (data, sso, sig) => async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL_DISCOURSE}/discourseLogin/?s1=${sso}&s2=${sig}`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "safari-web-extension",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
        "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, PATCH, DELETE, FETCH",
      },
      body: JSON.stringify(data),
    }).then((response) => {
      return response.json()
    }).then((data) => {
      return data
    }).catch((error) => {
      return error
    })
    return response;
  } catch (error) {
    return handleError(error)
  }
}

export const LogoutDiscourse = (user_id) => async () => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL_DISCOURSE}/discourseUser/logout`,
      {
        user_id: user_id,
      }
    )
    return response
  } catch (error) {
    return handleError(error)
  }
}

export const CreateDPrivateMessage = (data) => async () => {
  try {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }
    const response = fetch(
      `${process.env.REACT_APP_API_URL_DISCOURSE}/create-private-message`,
      requestOptions
    )
    if (response.status === 200 && !response.data.hasError) {
    }
    let returnData = {
      data: response.data,
      status: response.status,
    }
    return returnData
  } catch (error) {
    return handleError(error)
  }
}
