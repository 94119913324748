import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Form, Input, message, Tag, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import {
  validateEmail,
} from '../../pages/AddEvent/eventFormRules'
import Axios from '../../shared/config/axiosConfig'
import { handleRoleForChildAdmin } from '../../shared/config/globalFunctions'

const Index = ({
  defaultValue = [],
  btnTitle,
  label,
  onChange,
  flagError,
  eventManagers = [],
  beforeAppend = () => {
    return { status: true }
  },
  roles = [],
  flag
}) => {
  const [tags, setTags] = useState(defaultValue)
  const [visible, setVisible] = useState(false)
  const [input, setInput] = useState('')
  const [showError, setShowError] = useState(false)

  const onPressEnter = (e) => {
    if (e?.target?.value) {
      let isValid = true
      let inputValue = e.target.value ?? ''
      inputValue = inputValue.replaceAll(' ', '')
      let resp = validateEmail(inputValue)
      if (!resp) {
        isValid = false
        message.error('Invalid Email entered.')
        e.preventDefault()
      } else {
        // before upload
        let { status, message: errMess } = beforeAppend(inputValue)
        if (!status) {
          message.error(errMess)
          isValid = false
        }

        // check for duplicate
        if (tags.includes(inputValue)) {
          isValid = false
          message.error(`${inputValue} already exists.`)
        }

        if (isValid) setTags([...tags, inputValue])
      }
    }
    setInput('')
    setShowError(true)
    setVisible(!visible)
  }

  useEffect(() => {
    if (flagError) setShowError(true)
  }, [flagError])

  useEffect(() => {
    onChange(tags)
  }, [tags])

  const removeManager = async (payload = {}) => {
    delete payload.email_id
    const response = await Axios.post(`removeManager`, payload)
    if (response.status === 200) return true
    else {
      message.error('Error in deleting manager.')
      return false
    }
  }

  const handleDelete = (e, tg) => {
    e.preventDefault()
    Modal.confirm({
      title: 'Are you sure you want to remove this manager?',
      icon: <ExclamationCircleOutlined />,
      content: 'You can not revert back!!',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      async onOk() {
        let payload = eventManagers?.find((manager) => manager.email_id === tg)
        if (payload) {
          let resp = await removeManager(payload)
          if (resp) setTags(tags.filter((tag) => tag !== tg))
        } else setTags(tags.filter((tag) => tag !== tg))
      },
      onCancel() {
        // console.log('')
      },
    })
  }

  return (
    <>
      {/* ant-input-status-error */}
      <Form.Item
        layout="vertical"
        className="sort-select custom-tag"
        // label={label}
        name="Title"
      >
        <div className="ant-col ant-form-item-label">
          <label className="custom-tag-label" title={label}>
            {label}
            {!flag && <span className="custom-tag-required">*</span>}
          </label>
        </div>
        {!visible && (
          <Tag onClick={() => setVisible(true)} className="site-tag-plus">
            <PlusOutlined /> {btnTitle}
          </Tag>
        )}
        {visible && (
          <Input
            autoFocus
            onPressEnter={onPressEnter}
            onChange={(e) => setInput(e.target.value)}
            value={input}
            disabled={handleRoleForChildAdmin(roles) ? true : false}
          />
        )}
        {showError && !flag && tags?.length === 0 && (
          <div className="ant-form-item-explain-error">{label} is required</div>
        )}
        {tags?.map((tg, index) => (
          <span key={index} style={{ display: 'inline-block' }}>
            <Tag key={tg} closable={handleRoleForChildAdmin(roles) ? false : true} onClose={(e) => handleDelete(e, tg)}>
              {tg}
            </Tag>
          </span>
        ))}
      </Form.Item>
    </>
  )
}

export default Index
