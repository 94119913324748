import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Space,
  Spin,
  Upload,
  Tooltip,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import Layout from '../../components/Layout'
import NavBar from '../../components/NavBar'
import CustomTag from '../../components/CustomTag'
import {
  onlyCharactersandNumbersAllowed,
  ruleCsv,
  ruleEndDate,
  ruleEventTitleRequire,
  ruleImage,
  ruleRequired,
  ruleStartDateRequire,
} from './eventFormRules'
import {
  dateFormatStr,
  disableDateRange,
  disablePastDate,
  getRolesInArr,
  getImagePath,
  handleRoleForChildAdmin,
  handleCompressedUpload,
  getAttendeesCSVPath,
} from '../../shared/config/globalFunctions'
import moment from 'moment'
import {
  DownloadOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
  RocketOutlined,
  CloudDownloadOutlined,
} from '@ant-design/icons'
import {
  AddChildEvent,
  AddEvent,
  bulkUploadAttendee,
  ReMoveChildEvent,
  UpdateChildEvent,
  UpdateEvent,
  ExportAttendeesCSV,
} from '../../redux/actions/event/event.actions'
import { userAuthoritiesRole } from '../../redux/selectors/user/user.selector'
import './style.scss'

export const checkInEmailValidation = (formdata, email) => {
  let allEmails = []
  const {
    master_manager = '',
    onsite_manager = '',
    child_manager = '',
  } = formdata
  allEmails = allEmails.concat(
    master_manager.split(','),
    onsite_manager.split(','),
    child_manager.split(',')
  )
  if (!allEmails.includes(email)) return { status: true }
  else
    return {
      status: false,
      message: `${email} cannot be used as checkin manager`,
    }
}

const Home = (props) => {
  const { isAuthenticated } = props
  const userAuthorities = useSelector(userAuthoritiesRole)
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [role, setRole] = useState([])
  const [attendeeFiles, setAttendeeFiles] = useState({
    parent: {},
    child: [],
  })
  const [isLoadingAttendees, setIsLoadingAttendees] = useState(false)
  const [loadingId, setLoadingId] = useState({})

  const { state = {} } = location
  let prevData = { ...state }

  const [eventManagers] = useState(prevData?.managers ?? [])
  delete prevData?.managers

  const [formdata, setformdata] = useState(
    Object.keys(prevData).length > 0 ? state : {}
  )

  const [childForm, setChildForm] = useState(formdata?.childEvents ?? [])

  const roles = state?.roles ?? []

  const eventroles = state?.eventroles ?? []

  const handleForm = (e, key) => {
    const { value, id } = e.target
    if (key !== undefined) {
      childEventForm(key, id, value)
    } else {
      setformdata({ ...formdata, [id]: value })
    }
  }

  const targetData = (id, value, key) => {
    handleForm({ target: { id, value } }, key)
  }

  const handleTags = (id, tags = [], key) => {
    if (tags?.length === 0) targetData(id, '', key)

    let value = ''
    tags?.forEach((tg) => {
      value += `${tg},`
    })
    value = value.slice(0, -1)
    if (value) targetData(id, value, key)
  }

  const handleChildEvent = async (event, key, mastermanager) => {
    event['master_manager'] = mastermanager
    const { data, status } = await dispatch(AddChildEvent(event))
    if (status === 200) {
      const childevent_id = data?.data?.id
      await cvsBlockUpload(childevent_id, 'child', key)
      setIsLoading(false)
    }
  }

  const updateChildEvent = async (childEvent, key) => {
    const { status } = await dispatch(
      UpdateChildEvent(childEvent.id, childEvent)
    )
    if (status === 200) {
      const childevent_id = childEvent.id
      await cvsBlockUpload(childevent_id, 'child', key)
    }
    setIsLoading(false)
  }

  const isCheckInEmail = (email) => {
    let emailArr = formdata.checkin_manager ?? []
    return {
      status: !emailArr.includes(email),
      message: `${email} is present in checking manager`,
    }
  }

  const cvsBlockUpload = async (event_id, flag = '', key) => {
    if (!event_id) return null
    let payload = {}
    if (flag === 'parent') {
      payload = { event_id, file: attendeeFiles[flag] }
    } else {
      payload = { event_id, file: attendeeFiles[flag][key] }
    }
    if (Object.keys(payload.file ?? {}).length === 0) return
    await dispatch(bulkUploadAttendee(payload))
    setIsLoading(false)
  }

  const updateEventHandler = async (data) => {
    setIsLoading(true)
    let formdata = { ...data }
    let id = formdata?.event_id
    let banner = formdata?.event_banner
    if (!banner) {
      delete formdata['event_banner']
    }
    delete formdata['event_id']
    delete formdata['childEvents']
    delete formdata['managers']

    let { responseData } = await dispatch(UpdateEvent(id, formdata))

    if (responseData.code === 0) {
      setIsLoading(false)
      cvsBlockUpload(id, 'parent')

      childForm?.map(async (childEvent, key) => {
        let childbanner = childEvent?.event_banner
        if (!childbanner) {
          delete childEvent['event_banner']
        }
        childEvent.location = formdata?.location
        if (childEvent.id) {
          await updateChildEvent(childEvent, key)
          setIsLoading(false)
        } else {
          childEvent.location = formdata.location
          childEvent.event_id = id
          await handleChildEvent(childEvent, key, formdata.master_manager)
          setIsLoading(false)
        }
      })
      setIsLoading(false)
      message.success(responseData.message)
    } else {
      setIsLoading(false)
    }
  }

  const validationHandler = () => {
    let result = true

    // Managers for parent events.
    const { master_manager } = formdata
    if (!master_manager) result = false

    // Managers for child event.
    childForm?.forEach((childEvent) => {
      const { child_manager } = childEvent
      if (!child_manager) result = false
    })
    return result
  }

  const callbackFun = (data, key) => {
    targetData('event_banner', data, key)
  }

  const onFinish = async () => {
    setIsLoading(true)

    // event field validations
    if (!validationHandler()) {
      setIsLoading(true)
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
      return null
    }
    setIsLoading(false)

    const isNewEvent = Object.keys(state ?? {}).length === 0
    if (isNewEvent) {
      // New Event
      dispatch(AddEvent(formdata)).then(async (resDetails) => {
        if (resDetails.status === 200) {
          // Bluk Upload for Parent Event.
          await cvsBlockUpload(resDetails.data.id, 'parent')
          childForm?.map(async (childEvent, key) => {
            childEvent.location = formdata.location
            childEvent.event_id = resDetails.data.id

            await handleChildEvent(childEvent, key, formdata.master_manager)
          })
          setIsLoading(false)
          navigate('/')
          message.success(resDetails.message)
        } else {
          setIsLoading(false)
        }
      })
    } else {
      await updateEventHandler(formdata)
      navigate('/')
    }
  }

  const childEventForm = (key, id, value) => {
    let childFormdata = [...childForm]
    childFormdata[key] = { ...(childFormdata[key] ?? {}) }
    childFormdata[key][id] = value
    setChildForm(childFormdata)
  }

  const handleAttendeeFile = (flag, file = {}, key) => {
    if (flag === 'parent')
      return setAttendeeFiles({ ...attendeeFiles, [flag]: file })
    else {
      let files = [...attendeeFiles.child]
      files[key] = file

      setAttendeeFiles({ ...attendeeFiles, [flag]: files })
    }
  }

  const defaultDatePickerDate = (date) => {
    let newFormat = moment(date.slice(0, -1)).format('YYYY-MM-DD HH:mm')
    return moment(newFormat)
  }

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
  }

  const exportAttendees = async (exportType, eventId, key) => {
    const id = key
    setLoadingId((ids) => ({
      ...ids,
      [id]: true,
    }))
    try {
      if (exportType === 'master') {
        setIsLoadingAttendees(true)
      }
      await dispatch(
        ExportAttendeesCSV({ eventId: eventId, exportType: exportType })
      ).then(async (res) => {
        if (res.status === 200) {
          setTimeout(() => {
            if (exportType === 'master') {
              setIsLoadingAttendees(false)
            }
            message.success(res?.data?.message)
            const URL = getAttendeesCSVPath(res?.data?.data.fileName)
            window.open(URL, '_self')
          }, 10000)
        } else {
          if (exportType === 'master') {
            setIsLoadingAttendees(false)
          }
        }
        await mockFetch()
      })
    } catch {
      // ignore
    } finally {
      setLoadingId((ids) => ({
        ...ids,
        [id]: false,
      }))
    }
  }

  const mockFetch = () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(), 8000)
    })

  useEffect(() => {
    if (Object.keys(state ?? {}).length > 0) setformdata(state ?? {})
  }, [state])

  useEffect(() => {
    if (userAuthorities.length > 0) {
      const role_arr = getRolesInArr(userAuthorities)
      setRole(role_arr)
    }
  }, [userAuthorities])

  return (
    <>
      {!formdata ? (
        <div className="spinner-myevents" style={{ height: '100vh' }}>
          <Spin tip="Loading..."></Spin>
        </div>
      ) : (
        <>
          <NavBar isAuthenticated={isAuthenticated} roles={role} />
          <Layout roles={state?.roles ?? []}>
            <div className="add-event-page main-page-content">
              <div className="add-event-box">
                <p className="page-title">{state ? 'Edit' : 'Add'} Event</p>

                {/* form starts */}
                <Form
                  name="event-form"
                  initialValues={{
                    ...formdata,
                    start_date: formdata.start_date
                      ? moment(formdata.start_date)
                      : undefined,
                    end_date: formdata?.end_date
                      ? moment(formdata?.end_date)
                      : undefined,
                  }}
                  onFinish={() => onFinish()}
                >
                  <div className="form-row">
                    <Form.Item
                      layout="vertical"
                      className="sort-select"
                      label="Event Title"
                      name={'title'}
                      rules={ruleEventTitleRequire('Title')}
                    >
                      <Input
                        id="title"
                        onKeyDown={onlyCharactersandNumbersAllowed}
                        onChange={handleForm}
                        maxLength="70"
                        minLength="3"
                        disabled={
                          roles.includes('1') && roles.includes('2')
                            ? false
                            : roles.includes('1') && roles.includes('3')
                              ? false
                              : roles.includes('3') || roles.includes('2')
                                ? true
                                : false
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      layout="vertical"
                      className="sort-select"
                      label="Location"
                      name={'location'}
                      rules={ruleRequired('Location')}
                    >
                      <Input
                        onChange={handleForm}
                        onKeyDown={onlyCharactersandNumbersAllowed}
                        maxLength="120"
                        id="location"
                        disabled={
                          roles.includes('1') && roles.includes('2')
                            ? false
                            : roles.includes('1') && roles.includes('3')
                              ? false
                              : roles.includes('3') || roles.includes('2')
                                ? true
                                : false
                        }
                      />
                    </Form.Item>
                    <div className="ant-form-item">
                      <div className="form-row sub-row">
                        <Form.Item
                          layout="vertical"
                          className="sort-select"
                          label="Start Date"
                          name="start_date"
                          rules={ruleStartDateRequire()}
                        >
                          <DatePicker
                            format="YYYY-MM-DD HH:mm"
                            showTime={{
                              format: 'HH:mm',
                              use12Hours: true,
                              hideDisabledOptions: true,
                            }}
                            initialValues={
                              formdata?.start_date &&
                              defaultDatePickerDate(formdata?.start_date)
                            }
                            disabledDate={disablePastDate}
                            onChange={(date) => {
                              targetData(
                                'start_date',
                                date.format(dateFormatStr)
                              )
                            }}
                            disabled={
                              handleRoleForChildAdmin(eventroles) ? true : false
                            }
                          />
                        </Form.Item>

                        <Form.Item
                          layout="vertical"
                          className="sort-select"
                          label="End Date"
                          name="end_date"
                          rules={
                            !formdata?.end_date
                              ? ruleRequired('End Date')
                              : ruleEndDate(formdata?.start_date, 'parent')
                          }
                        >
                          <DatePicker
                            format="YYYY-MM-DD HH:mm"
                            showTime={{
                              format: 'HH:mm',
                              use12Hours: true,
                              hideDisabledOptions: true,
                            }}
                            initialValues={
                              formdata?.end_date &&
                              defaultDatePickerDate(formdata?.end_date)
                            }
                            disabledDate={(current) => {
                              const date = moment(
                                formdata?.start_date,
                                'YYYY-MM-DD HH:mm'
                              ).add(1, 'minutes')
                              return current && current.valueOf() < date
                            }}
                            onChange={(date) =>
                              targetData('end_date', date.format(dateFormatStr))
                            }
                            disabled={
                              handleRoleForChildAdmin(eventroles) === true
                                ? true
                                : !formdata.start_date
                                  ? true
                                  : false
                            }
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <Form.Item
                      layout="vertical"
                      className="sort-select"
                      name={['event_banner']}
                      label="Event Banner"
                    >
                      <Upload
                        name="logo"
                        listType="picture"
                        customRequest={dummyRequest}
                        maxCount={1}
                        onChange={({ fileList }) =>
                          fileList[0] &&
                          handleCompressedUpload(
                            fileList[0].originFileObj,
                            callbackFun
                          )
                        }
                        beforeUpload={ruleImage}
                      >
                        <Button
                          icon={<UploadOutlined />}
                          disabled={
                            handleRoleForChildAdmin(eventroles) ? true : false
                          }
                        >
                          Click to upload
                        </Button>
                        {formdata?.event_banner &&
                          typeof formdata?.event_banner !== 'object' ? (
                          <span className="img-upload">
                            <img
                              src={getImagePath(formdata?.event_banner)}
                              alt="event banner"
                            />
                          </span>
                        ) : (
                          ''
                        )}
                      </Upload>
                    </Form.Item>
                    <CustomTag
                      eventManagers={eventManagers}
                      defaultValue={
                        formdata.master_manager
                          ? formdata.master_manager.split(',')
                          : []
                      }
                      onChange={(tags) => handleTags('master_manager', tags)}
                      beforeAppend={(email) => isCheckInEmail(email)}
                      label="Invite Master Manager"
                      btnTitle="New Email"
                      flagError={error}
                      roles={eventroles}
                    />
                    <CustomTag
                      eventManagers={eventManagers}
                      defaultValue={
                        formdata.onsite_manager
                          ? formdata.onsite_manager.split(',')
                          : []
                      }
                      onChange={(tags) => handleTags('onsite_manager', tags)}
                      label="Invite Onsite Manager"
                      beforeAppend={(email) => isCheckInEmail(email)}
                      btnTitle="New Email"
                      roles={eventroles}
                      flag="no"
                    />
                    <CustomTag
                      eventManagers={eventManagers}
                      defaultValue={
                        formdata.checkin_manager
                          ? formdata.checkin_manager.split(',')
                          : []
                      }
                      onChange={(tags) => handleTags('checkin_manager', tags)}
                      beforeAppend={(email) =>
                        checkInEmailValidation(formdata, email)
                      }
                      label="Invite CheckIn Manager"
                      btnTitle="New Email"
                      // flagError={error}
                      roles={eventroles}
                      flag="no"
                    />
                    <Form.Item
                      layout="vertical"
                      className="sort-select"
                      label="Event Description"
                      name={'description'}
                      rules={ruleRequired('Description')}
                    >
                      <TextArea
                        id="description"
                        onChange={handleForm}
                        disabled={
                          handleRoleForChildAdmin(eventroles) ? true : false
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      layout="vertical"
                      className="sort-select"
                      name={'file'}
                      label="Upload Attendes CSV"
                    >
                      <Upload
                        name="logo"
                        listType="picture"
                        customRequest={dummyRequest}
                        onRemove={() => handleAttendeeFile('parent')}
                        maxCount={1}
                        onChange={({ fileList }) =>
                          fileList[0] &&
                          handleAttendeeFile(
                            'parent',
                            fileList[0].originFileObj
                          )
                        }
                        beforeUpload={ruleCsv}
                      >
                        <Button
                          icon={<UploadOutlined />}
                          disabled={
                            handleRoleForChildAdmin(eventroles) ? true : false
                          }
                        >
                          Click to upload
                        </Button>
                      </Upload>
                    </Form.Item>
                  </div>

                  {/* Child Event start */}
                  <Form.List name="childEvents">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((feildData, key) => {
                          const { name } = feildData
                          return (
                            <Space
                              key={key}
                              style={{
                                display: 'flex',
                                marginBottom: 8,
                              }}
                              align="baseline"
                            >
                              <div className="multiple-form-title">
                                <p>Child Event</p>
                                {!roles.includes('3') ? (
                                  <MinusCircleOutlined
                                    onClick={() => {
                                      Modal.confirm({
                                        title:
                                          'Are you sure delete this event?',
                                        icon: <ExclamationCircleOutlined />,
                                        content: 'You can not revert back!!',
                                        okText: 'Delete',
                                        okType: 'danger',
                                        cancelText: 'Cancel',
                                        onOk() {
                                          let flag = Object.keys(childForm[key])
                                          console.log(flag, childForm[key].id)
                                          if (flag.length > 1 && childForm[key].id) {
                                            let childEventId = childForm[key].id
                                            dispatch(
                                              ReMoveChildEvent(childEventId)
                                            ).then(
                                              ({
                                                data: { message: apiMessage },
                                                status,
                                              }) => {
                                                if (status === 200) {
                                                  message.success(apiMessage)
                                                  remove(name)
                                                  setChildForm(
                                                    childForm.filter(
                                                      (_, index) =>
                                                        index !== key
                                                    )
                                                  )
                                                }
                                              }
                                            )
                                          } else {
                                            remove(name)
                                            setChildForm(
                                              childForm.filter(
                                                (_, index) => index !== key
                                              )
                                            )
                                          }
                                        },
                                        onCancel() { },
                                      })
                                    }}
                                  />
                                ) : (
                                  ''
                                )}
                              </div>
                              <div className="form-row">
                                <Form.Item
                                  layout="vertical"
                                  className="sort-select"
                                  label="Event Title"
                                  rules={ruleRequired('Title')}
                                  name={[name, 'title']}
                                >
                                  <Input
                                    value={childForm[key]?.title}
                                    onChange={(e) =>
                                      childEventForm(
                                        key,
                                        'title',
                                        e.target.value
                                      )
                                    }
                                    maxLength="70"
                                  />
                                </Form.Item>

                                <div className="ant-form-item">
                                  <div className="form-row sub-row">
                                    <Form.Item
                                      layout="vertical"
                                      className="sort-select"
                                      label="Start Date"
                                      rules={ruleRequired('Start Date')}
                                      name={[name, 'child_start_date']}
                                      initialValue={
                                        childForm[key]?.start_date &&
                                        defaultDatePickerDate(
                                          childForm[key]?.start_date
                                        )
                                      }
                                    >
                                      <DatePicker
                                        format="YYYY-MM-DD HH:mm"
                                        showTime={{
                                          format: 'HH:mm',
                                          use12Hours: true,
                                          hideDisabledOptions: true,
                                        }}
                                        initialValues={
                                          childForm[key]?.start_date &&
                                          defaultDatePickerDate(
                                            childForm[key]?.start_date
                                          )
                                        }
                                        disabledDate={(current) =>
                                          disableDateRange(current, formdata)
                                        }
                                        onChange={(date) =>
                                          targetData(
                                            'start_date',
                                            date.format(dateFormatStr),
                                            key
                                          )
                                        }
                                        disabled={
                                          roles.includes('3') ? true : false
                                        }
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      layout="vertical"
                                      className="sort-select"
                                      label="End Date"
                                      initialValue={
                                        childForm[key]?.end_date &&
                                        defaultDatePickerDate(
                                          childForm[key]?.end_date
                                        )
                                      }
                                      name={[name, 'child_end_date']}
                                      rules={
                                        !childForm[key]?.end_date
                                          ? ruleRequired('End Date')
                                          : ruleEndDate(formdata?.end_date)
                                      }
                                    >
                                      <DatePicker
                                        format="YYYY-MM-DD HH:mm"
                                        disabledDate={(current) =>
                                          disableDateRange(current, formdata)
                                        }
                                        showTime={{
                                          format: 'HH:mm',
                                          use12Hours: true,
                                          hideDisabledOptions: true,
                                        }}
                                        initialValues={
                                          childForm[key]?.end_date &&
                                          defaultDatePickerDate(
                                            childForm[key]?.end_date
                                          )
                                        }
                                        onChange={(date) =>
                                          targetData(
                                            'end_date',
                                            date.format(dateFormatStr),
                                            key
                                          )
                                        }
                                        disabled={
                                          roles.includes('3') ? true : false
                                        }
                                      />
                                    </Form.Item>
                                  </div>
                                </div>
                                <Form.Item
                                  layout="vertical"
                                  className="sort-select"
                                  name={'event_banner'}
                                  label="Event Banner"
                                >
                                  <Upload
                                    name="logo"
                                    listType="picture"
                                    maxCount={1}
                                    customRequest={dummyRequest}
                                    onChange={({ fileList }) =>
                                      fileList[0] &&
                                      handleCompressedUpload(
                                        fileList[0].originFileObj,
                                        callbackFun,
                                        key
                                      )
                                    }
                                    beforeUpload={ruleImage}
                                  >
                                    <Button icon={<UploadOutlined />}>
                                      Click to upload
                                    </Button>
                                    {childForm[key]?.event_banner &&
                                      typeof childForm[key]?.event_banner !==
                                      'object' ? (
                                      <span className="img-upload">
                                        <img
                                          src={getImagePath(
                                            childForm[key]?.event_banner
                                          )}
                                          alt="event banner"
                                        />
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                  </Upload>
                                </Form.Item>
                                <CustomTag
                                  eventManagers={eventManagers}
                                  onChange={(tags) => {
                                    handleTags('onsite_manager', tags, key)
                                  }}
                                  defaultValue={
                                    childForm[key]?.onsite_manager
                                      ? childForm[key]?.onsite_manager.split(
                                        ','
                                      )
                                      : []
                                  }
                                  beforeAppend={(email) =>
                                    isCheckInEmail(email)
                                  }
                                  label="Invite Onsite Manager"
                                  btnTitle="New Email"
                                  flagError={error}
                                  flag="no"
                                />
                                <CustomTag
                                  eventManagers={eventManagers}
                                  onChange={(tags) =>
                                    handleTags('checkin_manager', tags, key)
                                  }
                                  beforeAppend={(email) =>
                                    checkInEmailValidation(formdata, email)
                                  }
                                  defaultValue={
                                    childForm[key]?.checkin_manager
                                      ? childForm[key]?.checkin_manager.split(
                                        ','
                                      )
                                      : []
                                  }
                                  label="Invite CheckIn Manager"
                                  btnTitle="New Email"
                                  flagError={error}
                                  flag="no"
                                />
                                <CustomTag
                                  eventManagers={eventManagers}
                                  onChange={(tags) =>
                                    handleTags('child_manager', tags, key)
                                  }
                                  defaultValue={
                                    childForm[key]?.child_manager
                                      ? childForm[key]?.child_manager.split(',')
                                      : []
                                  }
                                  label="Invite Child Manager"
                                  beforeAppend={(email) =>
                                    isCheckInEmail(email)
                                  }
                                  btnTitle="New Email"
                                  flagError={error}
                                />
                                <Form.Item
                                  layout="vertical"
                                  className="sort-select"
                                  label="Event Description"
                                  rules={ruleRequired('Description')}
                                  name={[name, 'description']}
                                >
                                  <TextArea
                                    id="description"
                                    onChange={(e) => handleForm(e, key)}
                                    disabled={
                                      roles.includes('3') ? true : false
                                    }
                                    name={`description${key}`}
                                    value={childForm[key]?.description}
                                  />
                                </Form.Item>
                                <Form.Item
                                  layout="vertical"
                                  className="sort-select"
                                  name={'file'}
                                  label="Upload Attendes CSV"
                                >
                                  <Upload
                                    name="logo"
                                    listType="picture"
                                    maxCount={1}
                                    onRemove={() =>
                                      handleAttendeeFile(
                                        'child',
                                        undefined,
                                        key
                                      )
                                    }
                                    beforeUpload={ruleCsv}
                                    onChange={({ fileList }) =>
                                      fileList[0] &&
                                      handleAttendeeFile(
                                        'child',
                                        fileList[0].originFileObj,
                                        key
                                      )
                                    }
                                  >
                                    <Button icon={<UploadOutlined />}>
                                      Click to upload
                                    </Button>
                                  </Upload>
                                </Form.Item>
                                <Form.Item></Form.Item>
                                {childForm[key]?.id && (
                                  <Form.Item className="child-event-export-button">
                                    <Tooltip title="Export & Download Attendees">
                                      <Button
                                        className="export-attendees"
                                        loading={loadingId[key] ? true : false}
                                        icon={<CloudDownloadOutlined />}
                                        onClick={() =>
                                          exportAttendees(
                                            'child',
                                            childForm[key]?.id,
                                            key
                                          )
                                        }
                                      >
                                        {loadingId[key]
                                          ? 'Export Attendees'
                                          : 'Export Attendees'}
                                      </Button>
                                    </Tooltip>
                                  </Form.Item>
                                )}
                              </div>
                            </Space>
                          )
                        })}
                        <div className="form-row">
                          {!roles.includes('3') && (
                            <Form.Item className="add-child-item">
                              <Button
                                type="dashed"
                                onClick={(e) => {
                                  e.preventDefault()
                                  add()
                                  setChildForm([...childForm, {}])
                                }}
                                block
                                icon={<PlusOutlined />}
                              >
                                Add New Child Event
                              </Button>
                            </Form.Item>
                          )}
                        </div>
                      </>
                    )}
                  </Form.List>
                  <Form.Item>
                    <Button
                      type="primary"
                      onClick={() => setError(true)}
                      htmlType="submit"
                      loading={isLoading}
                      icon={<RocketOutlined />}
                    >
                      Publish
                    </Button>
                    <Button
                      className="export-attendees"
                      type="primary"
                      htmlType="button"
                      onClick={() =>
                        window.open(
                          process.env.REACT_APP_SAMPLE_CSV_LINK,
                          '_self'
                        )
                      }
                    >
                      <DownloadOutlined />
                      Sample CSV File
                    </Button>
                    {formdata?.event_id && (
                      <Tooltip title="Export & Download Attendees">
                        <Button
                          className="export-attendees"
                          loading={isLoadingAttendees}
                          icon={<CloudDownloadOutlined />}
                          onClick={() =>
                            exportAttendees('master', formdata.event_id)
                          }
                        >
                          Export Attendees
                        </Button>
                      </Tooltip>
                    )}
                  </Form.Item>
                </Form>
                {/* form ends */}
              </div>
            </div>
          </Layout>
        </>
      )}
    </>
  )
}

export default Home
