import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { message, Tag } from 'antd'
import {
  capitalizeFirstLetter,
  getImagePath,
  lable,
  ExpiredDate,
} from '../../shared/config/globalFunctions'
import defaultEventImage from '../../assets/images/event-1.png'
import moment from 'moment'
import { useSwipeable } from 'react-swipeable'
import './style.scss'
import { userInformation } from '../../redux/selectors/user/user.selector'
import { useDispatch, useSelector } from 'react-redux'
import { Applyevents } from '../../redux/actions/event/event.actions'

const HomeCarousel = (props) => {
  const {
    allEventsArr,
    isAuthenticated,
    fetchMasterEventData,
    resData,
    setAllEventsArr,
  } = props
  const [activeEvent, setActiveEvent] = useState(allEventsArr.filteredEvents[0])
  const userInfo = useSelector(userInformation)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const checkLabel = (activeEvent) => {
    return lable(
      activeEvent?.isPending,
      activeEvent?.isRequested,
      activeEvent?.isApproved
    )
  }

  useEffect(() => {
    setActiveEvent(allEventsArr.filteredEvents[0])
  }, [allEventsArr])

  const handleSwipe = (dir) => {
    if (dir === 'Left') {
      if (allEventsArr.filteredEvents.length > 1) {
        setAllEventsArr({
          ...allEventsArr,
          filteredEvents: allEventsArr.filteredEvents?.filter((_, i) => i !== 0),
        })
      }
    }

    if (dir === 'Right') {
      if (!activeEvent) return null

      if (isAuthenticated) {
        const payload = {
          userId: userInfo.id,
          eventId: activeEvent.id,
          isApply: true,
          isCancel: false,
        }

        dispatch(Applyevents(payload)).then((res) => {
          if (res.status === 200) {
            fetchMasterEventData(resData, 'default')
            message.success(res?.data?.message)
            navigate('.', { state: null })
          }
        })
      } else {
        // Redirect to login
        navigate('/login', {
          state: { eventId: activeEvent.id },
        })
      }
    }
  }

  const handlers = useSwipeable({
    onSwiped: ({ dir }) => handleSwipe(dir),
    trackMouse: true,
  })

  return (
    <>
      <div className="home-carousel" {...handlers}>
        <div className="event-banner-img">
          <img
            src={
              activeEvent?.event_banner
                ? getImagePath(activeEvent?.event_banner)
                : defaultEventImage
            }
            alt="Event"
          />
          <div className="event-info">
            <h1>{capitalizeFirstLetter(activeEvent?.title)}</h1>
            <p>
              {moment(activeEvent?.start_date)
                .utc()
                .format('DD/MM/YYYY, hh:mm A')}
              -{' '}
              {moment(activeEvent?.end_date)
                .utc()
                .format('DD/MM/YYYY, hh:mm A')}
            </p>
          </div>
          {/* ********************** to display action button on banner  ***********************/}
          {isAuthenticated ? (
            <>
              {checkLabel(activeEvent)?.btnname ? (
                <button
                  className="register-mob-btn btn btn-primary"
                  type="button"
                  value={
                    checkLabel(activeEvent).btnname === 'Apply'
                      ? 'isAppied'
                      : 'pending'
                  }
                // onClick={(e) =>
                //   Eventhandler(e, activeEvent.id, '', true)
                // }
                >
                  {checkLabel(activeEvent)?.btnname}
                </button>
              ) : null}

              {/* ********************** to display the tags on banner ********************** */}
              {!ExpiredDate(activeEvent?.end_date) && checkLabel(activeEvent)?.tagname && (
                <div className="tagstatus">
                  <Tag
                    className={
                      checkLabel(activeEvent)?.tagname === 'Pending'
                        ? 'tagstatus-blue'
                        : checkLabel(activeEvent)?.tagname === 'Approved'
                          ? 'tagstatus-success'
                          : 'tagstatus-error'
                    }
                    color={
                      checkLabel(activeEvent)?.tagname === 'Pending'
                        ? 'processing'
                        : checkLabel(activeEvent)?.tagname === 'Approved'
                          ? 'success'
                          : 'default'
                    }
                  >
                    {checkLabel(activeEvent)?.tagname}
                  </Tag>
                </div>
              )}
              {ExpiredDate(activeEvent?.end_date) && (
                <div className="tagstatus">
                  <Tag
                    className={'tagstatus-error'}

                  >
                    {"Expired"}
                  </Tag>
                </div>
              )}
            </>
          ) : (
            <button
              className="register-mob-btn btn btn-primary"
              onClick={() => {
                navigate('/signup', {
                  state: { eventId: activeEvent?.id },
                })
              }}
            >
              Register
            </button>
          )}
        </div>
      </div>
    </>
  )
}

export default HomeCarousel
