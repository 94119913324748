import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Form, Input, message, Upload, Tag } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { updateProfile } from '../../redux/actions/user/user.actions'
import { EditOutlined } from '@ant-design/icons'
import defaultUserImg from '../../assets/images/default-user-img.png'
import NavBar from '../../components/NavBar'
import './Profile.scss'
import {
  userAuthoritiesRole,
  userInformation,
} from '../../redux/selectors/user/user.selector'
import {
  capitalizeFirstLetter,
  getRolesInArr,
  handleCompressedUpload,
  ImageData,
  roleName,
  validatephonenumber,
} from '../../shared/config/globalFunctions'
import { onlyCharactersandNumbersAllowed } from '../AddEvent/eventFormRules'

export default function Profile({ isAuthenticated }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userInfo = useSelector(userInformation)
  const userAuthorities = useSelector(userAuthoritiesRole)
  const [isLoading, setIsLoading] = useState(false)
  const [image, setImage] = useState()
  const [editData, setEditData] = useState(false)
  const [imageURL, setImageURL] = useState()
  const [roles, setRoles] = useState([])

  useEffect(() => {
    if (userAuthorities) {
      let roles_arr = getRolesInArr(userAuthorities)
      setRoles(roles_arr)
    }
  }, [userAuthorities])

  const onFinish = (data) => {
    setIsLoading(true)
    if (image) data['image'] = image

    //updateProfile is used to update user.
    dispatch(updateProfile(data)).then((res) => {
      if (res.status === 200) {
        message.success(res.data.message)
        setEditData(false)
        setImageURL()
        setIsLoading(false)
      } else {
        setIsLoading(false)
      }
    })
  }

  const callbackFun = (data) => {
    setImage(data)
  }

  const onFileUpload = (e) => {
    handleCompressedUpload(e?.fileList[0]?.originFileObj, callbackFun)
    setEditData(true)
    // Get this url from response in real world.
    getBase64(e?.fileList[0].originFileObj, (url) => {
      setImageURL(url)
    })
  }

  const onValuesChange = (data) => {
    if (!data) {
      setEditData(false)
    } else setEditData(true)
  }

  const getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  const profileImageHandler = () => {
    if (imageURL) return imageURL
    return ImageData(userInfo?.image, defaultUserImg)
  }

  return (
    <>
      <NavBar isAuthenticated={isAuthenticated} roles={roles} />
      <div className="form-cover profile-page main-page-content">
        <div className="userformmbox">
          <div className="form-feildbox">
            <div className="profile-wrapper">
              <div className="profile-img-wrapper">
                <div className="profile-img-profile">
                  <span className="img-upload">
                    <img
                      src={profileImageHandler()}
                      className="preview-image"
                      alt="avatar"
                      referrerPolicy="no-referrer"
                    />
                  </span>
                  <Upload
                    name="logo"
                    listType="picture"
                    maxCount={1}
                    onChange={(e) => onFileUpload(e)}
                    beforeUpload={() => {
                      return false
                    }}
                  >
                    <Button
                      icon={<EditOutlined />}
                      className="edit-icon"
                      htmlType="button"
                    ></Button>
                  </Upload>
                </div>
              </div>
              <p className="name">
                {userInfo?.first_name
                  ? capitalizeFirstLetter(userInfo?.first_name)
                  : userInfo?.first_name}
              </p>
              <div className="post-box">
                {userAuthorities?.map((authorities) => {
                  return (
                    <Tag className="post" key={authorities?.id} color="gold">
                      {roleName(authorities?.roleId)}
                    </Tag>
                  )
                })}
              </div>
              <div className="profile-action">
                <button
                  className="btn danger-btn"
                  onClick={() => navigate('/change-password')}
                >
                  Change Password
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="form-box-profile">
          <Form
            name="sign-in"
            onFinish={(e, data) => onFinish(e, data)}
            onValuesChange={(data) => onValuesChange(data)}
            initialValues={userInfo}
          >
            <div className="form-feildbox-row">
              <Form.Item
                name={['email_id']}
                rules={[
                  {
                    type: 'email',
                    message: 'Please enter a valid Email Address.',
                  },
                  {
                    required: true,
                    message: 'Email Address is required.',
                  },
                ]}
              >
                <Input
                  placeholder="Email Address *"
                  disabled={
                    roles.length > 0 && roles.includes('1') ? true : false
                  }
                />
              </Form.Item>
            </div>
            <div className="form-feildbox-row">
              <Form.Item
                name={['first_name']}
                rules={[
                  {
                    type: 'text',
                    message: 'Please enter the valid First Name.',
                  },
                  {
                    required: true,
                    message: 'First Name is required.',
                  },
                ]}
              >
                <Input
                  placeholder="First Name"
                  onKeyDown={onlyCharactersandNumbersAllowed}
                  maxLength="18"
                />
              </Form.Item>
            </div>
            <div className="form-feildbox-row">
              <Form.Item
                name={['last_name']}
                rules={[
                  {
                    type: 'text',
                    message: 'Please enter the valid Last Name.',
                  },
                  {
                    required: true,
                    message: 'Last Name is required.',
                  },
                ]}
              >
                <Input
                  placeholder="Last Name"
                  onKeyDown={onlyCharactersandNumbersAllowed}
                  maxLength="18"
                />
              </Form.Item>
            </div>
            <div className="form-feildbox-row">
              <Form.Item
                name={['phone_number']}
                rules={[
                  {
                    required: true,
                    message: 'Phone Number is required.',
                  },
                  () => ({
                    validator(_, value) {
                      if (value) {
                        return Promise.reject(validatephonenumber(value))
                      }
                      return Promise.resolve()
                    },
                  }),
                ]}
              >
                <Input
                  placeholder="Phone Number"
                  maxLength="17"
                  minLength="5"
                />
              </Form.Item>
            </div>
            <div className="form-feildbox-row">
              <Form.Item
                name={['company_name']}
                rules={[
                  {
                    type: 'text',
                    message: 'Please enter the valid Company Name.',
                  },
                  {
                    required: true,
                    message: 'Company Name is required.',
                  },
                ]}
              >
                <Input placeholder="Company Name" maxLength="32" />
              </Form.Item>
            </div>
            <div className="form-feildbox-row">
              <Form.Item name={['bio_link']}>
                <Input placeholder="Bio Link" />
              </Form.Item>
            </div>
            {editData && (
              <Form.Item>
                <Button
                  loading={isLoading}
                  className="btn primary-btn"
                  type="primary"
                  htmlType="submit"
                >
                  Save Changes
                </Button>
              </Form.Item>
            )}
          </Form>
        </div>
      </div>
    </>
  )
}
