import { LOGGED_IN, LOG_OUT, UPDATE_USER } from '../../types/user'

const initialState = {
  userInfo: {},
  isAdmin: false,
  authToken: '',
  userAuthorities: {},
}

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGGED_IN:
      return {
        userInfo: payload.userDetails,
        authToken: payload.accessToken,
        userAuthorities: payload.authorities,
      }
    case UPDATE_USER:
      return {
        ...state,
        userInfo: payload,
      }
    case LOG_OUT:
      return {
        userInfo: {},
        userAuthorities: {},
        authToken: '',
        event: {},
      }
    default:
      return state
  }
}

export default authReducer
