import React, { useEffect, useState, useRef } from 'react'
import { Modal, Tooltip, Button, message } from 'antd'
import { QrReader } from 'react-qr-reader'
import noData from '../../assets/images/NoData.png'
import User from '../../assets/images/default-user-img.png'
import {
  getAttendeewaitingList,
  getidForAttendeeInCheckinManager,
  ExportAttendeesCSV,
} from '../../redux/actions/event/event.actions'
import './style.scss'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { userSignOut } from '../../redux/actions/user/user.actions'
import {
  handlerestriction_level,
  ImageData,
  getAttendeesCSVPath,
} from '../../shared/config/globalFunctions'
import {
  DownloadOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons'
import { useCallback } from 'react'

export default function CheckInManager() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [eventId, setEventId] = useState('')
  const [counter, setCounter] = useState()
  const [ishow, setIsShow] = useState(false)
  const [isvisible, setIsvisible] = useState(true)
  const [result, setResult] = useState()
  const [facingMode, setFacingMode] = useState('user')
  const [userData, setUserData] = useState({})
  const [isLoadingAttendees, setIsLoadingAttendees] = useState(false)
  let eventid = localStorage.getItem('event_id')
  const ref = useRef(null)

  const [status] = useState([
    {
      name: 'Okay (Yes on List)',
      value: 1,
    }
  ])


  useEffect(() => {
    setIsvisible(false)
    closeCam()
    if (navigator.getUserMedia) {
      navigator.getUserMedia(
        {
          video: true,
        },
        (localMediaStream) => { },
        (err) => {
          alert(
            'The following error occurred when trying to access the camera: ' +
            err
          )
        }
      )
    } else {
      alert('Sorry, browser does not support camera access')
    }
  }, [])

  const closeCam = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: true,
    })
    stream.getTracks().forEach(function (track) {
      track.stop()
      track.enabled = false
    })
    ref?.current?.stopCamera()
  }

  const getCounterofAttendee = (id) => {
    dispatch(getAttendeewaitingList(id)).then((res) => {
      if (res.status === 200) {
        setCounter(res?.data?.data?.count)
      }
    })
  }

  const getUserDetailsData = useCallback((userId, eventid) => {
    dispatch(getidForAttendeeInCheckinManager(userId, eventid)).then((res) => {
      if (res?.data) {
        setUserData(res?.data?.data)
        setIsShow(true)
      } else {
        setIsShow(false)
      }
    })
  }, [dispatch])

  const checkInManagerAction = () => {
    setIsShow(false)
    setIsvisible(false)
    setUserData({})
  }

  // logout handler ask confirmation to logout
  const logoutHandler = () => {
    Modal.confirm({
      title: 'Are you sure you want to logout?',
      icon: <ExclamationCircleOutlined />,
      content: 'You can not revert back!!',
      okText: 'Logout',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        dispatch(userSignOut())
      },
      onCancel() { },
    })
  }

  const switchCameraHandler = () => {
    let currentMode = facingMode
    setFacingMode()
    // setFacingMode(currentMode === 'user' ? 'environment' : 'user')
    setTimeout(() => {
      setFacingMode(currentMode === 'user' ? 'environment' : 'user')
    }, [1])
  }

  let handleScan = (data) => {
    if (data) {
      setResult(data.text)
    }
  }

  let handleError = (err) => {
    alert(err)
  }

  const exportAttendees = async (exportType, eventId) => {
    setIsLoadingAttendees(true)
    await dispatch(
      ExportAttendeesCSV({ eventId: eventId, exportType: exportType })
    ).then(async (res) => {
      if (res.status === 200) {
        setTimeout(() => {
          setIsLoadingAttendees(false)
          message.success(res?.data?.message)
          const URL = getAttendeesCSVPath(res?.data?.data.fileName)
          window.open(URL, '_self')
        }, 10000);
      } else {
        setIsLoadingAttendees(false)
        message.error(res?.data?.message)
      }
    })
  }

  useEffect(() => {
    if (eventid) setEventId(eventid)
  }, [eventid])

  useEffect(() => {
    if (eventId) getCounterofAttendee(eventId)
    // eslint-disable-next-line
  }, [eventId])

  useEffect(() => {
    if (result) getUserDetailsData(result, eventId)
  }, [eventId, getUserDetailsData, result])

  return (
    <>
      <div className="form-cover scan-page">
        <div className="userformmbox door-scan-box">
          <div
            className="switch-camera-box"
            onClick={() => switchCameraHandler()}
          >
            <SyncOutlined className="switch-camera-btn" />
            <span className="sw-cm-txt">Switch Camera</span>
          </div>
          <div className="qr-reader-wrapper form-feildbox qr-box">
            {!isvisible && (
              <>
                {facingMode && (
                  <QrReader
                    // delay={500}
                    onError={handleError}
                    onResult={handleScan}
                    style={{ width: '100%' }}
                    constraints={{
                      facingMode: facingMode,
                    }}
                    ref={ref}
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div className="swipe-note">
          <p>
            How Many People are waiting for Approved - {counter ? counter : 0}
          </p>
        </div>
        {ishow &&
          (userData ? (
            <div className="userformmbox door-scan-box bottom">
              <div className="form-feildbox">
                <div className="profile-wrapper">
                  <div className="profile-img-wrapper">
                    <div className="profile-img-nodata">
                      {userData?.details?.userDetails ? (
                        <img
                          src={ImageData(
                            userData?.details?.userDetails[0]?.image,
                            User
                          )}
                          alt="Profile"
                        />
                      ) : (
                        <img
                          src={ImageData(
                            userData?.details?.childEventUserDetails[0]?.image,
                            User
                          )}
                          alt="Profile"
                        />
                      )}
                    </div>
                  </div>
                  {userData?.details?.userDetails ? (
                    <p className="name">
                      {userData?.details?.userDetails[0]?.first_name +
                        '  ' +
                        userData?.details?.userDetails[0]?.last_name}
                    </p>
                  ) : (
                    <p className="name">
                      {userData?.details?.childEventUserDetails[0]?.first_name +
                        '  ' +
                        userData?.details?.childEventUserDetails[0]?.last_name}
                    </p>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="userformmbox door-scan-box bottom">
              <div className="form-feildbox">
                <div className="profile-wrapper">
                  <div className="profile-img-wrapper">
                    <div className="profile-img-nodata">
                      <img src={noData} alt="Profile" />
                    </div>
                  </div>
                  <p className="name">No User Info available</p>
                </div>
              </div>
            </div>
          ))}
        <div className="action-btns">
          {ishow &&
            handlerestriction_level(
              userData?.restrict_access,
              userData?.details?.vip_level
            ) === true ? (
            <>
              {status?.map(({ name, value }) => (
                <button
                  key={name}
                  className="btn btn-primary"
                  onClick={() => checkInManagerAction()}
                >
                  {name}
                </button>
              ))}
            </>
          ) : ishow ? (
            <p className="name noavailable text-white">
              Come later please currently no capacity left
            </p>
          ) : (
            ''
          )}
          { }
          {eventId && (
            <button
              className="btn btn-primary"
              onClick={() =>
                navigate('/quick-addon', {
                  state: { eventId },
                })
              }
            >
              Come with Quick Add on
            </button>
          )}
          <Tooltip title="Export & Download Attendees">
            <Button
              className="btn btn-primary mt-0"
              loading={isLoadingAttendees}
              icon={<DownloadOutlined />}
              onClick={() => exportAttendees('child', eventId)}
            >
              Export Attendees
            </Button>
          </Tooltip>
          <button
            className="btn third-btn mt-0"
            onClick={() => {
              navigate('/')
              localStorage.removeItem('event_id')
              localStorage.removeItem('id')
              localStorage.removeItem('flag')
            }}
          >
            Back
          </button>
          <button
            className="btn third-btn mt-0"
            onClick={() => {
              logoutHandler()
              localStorage.removeItem('event_id')
              localStorage.removeItem('id')
              localStorage.removeItem('flag')
            }}
          >
            Logout
          </button>
        </div>
      </div>
    </>
  )
}
